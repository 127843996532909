import React from "react";
import { Grid } from "@mui/material";
import { Bar } from "react-chartjs-2";

import "./interest.scss";
import { ReactComponent as LastTime } from "../../../resources/icon/Table/LastTime.svg";

function Interest(props) {
  return (
    <Grid item xs={12} sm={12} md={4}>
      <h2 className="h2-title mt-0">Interest</h2>
      <p className="h2-title__discription">Average interest by all projects</p>
      <div className="box-p box-col box-chart">
        <div className="investor-chart">
          <ChartComponentForAnnual
            timeoutForSkeleton={props.timeoutForSkeleton}
          />
        </div>
        <div className="investors-data">
          <div className="investors-data__item" style={{ width: "115px" }}>
            <div className="investors-data__icon">
              <LastTime />
            </div>
            <div className="investors-data__title">
              <div>Last month</div>
              <div>5.7%</div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default Interest;

const ChartComponentForAnnual = (props) => {
  const [value, setValue] = React.useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue([357, 547, 472, 555, 555, 325, 615, 407, 555, 445, 488, 442]);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  function convertValueToText(value) {
    if (value === 0) {
      return "0";
    } else if (value >= 1 && value <= 1000) {
      const percentage = (value / 1000) * 10;
      return percentage.toFixed(0) + "%";
    } else {
      return "";
    }
  }
  const data = {
    labels: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
    datasets: [
      {
        type: "line",
        label: "Line",
        data: value,
        borderColor: "#43FCFF",
        fill: false,
        borderWidth: 1,
        pointBackgroundColor: "#FA544D",
        pointBorderColor: "#fff",
        tension: 0.3,
        order: 1,
        radius: 3.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    categoryPercentage: 0.4,
    // onHover: ({ x, y }, activeHover, chart) => {
    //   console.log(chart.scales);
    // },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "#43FCFF",
          font: {
            size: 8,
            family: "Montserrat",
          },
          callback: (value) => {
            return convertValueToText(value);
          },
        },
        grid: {
          color: "rgba(255, 255, 255, 0.02)",
          borderColor: "rgba(255, 255, 255, 0.02)",
          tickColor: "rgba(255, 255, 255, 0.02)",
        },
        border: {
          display: false,
        },
      },
      x: {
        beginAtZero: true,
        ticks: {
          color: "#43FCFF",
          font: {
            size: 7,
            family: "Montserrat",
            type: "line",
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    pointLabels: {
      color: "#8186AF",
    },
  };

  return <Bar data={data} options={options} />;
};
