import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { getCountryCallingCode } from "libphonenumber-js";
// import { FaFlag } from "react-icons/fa"; // Импортируем иконку с флагом
import "react-phone-number-input/style.css";

import "./callBack.scss";
import { ButtonExit } from "../../sidebar/SidebarWrapper";
import { ReactComponent as LogoModal } from "../../../resources/icon/LogoModal.svg";
import { ReactComponent as Name } from "../../../resources/icon/PopUp/Name.svg";
import { ReactComponent as Email } from "../../../resources/icon/PopUp/Email.svg";
import { ReactComponent as CallBackNumber } from "../../../resources/icon/PopUp/CallBack.svg";
import { ReactComponent as CallBackData } from "../../../resources/icon/PopUp/CallBackData.svg";
import { ReactComponent as Time } from "../../../resources/icon/PopUp/Time.svg";
// import { ReactComponent as PathClose } from "../../../resources/icon/PopUp/PathClose.svg";
// import { ReactComponent as PathOpen } from "../../../resources/icon/PopUp/PathOpen.svg";

const countries = [
  { code: "UA", name: "Ukraine", flag: "🇺🇦" },
  { code: "PL", name: "Poland", flag: "🇵🇱" },
  { code: "DE", name: "Germany", flag: "🇩🇪" },
  { code: "FR", name: "France", flag: "🇫🇷" },
  { code: "ES", name: "Spain", flag: "🇪🇸" },
];

function CallBack(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [callbackNumber, setCallbackNumber] = useState("");
  const [callbackDate, setCallbackDate] = useState("");
  const [time, setTime] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [callbackDateValid, setCallbackDateValid] = useState(false);
  const [timeValid, setTimeValid] = useState(false);

  const [onFocusName, setOnFocusName] = useState(false);
  const [onFocusEmail, setOnFocusEmail] = useState(false);
  const [onFocusCountry, setOnFocusCountry] = useState(false);
  const [onFocusNumber, setOnFocusNumber] = useState(false);
  const [onFocusDate, setOnFocusDate] = useState(false);
  const [onFocusTime, setOnFocusTime] = useState(false);

  const handleSelectOpen = () => {
    setIsOpen(true);
  };

  const handleSelectClose = () => {
    setIsOpen(false);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    setEmailValid(/^\S+@\S+\.\S+$/.test(inputValue));
  };

  function handleCallbackNumberChange(e) {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .replace(/^\d{3}-\d{2}-\d{2}$/)
      .slice(0, 8);
    setCallbackNumber(formattedValue);
  }

  const handleCallbackDateChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])-(0[1-9]|1[0-2])$/;
    const isValidFormat = regex.test(inputValue);
    const [day, month] = inputValue.split("-");

    const isWithinRange = day <= 31 && month <= 12;

    setCallbackDate(inputValue);
    setCallbackDateValid(isValidFormat && isWithinRange);
  };

  const handleTimeChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^([9]|1[0-6]):[0-5][0-9]$/; // Regular expression for "HH:MM" from 9:00 to 16:59
    const isValidFormat = regex.test(inputValue);

    // Check for time limit from 9:00 to 17:00
    const [hours, minutes] = inputValue.split(":");
    const isValidTime =
      hours >= 9 && hours <= 16 && minutes >= 0 && minutes <= 59;

    setTime(inputValue);
    setTimeValid(isValidFormat && isValidTime);
  };

  const styleName =
    onFocusName && (name.length > 3 ? "inp-success" : "inp-error");
  const styleEmail = onFocusEmail && (emailValid ? "inp-success" : "inp-error");
  const styleCountry =
    onFocusCountry && (selectedCountry ? "inp-success" : "inp-error");
  const styleNumber =
    onFocusNumber && (callbackNumber.length > 7 ? "inp-success" : "inp-error");
  const styleDate =
    onFocusDate && (callbackDateValid ? "inp-success" : "inp-error");
  const styleTime = onFocusTime && (timeValid ? "inp-success" : "inp-error");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      styleName === "inp-error" ||
      styleEmail === "inp-error" ||
      styleCountry === "inp-error" ||
      styleNumber === "inp-error" ||
      styleDate === "inp-error" ||
      styleTime === "inp-error"
    ) {
      alert("Please fill in all fields correctly!");
      return;
    } else {
      alert("We'll be contacting you soon, thanks!");
      props.handleCloseCallBack();
      return;
    }
  };

  return (
    <Box className="s-modal">
      <div className="s-modal__top">
        <div className="s-modal__logo">
          <LogoModal />
        </div>
        <button onClick={props.handleCloseCallBack} className="circle-bg">
          <ButtonExit />
        </button>
      </div>
      <h2 className="h2-title s-modal__title">Request a free callback</h2>
      <div className="s-modal__main-content">
        <form onSubmit={handleSubmit} id="callback-form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <label className="input-name">Name</label>
              <label className="input-label">
                <div className={`input-icon ${styleName}`}>
                  <Name />
                </div>
                <input
                  type="text"
                  placeholder="Enter your name"
                  className={`input-calculator s-modal__input-modal m-0 ${styleName}`}
                  value={name}
                  onChange={handleNameChange}
                  onFocus={() => setOnFocusName(true)}
                  required
                />
              </label>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className="input-name">Email</label>
              <label className="input-label">
                <div className={`input-icon ${styleEmail}`}>
                  <Email />
                </div>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className={`input-calculator s-modal__input-modal m-0 ${styleEmail}`}
                  value={email}
                  onChange={handleEmailChange}
                  onFocus={() => setOnFocusEmail(true)}
                  required
                />
              </label>
            </Grid>

            <Grid item xs={12} sm={6}>
              <label className="input-name">Callback number</label>
              <label className="input-label">
                <div className={`input-icon ${styleCountry}`}>
                  <CallBackNumber />
                </div>

                <div>
                  <select
                    className={`input-calculator s-modal__input-modal m-0 custom-select ${styleCountry}`}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    open={isOpen}
                    onOpen={handleSelectOpen}
                    onClose={handleSelectClose}
                    onFocus={() => setOnFocusCountry(true)}
                  >
                    <option value="" className="custom-select__option-default">
                      country
                    </option>
                    {countries.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.flag} {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </label>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className="input-name"></label>
              <label className="input-label">
                <div
                  className={`input-icon custom-select__code ${styleNumber}`}
                >
                  {" "}
                  {selectedCountry ? (
                    <div>
                      {selectedCountry.slice(4)} (+
                      {getCountryCallingCode(selectedCountry)})
                    </div>
                  ) : (
                    "+ ---"
                  )}
                </div>
                <input
                  type="number"
                  placeholder="000-00-00"
                  className={`input-calculator s-modal__input-modal m-0 ${styleNumber}`}
                  value={callbackNumber}
                  onChange={handleCallbackNumberChange}
                  onFocus={() => setOnFocusNumber(true)}
                  maxLength={10}
                  required
                />
              </label>
            </Grid>

            <Grid item xs={12} sm={6}>
              <label className="input-name">Callback date</label>
              <label className="input-label">
                <div className={`input-icon ${styleDate}`}>
                  <CallBackData />
                </div>
                <input
                  type="text"
                  placeholder="A call date (DD-MM)"
                  className={`input-calculator s-modal__input-modal m-0 ${styleDate}`}
                  value={callbackDate}
                  onChange={handleCallbackDateChange}
                  onFocus={() => setOnFocusDate(true)}
                  required
                />
              </label>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className="input-name">Time (9:00 - 17:00 h)</label>
              <label className="input-label">
                <div className={`input-icon ${styleTime}`}>
                  <Time />
                </div>
                <input
                  type="text"
                  placeholder="Enter the time"
                  className={`input-calculator s-modal__input-modal m-0 ${styleTime}`}
                  value={time}
                  onChange={handleTimeChange}
                  onFocus={() => setOnFocusTime(true)}
                  required
                />
              </label>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div className="input-name info-title">Description (optional)</div>
            <div className="info style-text info-descr">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna
            </div>

            <button
              type="submit"
              form="callback-form"
              className={`button-general s-modal__bt `}
            >
              Request a free callback
            </button>
          </Grid>
        </form>
      </div>
    </Box>
  );
}

export default CallBack;
