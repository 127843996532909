import { createSlice } from "@reduxjs/toolkit";
import data from "../../data/data";
import { companyNewData } from "../../data/companyNewe/companyNewData";

const initialState = {
  initialData: data,
  initialCompaneNewData: companyNewData,
  itemId: 101,
  filtered: [],
  filteredCompanyNewById: [],
};

const itemProjectSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    setItemId(state, action) {
      state.itemId = action.payload;
    },
    filter(state, action) {
      state.filtered = state.initialData.filter(
        (product) => product.id === action.payload
      );
    },
    filterCompanyNewById(state, action) {
      state.filteredCompanyNewById = state.initialCompaneNewData.filter(
        (news) => news.id === action.payload
      );
    },
  },
});

export const { setItemId, filter, filterCompanyNewById } =
  itemProjectSlice.actions;

export default itemProjectSlice.reducer;
