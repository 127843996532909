import React from "react";

import "./skeletonCompanyNewsItem.scss";

import { SkeletonTitle } from "../companyNews/SkeletonCompanyNews";
import { ReactComponent as SkeletonImg } from "../../../resources/icon/CompanyNews/SkeletonImg.svg";

function SkeletonCompanyNewsItem(props) {
  return (
    <div className="comp-news">
      <div className="comp-news__main-content sk-main-content">
        <SkeletonTitle />

        <div className="main-news-item">
          <div className="box-p skeleton-main-news__item">
            <div className="skeleton-main-news__img skeleton-main-news-item__img">
              <SkeletonImg />
            </div>
            <div className="main-news__info-wrapper">
              <SkInfoNews />
            </div>
          </div>
        </div>
        <div className="comp-news-btn" style={{ marginTop: "22px" }}>
          <div className="comp-news-btn__center">
            <div className="sk"></div>
          </div>
        </div>
      </div>

      <div className="comp-news__left-panel">
        <div className="box-p project-news">
          <SkeletonTitle />
        </div>

        <div className="project-news__container" style={{ maxHeight: "100%" }}>
          {[...new Array(4)].map((_, i) => {
            return (
              <div
                key={i}
                className="card-news__item skeleton-project-news__item"
              >
                <div className="skeleton-project-news__wrapper">
                  <div className="skeleton-project-news__img">
                    <SkeletonImg />
                  </div>
                  <SkInfoNews oneLine={true} />
                </div>
                <div className="info-news__divider"></div>
              </div>
            );
          })}
          <div className="comp-news-btn" style={{ marginTop: "22px" }}>
            <div className="comp-news-btn__center">
              <div className="sk"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonCompanyNewsItem;

function SkInfoNews(props) {
  return (
    <div className="skeleton-info-news">
      <div className="skeleton-info-news__wrapper">
        <div className="skeleton-info-news__views">
          <div className="skeleton-info-news__data sk"></div>
          <div className="skeleton-info-news__divider-y"></div>
          <div className="skeleton-info-news__view sk"></div>
        </div>
        <div className="skeleton-info-news__hashtag sk"></div>
      </div>
      <div className="skeleton-info-news__divider"></div>

      {!props.oneLine ? (
        <>
          <div
            className="skeleton-info-news__description sk"
            style={{ height: "26px" }}
          ></div>
          <div className="skeleton-info-news__description sk mt-20"></div>
          <div
            className="skeleton-info-news__description sk mt-20"
            style={{ width: "90%" }}
          ></div>
          <div
            className="skeleton-info-news__description sk mt-20"
            style={{ width: "90%" }}
          ></div>
          <div
            className="skeleton-info-news__description sk mt-20"
            style={{ width: "80%" }}
          ></div>
          <div className="skeleton-info-news__description-short sk mt-20"></div>
        </>
      ) : (
        <>
          <div className="skeleton-info-news__description sk"></div>
          <div className="skeleton-info-news__description-short sk"></div>
        </>
      )}
    </div>
  );
}
