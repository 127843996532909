import { useEffect, useState } from "react";

import "./labelPoint.scss";

import { ReactComponent as New } from "../../../resources/icon/Dashboard/New.svg";
import { ReactComponent as Fire } from "../../../resources/icon/Dashboard/Fire.svg";

function LabelPoint({ seletedLabelById }) {
  const [dataById, setDataById] = useState([]);
  useEffect(() => {
    fetch(seletedLabelById).then(setDataById(seletedLabelById));
  }, [seletedLabelById]);
  return (
    <>
      {dataById ? (
        <div>
          <div className="label-map">
            <div className="label-map__img">
              <img src={dataById[0]?.pictures[0].url} alt="images" />
            </div>
            <div className="label-map__content">
              <div className="label-map__title">{dataById[0]?.title}</div>
              <div className="label-map__funded">€233.000/€976.000</div>
              <div className="label-map__wrapper-number-and-date">
                <div className="label-map__conditions">34%</div>
                <div className="label-map__conditions">4% p.a.</div>
                <div className="label-map__conditions">12 Month</div>
              </div>
            </div>
            <div className="label-map__buttons">
              <button className="circle-bg label-map__button">
                <Fire />
              </button>
              <button className="circle-bg label-map__button">
                <New />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <p>Processing data, please wait...</p>
      )}
    </>
  );
}

export default LabelPoint;
