import React from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { darkMapStyles } from "./Theme";
import PinLocation from "../../resources/icon/PinLocation.svg";
import LabelPoint from "./labelPoint/LabelPoint";

const defaultOptions = {
  // disableDefaultUI: true,
  // panControl: true,
  // zoomControl: true,
  // clickableIcons: false,
  // fullscreenControl: false,
  // mapTypeControl: false,
  streetViewControl: false,
  rotateControl: false,
  scaleControl: false,
  keyboardShortcuts: false,
  scrollwheel: false,
  disableDoubleClickZoom: false,
  styles: darkMapStyles,
};

const Map = ({ data, containerStyle }) => {
  const mapRef = React.useRef(undefined);

  const onLoad = React.useCallback(function callback(map) {
    mapRef.current = map;
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    mapRef.current = undefined;
  }, []);

  const [selected, setSelected] = React.useState(null);
  const [seletedLabelById, setSeletedLabelById] = React.useState([]);

  const onSelect = (marker, id) => {
    setSelected(marker);
    setSeletedLabelById(data.filter((item) => item.id === id));
  };

  const calculateCenter = React.useMemo(() => {
    const coordinates = data?.map((item) => item.location.point);
    const latSum = coordinates?.reduce(
      (sum, coordinate) => sum + coordinate.lat,
      0
    );
    const lngSum = coordinates?.reduce(
      (sum, coordinate) => sum + coordinate.lng,
      0
    );
    const latAvg = latSum / coordinates?.length;
    const lngAvg = lngSum / coordinates?.length;
    return { lat: latAvg, lng: lngAvg };
  }, [data]);

  return (
    <div className="location">
      {" "}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={calculateCenter}
        zoom={5.3}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={defaultOptions}
      >
        {data?.map(({ location, id }, i) => {
          return (
            <Marker
              key={i}
              position={location.point}
              icon={{ url: PinLocation }}
              onClick={() => onSelect(location.point, id)}
            />
          );
        })}
        {selected ? (
          <InfoWindow
            position={selected}
            onCloseClick={() => setSelected(null)}
          >
            <div>
              <LabelPoint seletedLabelById={seletedLabelById} />
            </div>
          </InfoWindow>
        ) : (
          <h1>Processing data, please wait...</h1>
        )}
      </GoogleMap>
    </div>
  );
};

export default Map;
