import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Grid } from "@mui/material";

import ProductCard from "../../../productCard/ProductCard";

const FilterCardsFiveCategory = ({ startIndex, endIndex }) => {
  const data = useSelector((state) => state.filterCardsSlice.data);
  const {
    assetClassPayload,
    statusPayload,
    durationPayload,
    returnDataPayload,
    sortByAllDataPayload,
  } = useSelector((state) => state.filterCardsSlice);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let filteredByAsset = filterByAsset(data);
    let filteredByStatus = filterByState(filteredByAsset);
    let filteredByDuration = filterByDuration(filteredByStatus);
    let filteredByReturnData = filterByReturnData(filteredByDuration);
    let filteredSortByAllData = filterSortByAllData(filteredByReturnData);

    let passFilter = [...filteredSortByAllData];

    setFilteredData(passFilter);
    // eslint-disable-next-line
  }, [
    assetClassPayload,
    statusPayload,
    durationPayload,
    returnDataPayload,
    sortByAllDataPayload,
  ]);

  const filterSortByAllData = (dataArr) => {
    let passFilter = [];

    dataArr.forEach((item) => {
      if (
        sortByAllDataPayload === "Select the option" ||
        sortByAllDataPayload === ""
      ) {
        passFilter.push(item);
      } else if (sortByAllDataPayload === "date Created") {
        passFilter.push(item);
      } else if (sortByAllDataPayload === "alphabet (A to Z)") {
        passFilter.push(item);
      } else if (sortByAllDataPayload === item.category.mapBySort) {
        passFilter.push(item);
      }
    });

    if (sortByAllDataPayload === "date Created") {
      passFilter.sort(
        (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated)
      );
    } else if (sortByAllDataPayload === "alphabet (A to Z)") {
      passFilter.sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
    }

    return passFilter;
  };

  const filterByReturnData = (dataArr) => {
    let passFilter = [];
    dataArr.forEach((item) => {
      if (returnDataPayload === "Return" || returnDataPayload === "") {
        passFilter.push(item);
      } else if (
        returnDataPayload === "< 5% p.a." ||
        returnDataPayload === "5-6% p.a." ||
        returnDataPayload === "6-7% p.a." ||
        returnDataPayload === "> 7% p.a."
      ) {
        passFilter.push(item);
      }
    });

    let filteredByInterestRate = filterByInterestRate(
      passFilter,
      returnDataPayload
    );

    return filteredByInterestRate;
  };

  const filterByDuration = (dataArr) => {
    let passFilter = [];

    dataArr.forEach((item) => {
      if (durationPayload === "Duration" || durationPayload === "") {
        passFilter.push(item);
      } else if (
        durationPayload === "12-24 months" ||
        durationPayload === "24-36 months" ||
        durationPayload === "> 36 months"
      ) {
        passFilter.push(item);
      }
    });

    let filteredSwitch = filterSwitch(durationPayload, passFilter);

    return filteredSwitch;
  };

  const filterByAsset = (dataArr) => {
    let passFilter = [];

    dataArr.forEach((item) => {
      if (assetClassPayload === "Asset class" || assetClassPayload === "") {
        passFilter.push(item);
      } else if (item.category.assetClass === assetClassPayload) {
        passFilter.push(item);
      }
    });

    return passFilter;
  };

  const filterByState = (dataArr) => {
    let passFilter = [];

    dataArr.forEach((item) => {
      if (statusPayload === "Status" || statusPayload === "") {
        passFilter.push(item);
      } else if (item.category.status === statusPayload) {
        passFilter.push(item);
      }
    });

    return passFilter;
  };

  const filterByInterestRate = (dataArr, interestRatePayload) => {
    let filteredData = [];

    switch (interestRatePayload) {
      case "< 5% p.a.":
        filteredData = dataArr.filter((item) => item.procent.return < 5);
        break;
      case "5-6% p.a.":
        filteredData = dataArr.filter(
          (item) => item.procent.return >= 5 && item.procent.return <= 6
        );
        break;
      case "6-7% p.a.":
        filteredData = dataArr.filter(
          (item) => item.procent.return >= 6 && item.procent.return <= 7
        );
        break;
      case "> 7% p.a.":
        filteredData = dataArr.filter((item) => item.procent.return > 7);
        break;
      default:
        filteredData = dataArr;
    }

    return filteredData;
  };

  function filterSwitch(parameter, data) {
    let filteredData = [];

    switch (parameter) {
      case "12-24 months":
        filteredData = data.filter(
          (item) => item.procent.duration >= 12 && item.procent.duration <= 24
        );
        break;
      case "24-36 months":
        filteredData = data.filter(
          (item) => item.procent.duration > 24 && item.procent.duration <= 36
        );
        break;
      case "> 36 months":
        filteredData = data.filter((item) => item.procent.duration > 36);
        break;
      default:
        filteredData = data;
        break;
    }

    return filteredData;
  }

  return (
    <Grid container rowSpacing={{ xs: 11, sm: 11, md: 4 }} columnSpacing={4}>
      {(filteredData || []).slice(startIndex, endIndex).map((item) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
          <ProductCard {...item} />
        </Grid>
      ))}
    </Grid>
  );
};

export default FilterCardsFiveCategory;
