import React from "react";
import "./accruals.scss";

import { Grid, Pagination } from "@mui/material";

import { nDate } from "../investments/nData";
import data from "../../../data/data";
import { ReactComponent as AmountFiat } from "../../../resources/icon/Table/AmountFiat.svg";
import { ReactComponent as ID } from "../../../resources/icon/Table/ID.svg";
import { ResponsiveDatePickers } from "../../fewSimpleComponents/calender/Calendar";
import { ReactComponent as Date } from "../../../resources/icon/Table/Date.svg";
import { ReactComponent as TotalInvestment } from "../../../resources/icon/Table/TotalInvestment.svg";
import { ReactComponent as CurrencyBtc } from "../../../resources/icon/Table/CurrencyBtc.svg";
import { ReactComponent as Balance } from "../../../resources/icon/Table/Balance.svg";
import SkeletonInvestment from "./SkeletonAccruals";

function Accruals(props) {
  const [filtereData, setFiltereData] = React.useState(data);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const [searchAmount, setSearchAmount] = React.useState("");
  const [loadingSkeleton, setLoadingSkeleton] = React.useState(true);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoadingSkeleton(false);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    let filteredDate = filterByDate(data);
    let filteredById = filterById(filteredDate);
    let filteredByAmount = filterByAmount(filteredById);
    let passFilter = [...filteredByAmount];

    setFiltereData(passFilter);
    // eslint-disable-next-line
  }, [selectedDateFrom, selectedDateTo, searchValue, searchAmount]);

  const filterById = (arrData) => {
    const filtered = arrData.filter((item) =>
      String(item.category.transactionId).includes(searchValue)
    );
    return filtered;
  };

  const filterByAmount = (arrData) => {
    if (searchAmount) {
      const filtered = arrData.filter(
        (item) => +item.investmentTable.investment < +searchAmount
      );
      return filtered;
    } else {
      return arrData;
    }
  };

  const handleDateChangeFrom = (date) => {
    setSelectedDateFrom(date);
  };

  const handleDateChangeTo = (date) => {
    setSelectedDateTo(date);
  };

  const fromDate = selectedDateFrom;
  const toDate = selectedDateTo;

  const filterByDate = (data) => {
    return data.filter((item) => {
      let value;
      const itemDate = nDate(item.dateCreated);
      const filterFromDate = nDate(fromDate);
      const filterToDate = nDate(toDate);

      if (fromDate === null && toDate === null) {
        value = item;
      } else if (fromDate && toDate) {
        value = itemDate > filterFromDate && itemDate < filterToDate;
      } else if (fromDate) {
        value = itemDate > filterFromDate;
      } else if (toDate) {
        value = itemDate < filterToDate;
      }
      return value;
    });
  };

  const handleToggleCalendar = () => {
    setIsOpen(!isOpen);
  };
  const cardsPerPage = 6;

  const totalCards = filtereData.length;
  const pageCount = Math.ceil(totalCards / cardsPerPage);

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  //Get the current set of cards to display on the current page
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const currentCards = filtereData.slice(startIndex, endIndex);

  const amoutnAllSum = (array1) => {
    const sumWithInitial = array1.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return sumWithInitial;
  };

  const calcCarrency = (euroValue) => {
    const parsedEuroValue = parseFloat(euroValue);
    const bitcoinAmount = parsedEuroValue / 27918.85;
    return bitcoinAmount.toFixed(8);
  };

  const totalInvestmentEur = amoutnAllSum(
    filtereData.map((item) => +item.investmentTable.investment)
  ).toFixed(2);
  const totalInvestmentBTC = calcCarrency(
    amoutnAllSum(
      filtereData.map((item) => +item.investmentTable.investment)
    ).toFixed(2)
  );
  return (
    <Grid item xs={12}>
      <h2 className="h2-title" style={{ marginTop: "25px" }}>
        Accruals
      </h2>
      <p className="h2-title__discription">
        Accruals table for active investments
      </p>
      <div className="box table-box">
        <div className="filter-table__container">
          <div className="filter-table__wrapper">
            <div className="filter-table">
              <div
                className="filter-table__icon"
                onClick={handleToggleCalendar}
              >
                <Date />
              </div>
              <div
                className="filter-table__input"
                onClick={handleToggleCalendar}
              >
                <ResponsiveDatePickers
                  handleDateChange={handleDateChangeFrom}
                />
              </div>
            </div>
            {selectedDateFrom && (
              <div
                onClick={() => {
                  setSelectedDateFrom(null);
                }}
                className="cancel-filter"
              >
                &#215;
              </div>
            )}

            <div className="filter-table" style={{ marginRight: "30px" }}>
              <div className="filter-table__icon">
                <Date />
              </div>
              <div className="filter-table__input">
                <ResponsiveDatePickers handleDateChange={handleDateChangeTo} />
              </div>
            </div>

            {selectedDateTo && (
              <div
                onClick={() => {
                  setSelectedDateTo(null);
                }}
                className="cancel-filter"
                style={{ marginLeft: "-25px", marginRight: "25px" }}
              >
                &#215;
              </div>
            )}

            <div className="accruals-filter-table">
              <div className="accruals-filter-table__icon">
                <AmountFiat />
              </div>
              <div className="accruals-filter-table__input">
                <input
                  type="text"
                  name="dateFrom"
                  placeholder="Amount (fiat)"
                  value={searchAmount}
                  onChange={(e) => setSearchAmount(e.target.value)}
                />
              </div>
            </div>

            <div className="accruals-filter-table">
              <div className="accruals-filter-table__icon">
                <ID />
              </div>
              <div className="accruals-filter-table__input">
                <input
                  type="text"
                  name="dateFrom"
                  placeholder="Transaction ID"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div
            className="filter-table"
            style={{ width: "fit-content", marginRight: 0 }}
          >
            <div className="filter-table__icon">
              <TotalInvestment />
            </div>
            <div className="filter-table__title">
              Total investment <div></div>{" "}
              <span>
                € {!loadingSkeleton ? totalInvestmentEur : (0.0).toFixed(1)}/
              </span>
              {!loadingSkeleton ? totalInvestmentBTC : (0.0).toFixed(6)} BTC
            </div>
          </div>
        </div>

        <div className="accruals-titles-table">
          <div className="titles-table__item" style={{ paddingLeft: "25px" }}>
            Date
          </div>
          <div className="titles-table__item">Project</div>
          <div className="titles-table__item">Transaction ID</div>
          <div className="titles-table__item">Amount (fiat)</div>
          <div className="titles-table__item">Amount (Crypto)</div>
          <div className="titles-table__item">Balance</div>
        </div>

        <div style={{ minHeight: "338px" }}>
          {loadingSkeleton
            ? [...new Array(6)].map((item) => <SkeletonInvestment />)
            : currentCards.map((items, i) => {
                return (
                  <div className="accruals-table" key={i}>
                    <div className="accruals-table__Date">
                      <div className="accruals-table__Date-icon">
                        <Date />
                      </div>
                      <div className="accruals-table__Date-title">
                        <div>{items.dateCreated}</div>
                        <div>18:42 PM</div>
                      </div>
                    </div>
                    <div className="accruals-table__Project">
                      <div className="accruals-table__Project-img">
                        <img src={items.pictures[0].url} alt={items.title} />
                      </div>
                      <div className="accruals-table__Project-title">
                        {items.title}
                      </div>
                    </div>
                    <div className="accruals-table__Status">
                      <div className="accruals-table__Status-icon">
                        <ID />
                      </div>
                      <div className="accruals-table__Status-title">
                        {items.category.transactionId}
                      </div>
                    </div>

                    <div className="accruals-table__Term">
                      <div className="accruals-table__Term-icon">
                        <AmountFiat />
                      </div>
                      <div className="accruals-table__Term-title">
                        <div style={{ color: "#43fcff" }}>
                          € {items.investmentTable.investment}
                        </div>
                      </div>
                    </div>
                    <div className="accruals-table__Investment">
                      <div className="accruals-table__Investment-icon">
                        <CurrencyBtc />
                      </div>
                      <div className="accruals-table__Investment-title">
                        <div>
                          {calcCarrency(items.investmentTable.investment)} BTC
                        </div>
                        <div>€1.00 ≈ 0.00003582 BTC</div>
                      </div>
                    </div>
                    <div className="accruals-table__Term">
                      <div className="accruals-table__Term-icon">
                        <Balance />
                      </div>
                      <div className="accruals-table__Term-title">
                        <div style={{ color: "#fa544d" }}>
                          €{" "}
                          {(items.investmentTable.investment - 500).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>

        <p
          className="h2-title__discription"
          style={{ paddingTop: "20px", textAlign: "end" }}
        >
          Latest actions (Showing {startIndex + 1} to {endIndex} of{" "}
          {filtereData.length})
        </p>
        {!loadingSkeleton && (
          <div className="table-pagination">
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
            />
          </div>
        )}
      </div>
    </Grid>
  );
}
export default Accruals;
