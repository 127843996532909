import * as React from "react";
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import "./slider.scss";

export const ValueLabelComponent = (props) => {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

export default function CustomizedSlider() {
  return (
    <>
      <Box sx={{ m: 0, maxHeight: 45 }}>
        <div className="dashboard-slider">
          <div className="dashboard-slider-percent">83%</div>
          <div className="dashboard-slider-amount">€425.000/€847.000</div>
        </div>
        <Slider
          className="slider-dashboard"
          valueLabelDisplay="auto"
          slots={{
            valueLabel: ValueLabelComponent,
          }}
          aria-label="custom thumb label"
          defaultValue={70}
          disabled
        />
      </Box>
    </>
  );
}
