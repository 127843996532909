import React from "react";
import { ReactComponent as ImgSkeleton } from "../../../resources/elements/ImgSkeleton28.svg";

function SkeletonInvestment(props) {
  return (
    <div className="s-table">
      <div className="s-table__Date">
        <div className="s-table__Date-icon">
          <div></div>
        </div>
        <div className="s-table__Date-title">
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="s-table__Project">
        <div className="s-table__Project-img">
          <ImgSkeleton />
        </div>
        <div className="s-table__Project-title">
          <div></div>
        </div>
      </div>
      <div className="s-table__Status">
        <div className="s-table__Status-icon">
          <div></div>
        </div>
        <div className="s-table__Status-title">
          <div></div>
        </div>
      </div>
      <div className="s-table__Distribution">
        <div className="s-table__Date-icon">
          <div></div>
        </div>
        <div className="s-table__Status-title">
          <div></div>
        </div>
      </div>
      <div className="s-table__Return">
        <div className="s-table__Date-icon">
          <div></div>
        </div>
        <div className="s-table__Status-title">
          <div></div>
        </div>
      </div>
      <div className="s-table__Term">
        <div className="s-table__Date-icon">
          <div></div>
        </div>
        <div className="s-table__Status-title">
          <div></div>
        </div>
      </div>
      <div className="s-table__Investment">
        <div className="s-table__Investment-icon">
          <div></div>
        </div>
        <div className="s-table__Investment-title">
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="s-table__Funding">
        <div></div>

        <div></div>
      </div>
    </div>
  );
}

export default SkeletonInvestment;
