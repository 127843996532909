import React from "react";

function FAQ(props) {
  const { color } = props;
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99962 13.781C5.5825 13.781 5.17475 13.9047 4.82793 14.1364C4.4811 14.3682 4.21079 14.6975 4.05116 15.0829C3.89154 15.4683 3.84977 15.8923 3.93115 16.3015C4.01253 16.7106 4.21338 17.0863 4.50833 17.3813C4.80328 17.6762 5.17907 17.8771 5.58818 17.9585C5.99728 18.0399 6.42133 17.9981 6.8067 17.8385C7.19207 17.6788 7.52145 17.4085 7.75319 17.0617C7.98493 16.7149 8.10863 16.3071 8.10863 15.89C8.10783 15.3309 7.88538 14.7949 7.49003 14.3996C7.09469 14.0043 6.55871 13.7818 5.99962 13.781ZM5.99962 16.945C5.79096 16.945 5.58699 16.8831 5.4135 16.7672C5.24 16.6513 5.10478 16.4865 5.02493 16.2937C4.94508 16.101 4.92418 15.8888 4.96489 15.6842C5.0056 15.4795 5.10609 15.2916 5.25363 15.144C5.40117 14.9965 5.58916 14.896 5.79381 14.8553C5.99846 14.8146 6.21057 14.8355 6.40335 14.9153C6.59613 14.9952 6.7609 15.1304 6.87683 15.3039C6.99275 15.4774 7.05462 15.6814 7.05462 15.89C7.05436 16.1697 6.94312 16.4379 6.74533 16.6357C6.54754 16.8335 6.27934 16.9447 5.99962 16.945Z"
        fill="#8186AF"
        className={color}
      />
      <path
        d="M5.99963 1.08547e-07C4.54575 0.00185235 3.15194 0.580224 2.12389 1.60827C1.09585 2.63632 0.517477 4.03012 0.515625 5.484V6.011H4.73463V5.484C4.73444 5.27126 4.78787 5.06191 4.88998 4.87528C4.99208 4.68865 5.13957 4.53075 5.31882 4.41618C5.49808 4.30161 5.70331 4.23406 5.91557 4.21977C6.12782 4.20548 6.34026 4.2449 6.53325 4.3344C6.72624 4.4239 6.89357 4.5606 7.01978 4.73185C7.14598 4.90311 7.227 5.10341 7.25534 5.31425C7.28368 5.52509 7.25843 5.73968 7.18193 5.93818C7.10543 6.13669 6.98014 6.31271 6.81763 6.45L3.89062 8.939V12.727H8.10963V10.889L9.55063 9.664C10.4064 8.93723 11.0185 7.9653 11.3042 6.87952C11.5899 5.79373 11.5354 4.64642 11.1482 3.59257C10.761 2.53872 10.0596 1.62912 9.13887 0.986641C8.21812 0.344165 7.12237 -0.000223218 5.99963 1.08547e-07ZM8.86662 8.861L7.05463 10.4V11.67H4.94563V9.426L7.50063 7.253C7.77998 7.01543 7.99904 6.71502 8.13984 6.37641C8.28064 6.0378 8.33916 5.67064 8.31061 5.30503C8.28206 4.93943 8.16724 4.58581 7.97558 4.27316C7.78392 3.96051 7.52088 3.69775 7.20803 3.50643C6.89518 3.31511 6.54143 3.20068 6.1758 3.17252C5.81016 3.14436 5.44306 3.20328 5.10461 3.34444C4.76615 3.4856 4.46597 3.70498 4.2287 3.98459C3.99143 4.26421 3.82382 4.59608 3.73962 4.953H1.60062C1.68565 4.23629 1.94449 3.55121 2.35464 2.95735C2.7648 2.36348 3.31383 1.87882 3.95399 1.54553C4.59416 1.21223 5.30606 1.04039 6.02778 1.04495C6.7495 1.04951 7.45918 1.23034 8.09508 1.5717C8.73098 1.91307 9.27384 2.40463 9.67645 3.00363C10.0791 3.60263 10.3292 4.29093 10.4052 5.00865C10.4811 5.72638 10.3806 6.45179 10.1123 7.1218C9.84394 7.7918 9.41599 8.3861 8.86562 8.853L8.86662 8.861Z"
        fill="#8186AF"
        className={color}
      />
    </svg>
  );
}

export default FAQ;
