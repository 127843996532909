import { useState } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import "./hoverRating.scss";
const labels = {
  1: "E",
  2: "D",
  3: "C",
  4: "B",
  5: "A",
};

export const getLabelText = (value) => {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
};

export default function HoverRating() {
  const [value, setValue] = useState(5);
  const [hover, setHover] = useState(-1);

  return (
    <div className="rating-wrapper">
      <div className="circle-number">15</div>
      <div className="rating-box">
        <Rating
          readOnly
          className="rating"
          name="hover-feedback"
          size="small"
          value={value}
          precision={1}
          getLabelText={getLabelText}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={
            <StarIcon
              style={{ opacity: 1, color: "#323054" }}
              fontSize="inherit"
            />
          }
        />
        {value !== null && (
          <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
        )}
      </div>
    </div>
  );
}
