import Grid from "@mui/material/Grid";
import { v4 as uuidv4 } from "uuid";
import { Element } from "react-scroll";

import "./projectNews.scss";

const images = [
  {
    numberId: 1,
    title: "Lockup stage.",
    url: "https://bigfoto.name/uploads/posts/2021-11/thumbs/1638302098_5-bigfoto-name-p-dizain-domov-i-kottedzhei-6.jpg",
    date: "26 Oct 2020",
  },
  {
    numberId: 2,
    title: "The slab or base stage.",
    url: "https://bigfoto.name/uploads/posts/2021-11/thumbs/1638302112_1-bigfoto-name-p-dizain-domov-i-kottedzhei-2.jpg",
    date: "27 Oct 2020",
  },
  {
    numberId: 3,
    title: "Frame stage.",
    url: "https://bigfoto.name/uploads/posts/2021-11/thumbs/1638302082_2-bigfoto-name-p-dizain-domov-i-kottedzhei-3.jpg",
    date: "28 Oct 2020",
  },
  {
    numberId: 4,
    title: "Clearing of the site.",
    url: "https://bigfoto.name/uploads/posts/2021-11/thumbs/1638302098_5-bigfoto-name-p-dizain-domov-i-kottedzhei-6.jpg",
    date: "28 Oct 2020",
  },
];

function ProjectNews(props) {
  return (
    <Element name="Project news">
      <h2 className="h2-title project-new__mt">Project news</h2>
      <div className="box box-project-new">
        <Grid container spacing={2}>
          {images.map(({ numberId, title, url, date }, i) => {
            if (i % 2) {
              return (
                <Grid item xs={12} sm={6} key={uuidv4()}>
                  <div className="projec-news">
                    <div className="projec-news__img">
                      <img src={url} alt="images" />
                    </div>
                    <div className="projec-news__content">
                      <div className="projec-news__wrapper-number-and-date">
                        <div className="projec-news__number">
                          Project #{numberId}
                        </div>
                        <div className="projec-news__date">{date}</div>
                      </div>
                      <div className="projec-news__title">{title}</div>
                    </div>
                  </div>
                </Grid>
              );
            } else {
              return (
                <Grid item xs={12} sm={6} key={uuidv4()}>
                  <div className="projec-news">
                    <div className="projec-news__img">
                      <img src={url} alt="images" />
                    </div>
                    <div className="projec-news__content">
                      <div className="projec-news__wrapper-number-and-date">
                        <div className="projec-news__number">
                          Project #{numberId}
                        </div>
                        <div className="projec-news__date">{date}</div>
                      </div>
                      <div className="projec-news__title">{title}</div>
                    </div>
                  </div>
                </Grid>
              );
            }
          })}
        </Grid>
      </div>
    </Element>
  );
}

export default ProjectNews;
