import React from "react";
import { Grid } from "@mui/material";

import Highcharts from "highcharts";
import variablePie from "highcharts/modules/variable-pie";
import HighchartsReact from "highcharts-react-official";

import "./genderRatio.scss";

variablePie(Highcharts);

function GenderRatio(props) {
  return (
    <Grid item xs={12} sm={6} md={2}>
      <div className="boxs-chart">
        <div className="boxs-chart__title">
          <h4>Gender ratio</h4>
        </div>
        <div className="boxs-chart__container" style={{ padding: 0 }}>
          <div className="chart-polar-wrapper">
            <ChartExample timeoutForSkeleton={props.timeoutForSkeleton} />
          </div>
          <div className="ratio-panel-info__wrapper">
            <div className="ratio-panel-info">
              <div className="ratio-panel-info__icon">
                {" "}
                <div
                  className="chart-partfolio__color"
                  style={{ background: "#43fcff", position: "static" }}
                ></div>
              </div>
              <div className="ratio-panel-info__title">
                <div>Male</div>
                <div>{props.timeoutForSkeletonValue ? "2736" : "0"}</div>
              </div>
            </div>

            <div className="ratio-panel-info">
              <div className="ratio-panel-info__icon">
                {" "}
                <div
                  className="chart-partfolio__color"
                  style={{ background: "#FA544D", position: "static" }}
                ></div>
              </div>
              <div className="ratio-panel-info__title">
                <div>Female</div>
                <div>{props.timeoutForSkeletonValue ? "747" : "0"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default GenderRatio;

const ChartExample = (props) => {
  const [value, setValue] = React.useState([
    {
      name: "Male",
      y: 0,
      z: 0,
    },
    {
      name: "Female",
      y: 0,
      z: 0,
    },
  ]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue([
        {
          name: "Male",
          y: 2736,
          z: 2736,
        },
        {
          name: "Female",
          y: 747,
          z: 747,
        },
      ]);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  const options = {
    chart: {
      type: "variablepie",
      width: 115,
      height: 115,
      spacing: [0, 0, 0, 0],
      backgroundColor: "transparent",
      borderColor: "#323054",
      borderWidth: 1,
      borderRadius: 100,
    },
    title: {
      text: "",
      align: "left",
    },
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      headerFormat: "",
      pointFormat:
        " <b>{point.name}</b><br/>" +
        '<span style="color:{point.color}; margin-top: 10px;">&#9724;</span> Pai:<b> {point.y}</b>',
      style: {
        color: "#fff",
      },
    },

    credits: {
      enabled: false,
    },
    plotOptions: {
      variablepie: {
        maxPointSize: 100,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        minPointSize: 18,
        innerSize: "16%",
        zMin: 0,
        name: "countries",
        borderRadius: 0,
        borderColor: "#8186AF",
        data: value,
        colors: ["#43FCFF", "#FA544D"],
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
