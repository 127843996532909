export const investmentTables = [
  {
    distribution: "Monthly",
    investment: "9538.85",
    status: "Pending",
    rangeProcent: 38,
  },
  {
    distribution: "Yearly",
    investment: "1857.12",
    status: "Pending",
    rangeProcent: 26,
  },
  {
    distribution: "Quarterly",
    investment: "7316.06",
    status: "Pending",
    rangeProcent: 61,
  },
  {
    distribution: "Yearly",
    investment: "6838.12",
    status: "Active",
    rangeProcent: 53,
  },
  {
    distribution: "Yearly",
    investment: "3852.01",
    status: "Pending",
    rangeProcent: 11,
  },
  {
    distribution: "Quarterly",
    investment: "3832.23",
    status: "Completed",
    rangeProcent: 84,
  },
  {
    distribution: "Quarterly",
    investment: "3435.70",
    status: "Pending",
    rangeProcent: 22,
  },
  {
    distribution: "Quarterly",
    investment: "8908.34",
    status: "Pending",
    rangeProcent: 68,
  },
  {
    distribution: "Monthly",
    investment: "3335.86",
    status: "Active",
    rangeProcent: 43,
  },
  {
    distribution: "Quarterly",
    investment: "9935.60",
    status: "Pending",
    rangeProcent: 62,
  },
  {
    distribution: "Yearly",
    investment: "2716.97",
    status: "Completed",
    rangeProcent: 1,
  },
  {
    distribution: "Yearly",
    investment: "4533.43",
    status: "Completed",
    rangeProcent: 58,
  },
  {
    distribution: "Quarterly",
    investment: "6746.17",
    status: "Active",
    rangeProcent: 85,
  },
  {
    distribution: "Yearly",
    investment: "8039.32",
    status: "Completed",
    rangeProcent: 33,
  },
  {
    distribution: "Yearly",
    investment: "8280.24",
    status: "Active",
    rangeProcent: 45,
  },
  {
    distribution: "Yearly",
    investment: "5810.25",
    status: "Active",
    rangeProcent: 1,
  },
  {
    distribution: "Monthly",
    investment: "2233.81",
    status: "Pending",
    rangeProcent: 85,
  },
  {
    distribution: "Quarterly",
    investment: "2199.35",
    status: "Active",
    rangeProcent: 54,
  },
  {
    distribution: "Yearly",
    investment: "5398.66",
    status: "Completed",
    rangeProcent: 38,
  },
  {
    distribution: "Quarterly",
    investment: "1659.41",
    status: "Active",
    rangeProcent: 64,
  },
  {
    distribution: "Yearly",
    investment: "4589.71",
    status: "Active",
    rangeProcent: 32,
  },
  {
    distribution: "Monthly",
    investment: "3701.09",
    status: "Active",
    rangeProcent: 43,
  },
  {
    distribution: "Monthly",
    investment: "4959.39",
    status: "Pending",
    rangeProcent: 39,
  },
  {
    distribution: "Yearly",
    investment: "6446.15",
    status: "Completed",
    rangeProcent: 78,
  },
  {
    distribution: "Quarterly",
    investment: "3175.11",
    status: "Active",
    rangeProcent: 86,
  },
  {
    distribution: "Yearly",
    investment: "1736.41",
    status: "Completed",
    rangeProcent: 45,
  },
];

// function getRandomNumber(min, max) {
//   const amount = Math.random() * (max - min) + min;
//   return amount.toFixed(2);
// }

// function getRandomDistribution() {
//   var distributions = ["Yearly", "Monthly", "Quarterly"];
//   var randomIndex = Math.floor(Math.random() * distributions.length);
//   return distributions[randomIndex];
// }

// function getRandomStatus() {
//   var statuses = ["Active", "Completed", "Pending"];
//   var randomIndex = Math.floor(Math.random() * statuses.length);
//   return statuses[randomIndex];
// }

// var array = [];

// for (var i = 0; i < 26; i++) {
//   var distribution = getRandomDistribution();
//   var investment = getRandomNumber(1000, 10000);
//   var status = getRandomStatus();

//   var obj = {
//     distribution: distribution,
//     investment: investment,
//     status: status,
//   };

//   array.push(obj);
// }

// console.log(array);
