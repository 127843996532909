import React from "react";
import { Grid } from "@mui/material";
import Investors from "../../charts/investors/Investors";
import Portfolio from "../../charts/portfolio/Portfolio";
import Interest from "../../charts/interest/Interest";
import Deposit from "../../table/deposit/Deposit";
import ProfitableProjects from "../../table/profitableProjects/ProfitableProjects";
import ListOfNews from "../../table/listOfNews/ListOfNews";

function DlendoPortfolio(props) {
  const timeoutForSkeleton = 3000;
  return (
    <div>
      <Grid container spacing={3}>
        <Investors timeoutForSkeleton={timeoutForSkeleton} />
        <Portfolio timeoutForSkeleton={timeoutForSkeleton} />
        <Interest timeoutForSkeleton={timeoutForSkeleton} />
        <Deposit timeoutForSkeleton={timeoutForSkeleton} />
        <ProfitableProjects timeoutForSkeleton={timeoutForSkeleton} />
        <ListOfNews timeoutForSkeleton={timeoutForSkeleton} />
      </Grid>
    </div>
  );
}

export default DlendoPortfolio;
