import React from "react";

function Facebook(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
        fill="#4267B2"
      />
      <path
        d="M16.1993 12.112H13.4173V10.288C13.4021 10.1796 13.4111 10.0691 13.4436 9.96454C13.4761 9.85998 13.5313 9.76389 13.6052 9.68315C13.6792 9.60241 13.7701 9.53901 13.8714 9.49749C13.9727 9.45597 14.0819 9.43736 14.1913 9.44299H16.1543V6.43099L13.4483 6.42399C12.955 6.38521 12.4593 6.45377 11.9951 6.62497C11.5309 6.79616 11.1093 7.06592 10.7594 7.41566C10.4095 7.7654 10.1395 8.18683 9.96804 8.65092C9.7966 9.11501 9.72776 9.61074 9.76627 10.104V12.112H8.03027V15.212H9.76627V23.995H13.4173V15.216H15.8813L16.1993 12.112Z"
        fill="white"
      />
    </svg>
  );
}

export default Facebook;
