export const categories = [
  {
    mapBySort: "new projects",
    status: "open for investments",
    assetClass: "residential",
    transactionId: 87654,
  },
  {
    mapBySort: "new projects",
    status: "coming soon",
    assetClass: "commercial",
    transactionId: 13089,
  },
  {
    mapBySort: "new projects",
    status: "funded successfully",
    assetClass: "commercial",
    transactionId: 13578,
  },
  {
    mapBySort: "hot projects",
    status: "already refunded",
    assetClass: "commercial",
    transactionId: 41208,
  },
  {
    mapBySort: "new projects",
    status: "open for investments",
    assetClass: "commercial",
    transactionId: 49199,
  },
  {
    mapBySort: "hot projects",
    status: "coming soon",
    assetClass: "residential",
    transactionId: 68627,
  },
  {
    mapBySort: "new projects",
    status: "funded successfully",
    assetClass: "commercial",
    transactionId: 82797,
  },
  {
    mapBySort: "hot projects",
    status: "already refunded",
    assetClass: "residential",
    transactionId: 75409,
  },
  {
    mapBySort: "new projects",
    status: "open for investments",
    assetClass: "residential",
    transactionId: 61842,
  },
  {
    mapBySort: "hot projects",
    status: "coming soon",
    assetClass: "commercial",
    transactionId: 48402,
  },
  {
    mapBySort: "new projects",
    status: "funded successfully",
    assetClass: "residential",
    transactionId: 14505,
  },
  {
    mapBySort: "hot projects",
    status: "already refunded",
    assetClass: "commercial",
    transactionId: 79488,
  },
  {
    mapBySort: "new projects",
    status: "open for investments",
    assetClass: "commercial",
    transactionId: 56764,
  },
  {
    mapBySort: "hot projects",
    status: "coming soon",
    assetClass: "residential",
    transactionId: 64748,
  },
  {
    mapBySort: "new projects",
    status: "funded successfully",
    assetClass: "commercial",
    transactionId: 18355,
  },
];
