import React from "react";
import { Grid } from "@mui/material";
import { Bar } from "react-chartjs-2";

const dataAmount = [
  {
    title: "Visited",
    count: 7580,
    color: "#4E7CFF",
  },
  {
    title: "Registered",
    count: 9660,
    color: "#FA544D",
  },
  {
    title: "Funded",
    count: 5660,
    color: "#43FCFF",
  },
];

function ReferralInvolvement(props) {
  return (
    <Grid item xs={12} sm={12} md={6}>
      <div className="boxs-chart">
        <div className="boxs-chart__title">
          <h4>Referral involvement</h4>
        </div>
        <div className="boxs-chart__container">
          <div className="investor-chart">
            <ChartComponent timeoutForSkeleton={props.timeoutForSkeleton} />
          </div>
          <div className="panel-info__wrapper">
            {dataAmount.map(({ title, count, color }, i) => {
              return (
                <div key={i} className="panel-info">
                  <div className="panel-info__icon">
                    <div
                      className="chart-partfolio__color"
                      style={{ background: color, position: "static" }}
                    ></div>
                  </div>
                  <div className="panel-info__title">
                    <div>{title}</div>
                    <div>{props.timeoutForSkeletonValue ? count : "0"}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default ReferralInvolvement;

const ChartComponent = (props) => {
  const [value, setValue] = React.useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue([
        [600, 200, 700, 400, 100, 200, 600, 450, 680, 150, 400, 580],
        [900, 500, 1000, 700, 400, 500, 900, 750, 980, 450, 700, 880],
        [400, 800, 700, 600, 1000, 800, 700, 950, 300, 550, 300, 780],
      ]);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  function convertValueToText(value) {
    if (value === 0) {
      return 0;
    }

    return value;
  }
  const data = {
    labels: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
    datasets: [
      {
        type: "bar",
        label: dataAmount[2].title,
        data: value[0],
        backgroundColor: dataAmount[2].color,
        borderRadius: 2,
        order: 2,
      },
      {
        type: "bar",
        label: dataAmount[1].title,
        data: value[1],
        backgroundColor: dataAmount[1].color,
        borderRadius: 2,
        order: 2,
      },
      {
        type: "bar",
        label: dataAmount[0].title,
        data: value[2],
        backgroundColor: dataAmount[0].color,
        borderRadius: 2,
        order: 2,
      },
      {
        type: "line",
        label: dataAmount[2].title,
        data: value[0],
        borderColor: dataAmount[2].color,
        fill: false,
        borderWidth: 1,
        backgroundColor: dataAmount[2].color,
        pointBorderColor: "#fff",
        tension: 0.1,
        order: 1,
        radius: 3.3,
      },
      {
        type: "line",
        label: dataAmount[1].title,
        data: value[1],
        borderColor: dataAmount[1].color,
        fill: false,
        borderWidth: 1,
        backgroundColor: dataAmount[1].color,
        pointBorderColor: "#fff",
        tension: 0.1,
        order: 1,
        radius: 3.3,
      },
      {
        type: "line",
        label: dataAmount[0].title,
        data: value[2],
        borderColor: dataAmount[0].color,
        fill: false,
        borderWidth: 1,
        backgroundColor: dataAmount[0].color,
        pointBorderColor: "#fff",
        tension: 0.1,
        order: 1,
        radius: 3.3,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    categoryPercentage: 0.38,

    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          color: "#43FCFF",
          font: {
            size: 8,
            family: "Montserrat",
          },
          callback: (value) => {
            return convertValueToText(value);
          },
        },
        grid: {
          color: "rgba(255, 255, 255, 0.02)",
          borderColor: "rgba(255, 255, 255, 0.02)",
          tickColor: "rgba(255, 255, 255, 0.02)",
        },
        border: {
          display: false,
        },
      },
      x: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          color: "#43FCFF",
          font: {
            size: 7,
            family: "Montserrat",
            type: "line",
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    pointLabels: {
      color: "#8186AF",
    },
  };

  return <Bar data={data} options={options} />;
};
