import Grid from "@mui/material/Grid";
import { Element } from "react-scroll";

import ArrowPrimary from "../../../../resources/icon/ArrowPtimary";

import "./investmentCase.scss";

function InvestmentCase(props) {
  return (
    <Element name="Investment case">
      <h2 className="h2-title">Investment case</h2>
      <div className="box">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className="style-text">
              For the purpose of partial financing, IME GmbH intends to take up
              qualified subordinated loans of a maximum of € 1,000,000.
              <br />
              <br />
              The borrower is the owner of the property with the address
              Schillergasse 6-8 in 2351 Wiener Neudorf. A total of 63
              residential units with a weighted usable residential area of 2,200
              sqm and 63 underground parking spaces will be realised. The
              properties have been acquired, the planning phase has been
              completed and the building permit has already been issued.
              Construction is scheduled to start in May 2020. It is expected
              that construction work will be completed by summer 2022.
            </p>
            <div className="deal-highlights">
              <div className="deal-highlights__title-wrapper">
                <ArrowPrimary />
                <h3 className="deal-highlights__title">Yearly distribution:</h3>
              </div>

              <p className="deal-highlights__description">
                During the term, the lender receives of 7% per year.
              </p>
            </div>
            <div className="deal-highlights">
              <div className="deal-highlights__title-wrapper">
                <ArrowPrimary />
                <h3 className="deal-highlights__title">Private garden areas</h3>
              </div>

              <p className="deal-highlights__description">
                Private garden areas The lender receives his invested capital
                back after the sale of the apartments. According to the plan,
                this should take place after 30 months at the latest.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </Element>
  );
}

export default InvestmentCase;
