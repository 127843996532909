import React from "react";
import { Box, Grid } from "@mui/material";
import "./popUpInfo.scss";
import { ButtonExit } from "../../sidebar/SidebarWrapper";
import { ReactComponent as LogoModal } from "../../../resources/icon/LogoModal.svg";

function PopUpInfo(props) {
  return (
    <Box className="s-modal">
      <div className="s-modal__top">
        <div className="s-modal__logo">
          <LogoModal />
        </div>
        <button onClick={props.handleClose} className="circle-bg">
          <ButtonExit />
        </button>
      </div>
      <h2 className="h2-title s-modal__title">{props.title}</h2>
      <div className="s-modal__main-content">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="info-popup">
              <div className="info-popup__info">{props.info}</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default PopUpInfo;
