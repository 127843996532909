function ArrowPtimary(props) {
  return (
    <div
      className="circle-bg"
      style={{
        paddingLeft: "2px",
        minWidth: "30px",
        height: "30px",
      }}
    >
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5358 7.24899L7.61679 14.299H0.00878906L5.9328 7.25398L0.00878906 0.208984H7.6088L13.5358 7.24899Z"
          fill="url(#paint0_linear_0_14487)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_0_14487"
            x1="4.03984"
            y1="7.25398"
            x2="16.4035"
            y2="7.25398"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FA544D" />
            <stop offset="1" stop-color="#9A0000" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default ArrowPtimary;
