import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./testSlice/testSlice";
import itemProjectSlice from "./itemProjectSlice/itemProjectSlice";
import filterMapSlice from "./filterMap/filterMapSlice";
import filterCardsSlice from "./filterCardsSlice/filterCardsSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    itemProjectSlice,
    filterMapSlice,
    filterCardsSlice,
  },
});
