export const companyNewData = [
  {
    id: 200,
    title: "A new skyscraper in the city center reaches",
    description:
      "A new skyscraper in the city center reaches a staggering height of 300 meters! It's set to become an architectural marvel and an iconic landmark of the city.",
    pictures: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
  },
  {
    id: 201,
    title: "The construction of a bridge across the river",
    description:
      "The construction of a magnificent bridge across the river is finally complete. Now pedestrians can safely cross to the other bank, enhancing connectivity and convenience.",
    pictures:
      "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
  },
  {
    id: 202,
    title: "Company X has started the construction of",
    description:
      "Company X embarks on an ambitious project, constructing a new residential complex. The future residents can look forward to modern amenities, breathtaking views, and a comfortable lifestyle.",
    pictures:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
  },
  {
    id: 203,
    title: "A new shopping center has opened in the city",
    description:
      "Exciting news for the city dwellers! A state-of-the-art shopping center has opened its doors, offering a wide range of shops, boutiques, restaurants, and entertainment options.",
    pictures:
      "https://dekorin.me/wp-content/uploads/2017/11/5-dvuhetajnii-krasivii-dom.jpg",
  },
  {
    id: 204,
    title: "Construction company Y has been awarded a",
    description:
      "Construction company Y receives a prestigious award for its outstanding work. The company has been entrusted with the renovation of an old building, which is expected to become a historical landmark.",
    pictures: "https://belgorodproekt.ru/wp-content/uploads/2020/05/01-1-1.jpg",
  },
  {
    id: 205,
    title: "The government agency has allocated funds for",
    description:
      "The government agency has allocated substantial funds for the construction of new schools in the suburbs. This initiative aims to ensure that every child has access to quality education.",
    pictures: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
  },
  {
    id: 206,
    title: "The construction of a new airport has begun",
    description:
      "The construction of a new airport has officially commenced. Once completed, it will enhance regional connectivity, stimulate tourism, and boost economic growth in the area.",
    pictures:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
  },
  {
    id: 207,
    title: "New residential project offers luxurious homes",
    description:
      "Introducing an exclusive residential project that offers luxurious homes with exquisite designs and high-end features. Experience the epitome of luxury living in these stunning residences.",
    pictures:
      "https://eco-house.kiev.ua/wp-content/uploads/2019/06/dom-delta.jpg",
  },
  {
    id: 208,
    title: "Grand opening of the modern office complex",
    description:
      "Mark your calendars for the grand opening of a modern office complex. This state-of-the-art facility provides a conducive environment for businesses to thrive and succeed.",
    pictures:
      "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
  },
  {
    id: 209,
    title: "Renowned architect designs iconic landmark",
    description:
      "A renowned architect has unveiled plans for an iconic landmark. The design showcases innovation, creativity, and visionary thinking that will leave a lasting impression on the cityscape.",
    pictures: "https://belgorodproekt.ru/wp-content/uploads/2020/05/01-1-1.jpg",
  },
  {
    id: 210,
    title: "Exclusive waterfront properties available now",
    description:
      "Indulge in the allure of waterfront living. Discover a collection of exclusive properties with breathtaking views, tranquil surroundings, and unmatched serenity.",
    pictures: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
  },
  {
    id: 211,
    title: "Innovative green building receives prestigious award",
    description:
      "Celebrations abound as an innovative green building receives a prestigious award for its sustainable design and eco-friendly features. It sets a new benchmark for environmentally conscious constructions.",
    pictures: "https://belgorodproekt.ru/wp-content/uploads/2020/05/01-1-1.jpg",
  },
  {
    id: 212,
    title: "New housing development focuses on sust",
    description:
      "A new housing development is underway, focusing on sustainable living. These eco-friendly homes aim to reduce the carbon footprint and promote a healthier and greener lifestyle.",
    pictures:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
  },
  {
    id: 213,
    title: "Historic building undergoes extensive restoration",
    description:
      "Witness the transformation of a historic building through extensive restoration efforts. The revitalization aims to preserve its rich heritage while incorporating modern elements for a renewed purpose.",
    pictures:
      "https://dekorin.me/wp-content/uploads/2017/11/5-dvuhetajnii-krasivii-dom.jpg",
  },
  {
    id: 214,
    title: "Exciting investment opportunities estate market",
    description:
      "Exciting investment opportunities await in the real estate market. Explore a diverse range of properties that promise high returns and long-term value appreciation.",
    pictures: "https://belgorodproekt.ru/wp-content/uploads/2020/05/01-1-1.jpg",
  },
  {
    id: 215,
    title: "New regulations aim to improve construction safety",
    description:
      "New regulations have been introduced to improve construction safety standards. These measures prioritize the well-being of workers and ensure the integrity and quality of buildings.",
    pictures: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
  },
  {
    id: 216,
    title: "Affordable housing initiative addresses ",
    description:
      "Addressing the housing shortage, an affordable housing initiative aims to provide accessible and affordable homes for those in need. This initiative seeks to create inclusive communities for all.",
    pictures:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
  },
  {
    id: 217,
    title: "Growth in the construction industry boosts",
    description:
      "The construction industry continues to thrive, contributing to economic growth and job creation. The upward trajectory indicates a promising future for the sector and the overall economy.",
    pictures:
      "https://eco-house.kiev.ua/wp-content/uploads/2019/06/dom-delta.jpg",
  },
  {
    id: 218,
    title: "High demand for eco-friendly homes",
    description:
      "Experience the rising demand for eco-friendly homes. Buyers are increasingly seeking sustainable features and energy-efficient designs that align with their environmental values.",
    pictures:
      "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
  },
  {
    id: 219,
    title: "Luxury condominiums redefine urban living",
    description:
      "Discover a new standard of urban living with luxury condominiums. These meticulously crafted residences redefine sophistication, offering an array of upscale amenities and unparalleled comfort.",
    pictures: "https://belgorodproekt.ru/wp-content/uploads/2020/05/01-1-1.jpg",
  },
  {
    id: 220,
    title: "A new skyscraper in the city center reaches",
    description:
      "A new skyscraper in the city center reaches a staggering height of 300 meters! It's set to become an architectural marvel and an iconic landmark of the city.",
    pictures: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
  },
];

// const companyNewData1 = [];

// for (let i = 0; i < 20; i++) {
//   const newData = {
//     id: 200 + i,
//     title: title[i % title.length],
//     description: description[i % description.length],
//     pictures: pictures[i % pictures.length][0].url,
//   };

//   companyNewData1.push(newData);
// }

// console.log("companyNewData", companyNewData1);
