export const userNames = [
  { name: "John", secondname: "Doe" },
  { name: "Jane", secondname: "Smith" },
  { name: "David", secondname: "Johnson" },
  { name: "Alex", secondname: "Brown" },
  { name: "Emily", secondname: "Davis" },
  { name: "Michael", secondname: "Wilson" },
  { name: "Emma", secondname: "Thompson" },
  { name: "Daniel", secondname: "Anderson" },
  { name: "Olivia", secondname: "Martinez" },
  { name: "Matthew", secondname: "Taylor" },
  { name: "Sophia", secondname: "Clark" },
  { name: "Andrew", secondname: "Lee" },
  { name: "Isabella", secondname: "Walker" },
  { name: "Joshua", secondname: "Green" },
  { name: "Ava", secondname: "Hill" },
  { name: "William", secondname: "Baker" },
  { name: "Mia", secondname: "Gonzalez" },
  { name: "James", secondname: "Nelson" },
  { name: "Charlotte", secondname: "Anderson" },
  { name: "Benjamin", secondname: "Wright" },
  { name: "Abigail", secondname: "Perez" },
  { name: "Joseph", secondname: "Thomas" },
  { name: "Harper", secondname: "Jackson" },
  { name: "Daniel", secondname: "Turner" },
  { name: "Ella", secondname: "White" },
  { name: "Henry", secondname: "Harris" },
];
