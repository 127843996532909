import React from "react";

import { Box, Grid } from "@mui/material";

import "./investmentConfirmation.scss";

import { ButtonExit } from "../../sidebar/SidebarWrapper";
import { ReactComponent as LogoModal } from "../../../resources/icon/LogoModal.svg";
import { ReactComponent as Promo } from "../../../resources/icon/PopUp/Promo.svg";

const dataDetails = [
  {
    left: "Amount",
    right: "€425.00/0.01389479 BTC",
  },
  {
    left: "Return",
    right: "7%",
  },
  {
    left: "Duration",
    right: "36 months",
  },
  {
    left: "Distribution",
    right: "30 months",
  },
  {
    left: "Financial instrument",
    right: "Qualified Loan",
  },
  {
    left: "Issuer",
    right: "IME GmbH",
  },
  {
    left: "Payment method",
    right: "Kreditkarte",
  },
  {
    left: "Payment time",
    right: "Instant",
  },
  {
    left: "Terms and conditions",
    right: "Instant",
  },
  {
    left: "Loan contract",
    right: "Instant",
  },
  {
    left: "Fact sheet",
    right: "Instant",
  },
];

function InvestmentConfirmation(props) {
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  const [isButtonEnabled, setButtonEnabled] = React.useState(false);

  const handleCheckboxChange = (event, checkboxNumber) => {
    event.preventDefault();

    if (checkboxNumber === 1) {
      setChecked1(!checked1);
    } else if (checkboxNumber === 2) {
      setChecked2(!checked2);
    } else if (checkboxNumber === 3) {
      setChecked3(!checked3);
    }
  };

  React.useEffect(() => {
    if (checked1 && checked2 && checked3) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [checked1, checked2, checked3]);

  return (
    <Box className="s-modal">
      <div className="s-modal__top">
        <div className="s-modal__logo">
          <LogoModal />
        </div>
        <button onClick={props.handleCloseNext} className="circle-bg">
          <ButtonExit />
        </button>
      </div>
      <h2 className="h2-title s-modal__title">Investment confirmation</h2>
      <div className="s-modal__main-content">
        <Grid item xs={12}>
          {dataDetails.slice(0, 8).map(({ left, right }, i) => {
            return (
              <div className="info" key={i}>
                <div className="left-title">{left}</div>
                <div className="rigth-title">{right}</div>
              </div>
            );
          })}

          <div className="next-modal">
            <label className="input-name">Loan amount</label>
            <label className="input-label">
              <div className="input-icon">
                <Promo />
              </div>

              <input
                type="number"
                placeholder="Enter your promo code here"
                className="input-calculator s-modal__input-modal next-modal__input"
              />
              <button className="next-modal__bt-input">Redeem</button>
            </label>

            {dataDetails.slice(8).map(({ left }, i) => {
              return (
                <div className="info" key={i}>
                  <div className="left-title">{left}</div>
                  <a href="./test.xlsx" download>
                    <button className="button-general next-modal__bt">
                      Download
                    </button>
                  </a>
                </div>
              );
            })}

            <div className="main-checkbox-container">
              <label className="checkbox-container">
                <div>
                  <input
                    type="checkbox"
                    checked={checked1}
                    onChange={(event) => handleCheckboxChange(event, 1)}
                  />
                  <span className="custom-checkbox"></span>
                </div>
                <p className="main-checkbox-container__description">
                  By confirming you agree to thetermsof Dlendo GmbH and accept
                  them hereby.
                </p>
              </label>

              <label className="checkbox-container">
                <div>
                  <input
                    type="checkbox"
                    checked={checked2}
                    onChange={(event) => handleCheckboxChange(event, 2)}
                  />
                  <span className="custom-checkbox"></span>
                </div>
                <p className="main-checkbox-container__description">
                  I am taking the note of the{" "}
                  <a href="http://google.com">loan contract / cancellation</a>{" "}
                  on instructionand Fact Sheetand accept them.
                </p>
              </label>

              <label className="checkbox-container">
                <div>
                  {" "}
                  <input
                    type="checkbox"
                    checked={checked3}
                    onChange={(event) => handleCheckboxChange(event, 3)}
                  />
                  <span className="custom-checkbox"></span>
                </div>
                <p className="main-checkbox-container__description">
                  I am aware of therisk.
                </p>
              </label>
            </div>
            <button
              className={`button-general main-checkbox-container__bt ${
                !isButtonEnabled ? "disable-bt" : ""
              }`}
              disabled={!isButtonEnabled}
              onClick={props.handleCloseNext}
            >
              Confirm
            </button>
          </div>
        </Grid>
      </div>
    </Box>
  );
}

export default InvestmentConfirmation;
