import React from "react";
import { Grid } from "@mui/material";
import PartnershipTitleChart from "../../charts/partnershipTitleChart/PartnershipTitleChart";
import ReferralCommissions from "../../charts/referralCommissions/ReferralCommissions";
import GenderRatio from "../../charts/genderRatio/GenderRatio";
import ReferralInvolvement from "../../charts/referralInvolvement/ReferralInvolvement";
import GlobalInvolvement from "../../charts/globalInvolvement/GlobalInvolvement";
import ShareYourIink from "../../fewSimpleComponents/shareYourIink/ShareYourIink";
import AnvestmentsAndAccruals from "../../charts/investmentsAndAccruals/InvestmentsAndAccruals";
import Cashback from "../../charts/cashback/Cashback";
import Transactions from "../../table/transactions/Transactions";

function Partnership(props) {
  const timeoutForSkeleton = 3000;

  const [value, setValue] = React.useState(false);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue(true);
    }, timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Grid container spacing={3}>
        <PartnershipTitleChart
          timeoutForSkeleton={timeoutForSkeleton}
          timeoutForSkeletonValue={value}
        />
        <ReferralCommissions
          timeoutForSkeleton={timeoutForSkeleton}
          timeoutForSkeletonValue={value}
        />
        <GenderRatio
          timeoutForSkeleton={timeoutForSkeleton}
          timeoutForSkeletonValue={value}
        />
        <ReferralInvolvement
          timeoutForSkeleton={timeoutForSkeleton}
          timeoutForSkeletonValue={value}
        />
        <GlobalInvolvement timeoutForSkeletonValue={value} />
        <ShareYourIink />
        <AnvestmentsAndAccruals
          timeoutForSkeleton={timeoutForSkeleton}
          timeoutForSkeletonValue={value}
        />
        <Cashback timeoutForSkeleton={timeoutForSkeleton} />
        <Transactions timeoutForSkeleton={timeoutForSkeleton} />
      </Grid>
    </div>
  );
}

export default Partnership;
