import "./page404.scss";

function Page404(props) {
  return (
    <div className="page-404">
      <div>
        <h1>404</h1>
        <h2>This page doesn't exist</h2>
      </div>
    </div>
  );
}

export default Page404;
