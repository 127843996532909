import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { filterCompanyNewById } from "../../../store/itemProjectSlice/itemProjectSlice";
import { Box, Grid, Grow } from "@mui/material";

import { companyNewData } from "../../../data/companyNewe/companyNewData";

import "./companyNews.scss";
import { ReactComponent as CompanyNewIcon } from "../../../resources/icon/CompanyNews/CompanyNews.svg";
import { ReactComponent as Views } from "../../../resources/icon/CompanyNews/views.svg";
import { ReactComponent as New } from "../../../resources/icon/Dashboard/New.svg";
import { ReactComponent as ProjectsNews } from "../../../resources/icon/CompanyNews/ProjectsNews.svg";
import { ReactComponent as DlendoReports } from "../../../resources/icon/CompanyNews/DlendoReports.svg";
import logoDlendo from "../../../resources/icon/CompanyNews/LogoDlendo.png";
import { SkInfoNews, SkeletonCompanyNews } from "./SkeletonCompanyNews";
import { ReactComponent as SkeletonImg } from "../../../resources/icon/CompanyNews/SkeletonImg.svg";
import { ButtonExit } from "../../sidebar/SidebarWrapper";
import { ReactComponent as ButtonList } from "../../../resources/elements/ButtonList.svg";

function CompanyNews(props) {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }}>
      {isLoading ? <SkeletonCompanyNews /> : <CompanyNewsView />}
    </Box>
  );
}

function CompanyNewsView(props) {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenSecondMenu, setIsOpenSecondMenu] = React.useState(false);

  const [visibleProjectsNewsCards, setVisibleProjectsNewsCards] =
    React.useState(4);
  const [visibleDlendoReportsCards, setVisibleDlendoReportsCards] =
    React.useState(3);
  const [visibleCards, setVisibleCards] = React.useState(4);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingProjectsNews, setIsLoadingProjectsNews] =
    React.useState(false);
  const [isLoadingReportsCards, setIsLoadingReportsCards] =
    React.useState(false);

  const cardsContainerRef = React.useRef(null);
  const cardsDlendoReportsRef = React.useRef(null);

  const sendIdByAction = (id) => {
    dispatch(filterCompanyNewById(id));
    console.log("id", id);
  };

  const handleLoadMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 2);
    setIsLoading(true);
  };

  const handleLoadMoreProjectsNews = () => {
    setVisibleProjectsNewsCards((prevVisibleCards) => prevVisibleCards + 2);
    setIsLoadingProjectsNews(true);
  };

  const handleLoadMoreDlendoReportsCards = () => {
    setVisibleDlendoReportsCards((prevVisibleCards) => prevVisibleCards + 2);
    setIsLoadingReportsCards(true);
  };

  React.useEffect(() => {
    if (visibleCards > visibleCards - 2) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [visibleCards]);

  React.useEffect(() => {
    if (cardsContainerRef.current) {
      const lastCard = cardsContainerRef.current.lastChild;
      if (lastCard) {
        lastCard.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [visibleProjectsNewsCards]);

  React.useEffect(() => {
    if (cardsDlendoReportsRef.current) {
      const lastCard = cardsDlendoReportsRef.current.lastChild;
      if (lastCard) {
        lastCard.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [visibleDlendoReportsCards]);

  React.useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, [visibleCards]);

  React.useEffect(() => {
    setIsLoadingProjectsNews(true);
    setTimeout(() => {
      setIsLoadingProjectsNews(false);
    }, 2500);
  }, [visibleProjectsNewsCards]);

  React.useEffect(() => {
    setIsLoadingReportsCards(true);
    setTimeout(() => {
      setIsLoadingReportsCards(false);
    }, 2500);
  }, [visibleDlendoReportsCards]);

  const buttonName = isOpenSecondMenu ? "Projects news" : "Dlendo reports";

  return (
    <div className="comp-news">
      <div className="comp-news__main-content">
        <div className="comp-news__box-title">
          <div className="comp-news__icon">
            <CompanyNewIcon />
          </div>
          <h2 className="comp-news__title">Company news</h2>
        </div>

        <div className="main-news">
          <Grid container spacing={3} justifyContent="center">
            {companyNewData.slice(0, 2).map(({ title, pictures, id }, i) => {
              return (
                <Grid item xs={12} sm={6} md={6}>
                  <Link
                    key={i}
                    to={`/company-news/new/${id}`}
                    className="box-p main-news__item"
                    onClick={() => sendIdByAction(id)}
                  >
                    <button className="circle-bg">
                      <New />
                    </button>
                    <img src={pictures} alt="photos" />
                    <div className="main-news__info-wrapper">
                      <InfoNews title={title} />
                    </div>
                  </Link>
                </Grid>
              );
            })}
            {companyNewData
              .slice(2, visibleCards + 2)
              .map(({ title, pictures, id }, i) => (
                <Grid item xs={12} sm={6} md={6}>
                  <Grow
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(true ? { timeout: 400 } : {})}
                  >
                    <Link
                      key={i}
                      to={`/company-news/new/${id}`}
                      className="box-p card-news__item"
                      onClick={() => sendIdByAction(id)}
                    >
                      {isLoading &&
                      i >= visibleCards - 2 &&
                      i < visibleCards ? (
                        <>
                          <div className="skeleton-card-news__img">
                            <SkeletonImg />
                          </div>
                          <SkInfoNews />
                        </>
                      ) : (
                        <>
                          <img src={pictures} alt="photos" />
                          <InfoNews title={title} />
                        </>
                      )}
                    </Link>
                  </Grow>
                </Grid>
              ))}
          </Grid>
        </div>
        {visibleCards < companyNewData.length - 2 && (
          <div className="comp-news__btn" onClick={handleLoadMore}>
            <button className="button-general">More news</button>
          </div>
        )}

        <div className="main-news__b-menu">
          {isOpen && (
            <button
              className="circle-bg main-news__but-b-menu main-news__style-center-btn"
              onClick={() => setIsOpenSecondMenu(!isOpenSecondMenu)}
            >
              {buttonName}
            </button>
          )}
          <button
            className="toggle-button main-news__but-b-menu"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <ButtonExit /> : <ButtonList />}
          </button>
        </div>
      </div>

      <div className={`comp-news__left-panel ${isOpen ? "close-menu" : ""}`}>
        <div
          className={`box-p project-news ${
            isOpenSecondMenu ? "nidden-new" : ""
          }`}
        >
          <div className="comp-news__box-title">
            <div className="comp-news__icon">
              <ProjectsNews />
            </div>
            <h2 className="comp-news__title">Projects news</h2>
          </div>
        </div>
        <div
          className={`project-news__container project-news__height ${
            isOpenSecondMenu ? "nidden-new" : ""
          }`}
          ref={cardsContainerRef}
        >
          {companyNewData
            .slice(0, visibleProjectsNewsCards)
            .map(({ pictures, title }, i) => {
              return (
                <div key={i} className="card-news__item project-news__item">
                  <div className="project-news__wrapper">
                    {isLoadingProjectsNews &&
                    i >= visibleProjectsNewsCards - 2 &&
                    i < visibleProjectsNewsCards ? (
                      <>
                        <div className="skeleton-project-news__img">
                          <SkeletonImg />
                        </div>
                        <SkInfoNews />
                      </>
                    ) : (
                      <>
                        <img src={pictures} alt="photos" />
                        <InfoNewsForMenu title={title} />
                      </>
                    )}
                  </div>
                  <div className="info-news__divider"></div>
                </div>
              );
            })}

          {visibleProjectsNewsCards < companyNewData.length - 2 && (
            <div
              className="comp-news__btn"
              style={{ marginTop: "22px" }}
              onClick={handleLoadMoreProjectsNews}
            >
              <button className="button-general">More news</button>
            </div>
          )}
        </div>

        <div className="box-p project-news br-t">
          <div className="comp-news__box-title">
            <div className="comp-news__icon">
              <DlendoReports />
            </div>
            <h2 className="comp-news__title">Dlendo reports</h2>
          </div>
        </div>
        <div className="project-news__container ht" ref={cardsDlendoReportsRef}>
          {companyNewData
            .slice(0, visibleDlendoReportsCards)
            .map(({ title }, i) => {
              return (
                <div className="card-news__item project-news__item">
                  <div className="project-news__wrapper">
                    {isLoadingReportsCards &&
                    i >= visibleDlendoReportsCards - 2 &&
                    i < visibleDlendoReportsCards ? (
                      <>
                        <div className="dlendor-eports-sk"></div>
                        <SkInfoNews oneLine={true} />
                      </>
                    ) : (
                      <>
                        <div className="dlendor-eports">
                          <img src={logoDlendo} alt="photos" />
                        </div>
                        <InfoNewsForMenu title={title.slice(0, 30) + "..."} />
                      </>
                    )}
                  </div>
                  <div className="info-news__divider"></div>
                </div>
              );
            })}
          {visibleDlendoReportsCards < companyNewData.length - 2 && (
            <div
              className="comp-news__btn"
              style={{ marginTop: "22px" }}
              onClick={handleLoadMoreDlendoReportsCards}
            >
              <button className="button-general">More reports </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function InfoNews({ title }) {
  return (
    <div className="info-news">
      <div className="info-news__wrapper">
        <div className="info-news__views">
          <div className="info-news__data">08/11/2020</div>
          <div className="info-news__divider-y"></div>
          <div className="info-news__view">
            <div className="info-news__icon">
              <Views />
            </div>
            <div className="info-news__amount">635</div>
          </div>
        </div>
        <div className="info-news__hashtag">#hashtag</div>
      </div>
      <div className="info-news__divider"></div>
      <div className="info-news__description">{title}</div>
    </div>
  );
}

function InfoNewsForMenu({ title }) {
  return (
    <div className="info-news">
      <div className="info-news__wrapper">
        <div className="info-news__views">
          <div className="info-news__data">08/11/2020</div>
          <div className="info-news__divider-y"></div>
          <div className="info-news__view">
            <div className="info-news__icon">
              <Views />
            </div>
            <div className="info-news__amount">635</div>
          </div>
        </div>
        <div className="info-news__hashtag">#hashtag</div>
      </div>

      <div className="info-news__description mt-0">{title}</div>
    </div>
  );
}

export { CompanyNews, InfoNewsForMenu };
