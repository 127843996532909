import React from "react";
import { Grid } from "@mui/material";

import "./shareYourIink.scss";
import { ReactComponent as LinkCopy } from "../../../resources/icon/Table/LinkCopy.svg";
import { ReactComponent as CopyButton } from "../../../resources/icon/Table/CopyButton.svg";
import InstagramIcon from "@mui/icons-material/Instagram";
import {
  YouTube,
  LinkedIn,
  Twitter,
  Telegram,
  Facebook,
} from "@mui/icons-material";

function ShareYourIink(props) {
  const [hoverActive, setHoverActive] = React.useState(false);
  const [hoverActiveSecond, setHoverActiveSecond] = React.useState(false);

  const copyRefs = React.useRef(null);
  const copySecondRefs = React.useRef(null);

  const handleIconClickCopy = (valuecCopyRefs) => {
    const inputValue = valuecCopyRefs.current.value;
    if (inputValue) {
      navigator.clipboard
        .writeText(inputValue)
        .then(() => {
          console.log("Value copied to clipboard:", inputValue);
        })
        .catch((error) => {
          console.error("Error copying value to clipboard:", error);
        });
    }
  };
  return (
    <Grid item xs={12} sm={12} md={6}>
      <div className="boxs-chart" style={{ height: "380px" }}>
        <div className="boxs-chart__title">
          <h4>Share your link</h4>
        </div>
        <div className="boxs-chart__container" style={{ paddingTop: "10px" }}>
          <div className="data-input copy-wrapper">
            <div className={`data-input__wrapper `} style={{ width: "100%" }}>
              <div className="data-input__left-icon">
                <LinkCopy />
              </div>
              <div className="data-input__input-general">
                <input value="https://Dlendo.com/LaurensBerns" ref={copyRefs} />
                <label>Referral link</label>
              </div>
            </div>
            <button
              className="button-general copy-bt"
              onClick={() => handleIconClickCopy(copyRefs)}
              onMouseEnter={() => setHoverActive(true)}
              onMouseLeave={() => setHoverActive(false)}
            >
              <div className="copy-icon">
                <CopyButton className={hoverActive && "hover-svg"} />
              </div>
              Copy
            </button>
          </div>

          <div className="data-input copy-wrapper">
            <div className={`data-input__wrapper `} style={{ width: "100%" }}>
              <div className="data-input__left-icon">
                <LinkCopy />
              </div>
              <div className="data-input__input-general">
                <input value="#7gi86f" ref={copySecondRefs} />
                <label>Referral code</label>
              </div>
            </div>
            <button
              className="button-general copy-bt"
              onClick={() => handleIconClickCopy(copySecondRefs)}
              onMouseEnter={() => setHoverActiveSecond(true)}
              onMouseLeave={() => setHoverActiveSecond(false)}
            >
              <div className="copy-icon">
                <CopyButton className={hoverActiveSecond && "hover-svg"} />
              </div>
              Copy
            </button>
          </div>

          <div className="link-title">
            <div className="link-title__divider"></div>
            <div className="link-title__text">
              Or share your link via social media
            </div>
            <div className="link-title__divider"></div>
          </div>

          <div className="link-sharing">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/"
              className="link-sharing__item"
            >
              <InstagramIcon sx={{ color: "#fff" }} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/"
              className="link-sharing__item"
            >
              <YouTube sx={{ color: "#fff" }} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/"
              className="link-sharing__item"
            >
              <LinkedIn sx={{ color: "#fff" }} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.twitter.com/"
              className="link-sharing__item"
            >
              <Twitter sx={{ color: "#fff" }} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.telegram.org/"
              className="link-sharing__item"
            >
              <Telegram sx={{ color: "#fff" }} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/"
              className="link-sharing__item"
            >
              <Facebook sx={{ color: "#fff" }} />
            </a>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default ShareYourIink;
