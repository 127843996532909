import React from "react";
import { Box, Grid, Modal } from "@mui/material";

import "./telephoneVerification.scss";
import PopUpInfo from "../../popUps/popUpInfo/PopUpInfo";
import { ButtonExit } from "../../sidebar/SidebarWrapper";
import { ReactComponent as LogoModal } from "../../../resources/icon/LogoModal.svg";
import { ReactComponent as Telephone } from "../../../resources/icon/SettingProfile/Telephone.svg";
import { ReactComponent as Attention } from "../../../resources/icon/SettingProfile/Attention.svg";

const ContextForInput = React.createContext();

const info =
  "Verification has been passed, now you can use all the features of the Dlendo. Now you have access to be informed about new offers and two-factor authentication by phone number";
const title = "Verification complete";

function TelephoneVerification(props) {
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(false);

  const handleCloseInfo = () => {
    setOpenInfo(false);
    props.handleClose();
  };
  const handleOpenInfo = () => setOpenInfo(true);

  let inputArray = [];

  function handleErrorMessage(value) {
    return (inputArray = value);
  }

  const submitCode = () => {
    if (inputArray.some((element) => element === "")) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      handleOpenInfo();
      setTimeout(() => props.handleClose(), 7000);
    }
  };

  return (
    <Box className="s-modal">
      <div className="s-modal__top">
        <div className="s-modal__logo">
          <LogoModal />
        </div>
        <button onClick={props.handleClose} className="circle-bg">
          <ButtonExit />
        </button>
      </div>
      <h2 className="h2-title s-modal__title">Telephone verification</h2>
      <div className="s-modal__main-content">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="data-input">
              <div className={`data-input__wrapper `}>
                <div className="data-input__left-icon">
                  <Telephone />
                </div>
                <div className="tel-verification">
                  <div className="tel-verification__input">
                    <ContextForInput.Provider value={handleErrorMessage}>
                      <InputCode
                        length={6}
                        loading={loading}
                        onComplete={(code) => {
                          setLoading(true);
                          setTimeout(() => setLoading(false), 10000);
                        }}
                      />
                    </ContextForInput.Provider>
                  </div>
                  <label>Verification code</label>

                  <div className="attention-label attention-label__tel-verification">
                    <Attention />
                  </div>
                </div>

                <div className="data-input__icon-right data-input__verify data-input__icon-right_tel-verification">
                  Send code again
                </div>
              </div>
            </div>
            {errorMessage ? (
              <div
                className="error-description"
                style={{ margin: "10px 0 -10px 0" }}
              >
                <ul className="error-description__error-list">
                  <li className="error-description__error-item">
                    Incorrect verification code
                  </li>
                </ul>
              </div>
            ) : null}

            <div className="d-flex">
              <div className="taxt-setting__description-attention">
                <Attention />
              </div>
              <div className="taxt-setting__description">
                A verification code has been sent to you. Please fill out the
                form to successfully complete the verification.
              </div>
            </div>

            <button
              className={`button-general main-checkbox-container__bt `}
              onClick={submitCode}
            >
              Verify telephone number
            </button>
          </Grid>
        </Grid>
      </div>
      <Modal className="custom-modal" open={openInfo} onClose={handleCloseInfo}>
        <PopUpInfo handleClose={handleCloseInfo} title={title} info={info} />
      </Modal>
    </Box>
  );
}

export default TelephoneVerification;

const InputCode = ({ length, loading, onComplete }) => {
  const [code, setCode] = React.useState([...Array(length)].map(() => ""));
  const inputs = React.useRef([]);
  const handleErrorMessage = React.useContext(ContextForInput);

  handleErrorMessage(code);

  // Typescript
  // useRef<(HTMLInputElement | null)[]>([])

  const processInput = (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every((num) => num !== "")) {
      onComplete(newCode.join(""));
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = "";
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <div className="code-input">
      <div className="code-inputs">
        {code.map((num, idx) => {
          return (
            <input
              key={idx}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              readOnly={loading}
              onChange={(e) => processInput(e, idx)}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => inputs.current.push(ref)}
            />
          );
        })}
      </div>
    </div>
  );
};
