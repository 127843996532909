import { Routes, Route } from "react-router-dom";
import "./app.scss";

import { Layout } from "../pages/layout/Layout";

import Item from "../pages/item/Item";
import Home from "../pages/home/Home";
import DlendoPortfolio from "../pages/dlendoPortfolio/DlendoPortfolio";
import Page404 from "../pages/404/Page404";
import Comparison from "../pages/comparison/Comparison";
import { CompanyNews } from "../pages/companyNews/CompanyNews";
import CompanyNewsItem from "../pages/companyNewsItem/CompanyNewsItem";
import SettingsPaga from "../pages/settingsPage/SettingsPaga";
import MyPortfolio from "../pages/myPortfolio/MyPortfolio";
import Partnership from "../pages/partnership/Partnership";
import MyWallet from "../pages/myWallet/MyWallet";

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="item/:id" element={<Item />} />
          <Route path="comparison" element={<Comparison />} />
          <Route path="settings" element={<SettingsPaga />} />
          <Route path="dlendo-portfolio" element={<DlendoPortfolio />} />
          <Route path="my-portfolio" element={<MyPortfolio />} />
          <Route path="my-wallet" element={<MyWallet />} />
          <Route path="partnership" element={<Partnership />} />
          <Route path="company-news" element={<CompanyNews />} />
          <Route path="company-news/new/:id" element={<CompanyNewsItem />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
