import React from "react";
import { Grid, Pagination } from "@mui/material";

import "./transactions.scss";
import SkeletonTransactions from "./SkeletonTransactions";

import { nDate } from "../investments/nData";
import data from "../../../data/data";
import { ResponsiveDatePickers } from "../../fewSimpleComponents/calender/Calendar";

import { ReactComponent as AmountFiat } from "../../../resources/icon/Table/AmountFiat.svg";
import { ReactComponent as ID } from "../../../resources/icon/Table/ID.svg";
import { ReactComponent as Date } from "../../../resources/icon/Table/Date.svg";
import { ReactComponent as DateBigger } from "../../../resources/icon/Table/DateBigger.svg";
import { ReactComponent as Operation } from "../../../resources/icon/Table/Operation.svg";
import { ReactComponent as Path } from "../../../resources/icon/SettingProfile/Path.svg";

function getRandomKnowledge() {
  const knowledge = ["Cashback", "Ref bonus"];
  const randomIndex = Math.floor(Math.random() * knowledge.length);
  return knowledge[randomIndex];
}

const dataOption = data.map((obj) => {
  return { ...obj, operation: getRandomKnowledge() };
});
function Transactions(props) {
  const [filtereData, setFiltereData] = React.useState(dataOption);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState(null);
  const [loadingSkeleton, setLoadingSkeleton] = React.useState(true);
  const [selectedStatus, setSelectedStatus] = React.useState(null);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoadingSkeleton(false);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    let filteredDate = filterByDate(dataOption);
    let filteredByStatus = filterByStatus(filteredDate);
    let passFilter = [...filteredByStatus];

    setFiltereData(passFilter);
    // eslint-disable-next-line
  }, [selectedDateFrom, selectedDateTo, selectedStatus]);

  const filterByStatus = (dataArr) => {
    return dataArr.filter((item) => {
      if (!selectedStatus || selectedStatus === "All operation") {
        return item;
      } else {
        return item.operation === selectedStatus;
      }
    });
  };

  const handleDateChangeFrom = (date) => {
    setSelectedDateFrom(date);
  };

  const handleDateChangeTo = (date) => {
    setSelectedDateTo(date);
  };

  const fromDate = selectedDateFrom;
  const toDate = selectedDateTo;

  const filterByDate = (data) => {
    return data.filter((item) => {
      let value;
      const itemDate = nDate(item.dateCreated);
      const filterFromDate = nDate(fromDate);
      const filterToDate = nDate(toDate);

      if (fromDate === null && toDate === null) {
        value = item;
      } else if (fromDate && toDate) {
        value = itemDate > filterFromDate && itemDate < filterToDate;
      } else if (fromDate) {
        value = itemDate > filterFromDate;
      } else if (toDate) {
        value = itemDate < filterToDate;
      }
      return value;
    });
  };

  const cardsPerPage = 6;

  const totalCards = filtereData.length;
  const pageCount = Math.ceil(totalCards / cardsPerPage);

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  //Get the current set of cards to display on the current page
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const currentCards = filtereData.slice(startIndex, endIndex);

  return (
    <Grid item xs={12}>
      <div className="boxs-chart" style={{ height: "635px" }}>
        <div className="boxs-chart__title">
          <h4>Transactions</h4>
        </div>
        <div
          className="boxs-chart__container"
          style={{ padding: "40px 20px 20px 20px", overflow: "auto" }}
        >
          <div className="filter-table__container">
            <div className="filter-table__wrapper">
              <div className="wrapper-options">
                <CustomSelectForDate onSelectChange={setSelectedStatus} />
              </div>
              <div
                className="wrapper-options d-flex"
                style={{ marginLeft: "20px" }}
              >
                <div className="wrapper-options__icon">
                  <DateBigger />
                </div>
                <div className="date-input-range">
                  <div className="date-input-range__name">
                    Select time period
                  </div>
                  <div className="date-input-range__input">
                    <ResponsiveDatePickers
                      handleDateChange={handleDateChangeFrom}
                    />
                    <span>-</span>
                    <ResponsiveDatePickers
                      handleDateChange={handleDateChangeTo}
                    />{" "}
                    {selectedDateFrom ? (
                      <div
                        onClick={() => {
                          setSelectedDateFrom(null);
                          setSelectedDateTo(null);
                        }}
                        className="cancel-filter"
                        style={{ marginLeft: "10px" }}
                      >
                        &#215;
                      </div>
                    ) : selectedDateTo ? (
                      <div
                        onClick={() => {
                          setSelectedDateFrom(null);
                          setSelectedDateTo(null);
                        }}
                        className="cancel-filter"
                        style={{ marginLeft: "10px" }}
                      >
                        &#215;
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <a
              href="./test.xlsx"
              download
              className="wrapper-options download-btn"
            >
              Download Area
            </a>
          </div>

          <div className="accruals-titles-table grid-table-trans-title">
            <div className="titles-table__item" style={{ paddingLeft: "25px" }}>
              Date
            </div>
            <div className="titles-table__item">Operation</div>
            <div className="titles-table__item">Amount</div>
            <div className="titles-table__item">Transaction ID</div>
          </div>

          <div style={{ minHeight: "338px" }}>
            {loadingSkeleton
              ? [...new Array(6)].map((item) => <SkeletonTransactions />)
              : currentCards.map((items, i) => {
                  return (
                    <div className="accruals-table grid-table-trans" key={i}>
                      <div className="accruals-table__Date">
                        <div className="accruals-table__Date-icon">
                          <Date />
                        </div>
                        <div className="accruals-table__Date-title">
                          <div>{items.dateCreated}</div>
                          <div>18:42 PM</div>
                        </div>
                      </div>

                      <div className="accruals-table__Term">
                        <div className="accruals-table__Term-icon operation-icon">
                          <Operation />
                        </div>
                        <div className="accruals-table__Term-title">
                          <div className="accruals-table__Status-title">
                            {items.operation}
                          </div>
                        </div>
                      </div>

                      <div className="accruals-table__Term">
                        <div className="accruals-table__Term-icon">
                          <AmountFiat />
                        </div>
                        <div className="accruals-table__Term-title">
                          <div style={{ color: "#43fcff" }}>
                            € {items.investmentTable.investment}
                          </div>
                        </div>
                      </div>

                      <div className="accruals-table__Status">
                        <div className="accruals-table__Status-icon">
                          <ID />
                        </div>
                        <div className="accruals-table__Status-title">
                          {items.category.transactionId}
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        {!loadingSkeleton && (
          <div className="table-pagination" style={{ marginTop: "15px" }}>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
            />
          </div>
        )}
      </div>
    </Grid>
  );
}

export default Transactions;

function CustomSelectForDate({ options, onSelectChange }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelectChange(option);
    setIsOpen(false);
  };

  return (
    <div className={`custom-selects  ${isOpen ? "open" : ""}`}>
      <div
        className="selected-option-castom selected-option-castom__tansaction d-flex"
        onClick={handleToggle}
      >
        {selectedOption ? (
          <span style={{ paddingLeft: "3px" }}>{selectedOption}</span>
        ) : (
          <span style={{ paddingLeft: "3px" }}>All operation</span>
        )}
      </div>
      <div
        className={`arrow arrow__tansaction ${isOpen ? "opened" : ""}`}
        style={{ right: 0 }}
      >
        <Path />
      </div>
      {isOpen && (
        <div className="options-castom options-castom__tansaction">
          {["All operation", "Cashback", "Ref bonus"].map((option, i) => (
            <div
              key={i}
              className="option-castom option-castom__tansaction d-flex"
              onClick={() => handleOptionClick(option)}
            >
              <span style={{ paddingLeft: "3px" }}>{option}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
