import React from "react";
import { Grid } from "@mui/material";
import { Bar } from "react-chartjs-2";

import "./income.scss";
import data from "../../../data/data";
import { CustomSelect } from "../diversifiaction/Diversifiaction";
import { ReactComponent as LineForChartInput } from "../../../resources/elements/LineForChartInput.svg";

function Income(props) {
  const [value, setValue] = React.useState(1);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue(70);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  const handleSelectChange = (value) => {
    setValue(value.investmentTable.rangeProcent);
  };

  const calculatePercentage = (value) => {
    if (value < 11) {
      return 10;
    } else if (value > 90) {
      return 90;
    } else {
      return value;
    }
  };

  const sliderStyles = {
    position: "relative",
    left: `calc(${calculatePercentage(value)}%)`,
    transform: "translateX(-50%)",
    pointerEvents: "none",
    transition: "left 2s",
    zIndex: 1,
    maxWidth: "100%",
    overflow: "hidden",
  };

  return (
    <Grid item xs={12} sm={12} md={4}>
      <h2 className="h2-title mt-0">Income</h2>
      <div className="income__container box-col">
        <div className="box-p income__item">
          <span className="income__title">ROI performance</span>
          <div className="diversifiaction-select" style={{ marginTop: "10px" }}>
            <CustomSelect
              options={data}
              onSelectChange={handleSelectChange}
              timeoutForSkeleton={props.timeoutForSkeleton}
            />
          </div>

          <div className="chart-input my-input-range">
            <div className="chart-input__position">
              <div className="chart-input__slider" style={sliderStyles}>
                <div className="chart-input__title-amount">
                  €{value}00<span>*</span>/{value}%
                </div>
              </div>
            </div>

            <div className="custom-slider">
              <div className="slider-track">
                <div
                  className="slider-fill"
                  style={{ width: `${value}%` }}
                ></div>
              </div>
            </div>

            <div className="chart-input__line">
              <div className="chart-input__wrapper-text">
                <div className="chart-input__text">€0</div>
                <div className="chart-input__text">€10000</div>
              </div>
              <LineForChartInput />
            </div>
          </div>
          <p className="income__return">Return on investment received</p>
          <p className="income__expected">
            Expected return on investment based on the previous performance
          </p>
        </div>

        <div className="box-p income__item">
          <span className="income__title">Annual income</span>
          <div className="chart-annual">
            <ChartComponentForAnnual
              timeoutForSkeleton={props.timeoutForSkeleton}
            />
          </div>
        </div>
        <div className="box-p  income__item">
          <span className="income__title">Quarterly income</span>
          <div className="chart-annual">
            <ChartComponentForQuarterly
              timeoutForSkeleton={props.timeoutForSkeleton}
            />
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default Income;

const ChartComponentForAnnual = (props) => {
  const [value, setValue] = React.useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue([
        1700, 500, 2300, 1100, 2100, 2500, 1900, 900, 1300, 1500, 700, 1700,
      ]);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  function convertValueToText(value) {
    if (value === 0) {
      return 0;
    }
    if (value >= 1000) {
      const thousands = value / 1000;
      return "€" + thousands.toFixed(1) + "k";
    } else {
      return "€" + value;
    }
  }
  const data = {
    labels: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
    datasets: [
      {
        type: "bar",
        label: "Bar",
        data: value,
        backgroundColor: "#43FCFF",
        borderRadius: 2,
        order: 2,
      },
      {
        type: "line",
        label: "Line",
        data: value,
        borderColor: "#43FCFF",
        fill: false,
        borderWidth: 1,
        pointBackgroundColor: "#43FCFF",
        pointBorderColor: "#fff",
        tension: 0.3,
        order: 1,
        radius: 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    categoryPercentage: 0.4,
    // onHover: ({ x, y }, activeHover, chart) => {
    //   console.log(chart.scales);
    // },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "#43FCFF",
          font: {
            size: 8,
            family: "Montserrat",
          },
          callback: (value) => {
            return convertValueToText(value);
          },
        },
        grid: {
          color: "rgba(255, 255, 255, 0.02)",
          borderColor: "rgba(255, 255, 255, 0.02)",
          tickColor: "rgba(255, 255, 255, 0.02)",
        },
        border: {
          display: false,
        },
      },
      x: {
        beginAtZero: true,
        ticks: {
          color: "#43FCFF",
          font: {
            size: 7,
            family: "Montserrat",
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    pointLabels: {
      color: "#8186AF",
    },
  };

  return <Bar data={data} options={options} />;
};

const ChartComponentForQuarterly = (props) => {
  const [value, setValue] = React.useState([0, 0, 0, 0]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue([1700, 500, 2300, 900]);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  function convertValueToText(value) {
    if (value === 0) {
      return 0;
    }
    if (value >= 1000) {
      const thousands = value / 1000;
      return "€" + thousands.toFixed(1) + "k";
    } else {
      return "€" + value;
    }
  }
  const data = {
    labels: ["Q1", "Q2", "Q3", "Q4"],
    datasets: [
      {
        type: "bar",
        label: "Bar",
        data: value,
        backgroundColor: "#FA544D",
        borderRadius: 2,
        order: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    categoryPercentage: 0.6,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "#43FCFF",
          font: {
            size: 8,
            family: "Montserrat",
          },
          callback: (value) => {
            return convertValueToText(value);
          },
        },
        grid: {
          color: "rgba(255, 255, 255, 0.02)",
          tickColor: "rgba(255, 255, 255, 0.02)",
        },
        border: {
          display: false,
        },
      },
      x: {
        beginAtZero: true,
        ticks: {
          color: "#43FCFF",
          font: {
            size: 8,
            family: "Montserrat",
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    pointLabels: {
      color: "#FA544D",
    },
    elements: {
      bar: {
        backgroundColor: "rgba(255, 99, 132, 0.6)", // Колір фону барів
      },
    },
    backgroundColor: "#E5E5E5",
  };

  return <Bar data={data} options={options} />;
};
