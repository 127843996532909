export const places = [
  {
    name: "Berlin",
    point: {
      lat: 52.520008,
      lng: 13.404954,
    },
  },
  {
    name: "Munich",
    point: {
      lat: 48.135124,
      lng: 11.581981,
    },
  },
  {
    name: "Hamburg",
    point: {
      lat: 53.551086,
      lng: 9.993682,
    },
  },
  {
    name: "Cologne",
    point: {
      lat: 50.937531,
      lng: 6.960279,
    },
  },
  {
    name: "Frankfurt",
    point: {
      lat: 50.110922,
      lng: 8.682127,
    },
  },
  {
    name: "Stuttgart",
    point: {
      lat: 48.775845,
      lng: 9.182932,
    },
  },
  {
    name: "Dusseldorf",
    point: {
      lat: 51.227741,
      lng: 6.773456,
    },
  },
  {
    name: "Dortmund",
    point: {
      lat: 51.513587,
      lng: 7.465298,
    },
  },
  {
    name: "Essen",
    point: {
      lat: 51.455643,
      lng: 7.011555,
    },
  },
  {
    name: "Leipzig",
    point: {
      lat: 51.339695,
      lng: 12.373075,
    },
  },
  {
    name: "Dresden",
    point: {
      lat: 51.050409,
      lng: 13.737262,
    },
  },
  {
    name: "Hanover",
    point: {
      lat: 52.375892,
      lng: 9.73201,
    },
  },
  {
    name: "Nuremberg",
    point: {
      lat: 49.452103,
      lng: 11.076665,
    },
  },
  {
    name: "Duisburg",
    point: {
      lat: 51.434407,
      lng: 6.762329,
    },
  },
  {
    name: "Bremen",
    point: {
      lat: 53.075876,
      lng: 8.807081,
    },
  },
  {
    name: "Görlitz",
    point: {
      lat: 51.028778,
      lng: 13.726981,
    },
  },
  {
    name: "Darmstadt",
    point: {
      lat: 49.872775,
      lng: 8.651177,
    },
  },
  {
    name: "Halle",
    point: {
      lat: 51.482979,
      lng: 11.969824,
    },
  },
  {
    name: "Kassel",
    point: {
      lat: 51.314668,
      lng: 9.496173,
    },
  },
  {
    name: "Lubeck",
    point: {
      lat: 53.865467,
      lng: 10.686559,
    },
  },
];

// const formattedData = places.map((item, index) => ({
//   name: item.name,
//   point: {
//     lat: places[index].lat,
//     lng: places[index].lng,
//   },
// }));

// console.log(formattedData);
