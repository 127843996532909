import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
//Carousel API https://github.com/leandrowd/react-responsive-carousel/tree/master

import "./dashboard.scss";
import { Box, Grid, Modal } from "@mui/material";
import { Element } from "react-scroll";

import { ReactComponent as Share } from "../../../../resources/icon/Dashboard/Share.svg";
import { ReactComponent as Pdf } from "../../../../resources/icon/Dashboard/Pdf.svg";
import { ReactComponent as Print } from "../../../../resources/icon/Dashboard/Print.svg";
import { ReactComponent as Weight } from "../../../../resources/icon/Dashboard/Weight.svg";
import { ReactComponent as New } from "../../../../resources/icon/Dashboard/New.svg";
import { ReactComponent as Fire } from "../../../../resources/icon/Dashboard/Fire.svg";
import { ReactComponent as Location } from "../../../../resources/icon/Dashboard/Location.svg";
import { ReactComponent as Distribution } from "../../../../resources/icon/Dashboard/Distribution.svg";
import { ReactComponent as Payment } from "../../../../resources/icon/Dashboard/Payment.svg";
import { ReactComponent as Issuer } from "../../../../resources/icon/Dashboard/Issuer.svg";
import { ReactComponent as LocationRed } from "../../../../resources/icon/Dashboard/LocationRed.svg";

import HoverRating from "../../../fewSimpleComponents/hoverRating/HoverRating";
import Slider from "../../../fewSimpleComponents/slider/Slider";
import Investment from "../../../popUps/investment/Investment";
import InvestmentConfirmation from "../../../popUps/InvestmentConfirmation/InvestmentConfirmation";
import CallBack from "../../../popUps/callBack/CallBack";

function Dashboard(props) {
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openNext, setOpenNext] = React.useState(false);
  const [openCallBack, setOpenCallBack] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenNext = () => setOpenNext(true);
  const handleCloseNext = () => setOpenNext(false);
  const handleOpenCallBack = () => setOpenCallBack(true);
  const handleCloseCallBack = () => setOpenCallBack(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await props;
      setData(fetchedData);
    };

    fetchData();
  }, [props]);

  const { title, pictures, procent, location } = data.itemId ? data.itemId : [];

  return (
    <Element className="box" name="Project details">
      <Grid container spacing={3} className="slader-item-arrows">
        <Grid item xs={12} sm={12} md={7}>
          <Carousel showArrows={true} showThumbs={false}>
            {pictures?.map(({ url }, i) => {
              return (
                <div key={i}>
                  <div className="slader-item">
                    <div
                      className="ImgFit"
                      style={{
                        backgroundImage: `url(${url})`,
                      }}
                    ></div>
                  </div>
                  {/* <p className="legend">Slide {i}</p> */}
                </div>
              );
            })}
          </Carousel>
          <Box className="button-under-carousel">
            <Share className="item-bt-under-carousel share" />
            <Pdf className="item-bt-under-carousel pdf" />
            <Print className="item-bt-under-carousel print" />
          </Box>
          <div className="content-under-carousel">
            <h2 className="name-under-slader">{title}</h2>
            <div className="bt-wrapper">
              <button className="button-instand">Instant interest</button>
              <button className="button-instand-info">
                <ButtonInfo />
              </button>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <div className="top-riting">
            <HoverRating />
            <div className="d-flex super-button">
              <button className="circle-bg cl-position ">
                <Fire />
              </button>
              <button className="circle-bg cl-position">
                <New />
              </button>
              <button className="circle-bg cl-position">
                <Weight />
              </button>
            </div>
          </div>

          <Slider />

          <div className="conditions">
            <div className="condition">
              <div className="conditions-sum">{procent?.return}% p.a.</div>

              <p className="conditions-name">Return</p>
            </div>
            <div className="condition">
              <div className="conditions-sum">{procent?.duration} Month</div>
              <div className="conditions-name">Duration</div>
            </div>
          </div>

          <Box className="details">
            <div className="details-row">
              <div className="details-name">
                <Location style={{ position: "relative", left: "-1px" }} />
                <div className="detail-name">Location</div>
              </div>
              <div className="map">
                <LocationRed style={{ position: "relative", left: "-5px" }} />
                <div className="detail">
                  <a href={location?.name} style={{ color: "#4E7CFF" }}>
                    {location?.name}
                  </a>
                </div>
              </div>
            </div>

            <div className="details-row">
              <div className="details-name">
                <Distribution style={{ position: "relative", left: "-2px" }} />
                <div
                  className="detail-name"
                  style={{ position: "relative", left: "-2px" }}
                >
                  Distribution
                </div>
              </div>
              <div className="detail">Yearly</div>
            </div>

            <div className="details-row">
              <div className="details-name">
                <Payment />
                <div className="detail-name">Payment method</div>
              </div>
              <div className="detail">Qualified Subordinated Loan</div>
            </div>

            <div className="details-row">
              <div className="details-name">
                <Issuer />
                <div className="detail-name">Issuer</div>
              </div>
              <div className="detail">Alberchtstrabe 335 Project GmbH</div>
            </div>
          </Box>

          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={6}>
              <button
                className="button-general bt-bottom-position"
                onClick={handleOpenCallBack}
              >
                Call back
              </button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <button
                className="button-general bt-bottom-position"
                onClick={handleOpen}
              >
                Invest now
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal className="custom-modal" open={open} onClose={handleClose}>
        <Investment handleClose={handleClose} handleOpenNext={handleOpenNext} />
      </Modal>
      <Modal className="custom-modal" open={openNext} onClose={handleCloseNext}>
        <InvestmentConfirmation handleCloseNext={handleCloseNext} />
      </Modal>
      <Modal
        className="custom-modal"
        open={openCallBack}
        onClose={handleCloseCallBack}
      >
        <CallBack handleCloseCallBack={handleCloseCallBack} />
      </Modal>
    </Element>
  );
}

export default Dashboard;

function ButtonInfo(props) {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.13715 10.819C5.09108 10.819 4.06851 10.5088 3.19873 9.92762C2.32896 9.34645 1.65106 8.52043 1.25075 7.55399C0.85044 6.58755 0.745695 5.52411 0.949772 4.49814C1.15385 3.47218 1.65758 2.52977 2.39726 1.79009C3.13694 1.05041 4.07935 0.546672 5.10531 0.342595C6.13128 0.138517 7.19472 0.243262 8.16116 0.643574C9.1276 1.04389 9.95363 1.72179 10.5348 2.59156C11.116 3.46133 11.4262 4.48391 11.4262 5.52997C11.4246 6.93222 10.8668 8.27656 9.87529 9.2681C8.88375 10.2596 7.53939 10.8174 6.13715 10.819ZM6.13715 1.34098C5.30864 1.34098 4.49874 1.58665 3.80986 2.04694C3.12099 2.50723 2.58407 3.16147 2.26702 3.92691C1.94996 4.69235 1.867 5.53462 2.02864 6.3472C2.19027 7.15979 2.58923 7.9062 3.17508 8.49204C3.76092 9.07788 4.50733 9.47684 5.31991 9.63848C6.1325 9.80011 6.97477 9.71716 7.74021 9.4001C8.50565 9.08305 9.15988 8.54614 9.62017 7.85726C10.0805 7.16838 10.3261 6.35848 10.3261 5.52997C10.3248 4.41939 9.88306 3.35466 9.09776 2.56936C8.31246 1.78405 7.24774 1.3423 6.13715 1.34098Z"
        fill="#FA544D"
      />
      <path
        d="M6.13698 4.74902C5.92958 4.74902 5.73067 4.8314 5.58401 4.97806C5.43736 5.12471 5.35498 5.32361 5.35498 5.53101V7.87601C5.36222 8.07844 5.44772 8.27015 5.59349 8.4108C5.73926 8.55144 5.93392 8.63007 6.13647 8.63007C6.33903 8.63007 6.53369 8.55144 6.67946 8.4108C6.82523 8.27015 6.91073 8.07844 6.91797 7.87601V5.53101C6.91797 5.32378 6.83572 5.12505 6.68929 4.97842C6.54285 4.8318 6.3442 4.74929 6.13698 4.74902Z"
        fill="#FA544D"
      />
      <path
        d="M6.13699 2.40405C5.98232 2.40405 5.83112 2.44993 5.70252 2.53586C5.57392 2.62179 5.4737 2.7439 5.41451 2.8868C5.35533 3.02969 5.33983 3.18693 5.37 3.33862C5.40018 3.49032 5.47466 3.62965 5.58402 3.73901C5.69339 3.84838 5.83272 3.92286 5.98441 3.95303C6.13611 3.98321 6.29335 3.96773 6.43624 3.90854C6.57913 3.84935 6.70127 3.74911 6.78719 3.62051C6.87312 3.49191 6.91899 3.34073 6.91899 3.18607C6.91899 2.97867 6.83659 2.77976 6.68994 2.6331C6.54328 2.48645 6.34439 2.40405 6.13699 2.40405Z"
        fill="#FA544D"
      />
    </svg>
  );
}
