import { dataLinks } from "../dataSitebar/DataLinks";
import { useState } from "react";

import { Link, useLocation } from "react-router-dom";

import "./sidebar.scss";

function Sidebar() {
  const [hoveredButtons, setHoveredButtons] = useState([]);

  const { pathname } = useLocation();

  const activeClass = (value) => {
    return pathname === value.link ? "active" : "";
  };

  const handleMouseEnter = (index) => {
    setHoveredButtons((prevHoveredButtons) => {
      const updatedHoveredButtons = [...prevHoveredButtons];
      updatedHoveredButtons[index] = true;
      return updatedHoveredButtons;
    });
  };

  const handleMouseLeave = (index) => {
    setHoveredButtons((prevHoveredButtons) => {
      const updatedHoveredButtons = [...prevHoveredButtons];
      updatedHoveredButtons[index] = false;
      return updatedHoveredButtons;
    });
  };

  return (
    <ul className="sidebar-list">
      <p className="user-panel">USER PANEL</p>
      {dataLinks.slice(0, 6).map((value, key) => {
        return (
          <Link
            to={value.link}
            onMouseEnter={() => handleMouseEnter(key)}
            onMouseLeave={() => handleMouseLeave(key)}
            key={key}
            className="sidebar-row"
            id={activeClass(value)}
          >
            <div id="icon">
              {pathname === value.link || hoveredButtons[key]
                ? value.iconWhite
                : value.icon}
            </div>{" "}
            <div id="title">{value.title}</div>
          </Link>
        );
      })}
      <p className="user-panel">HOMEPAGE</p>
      {dataLinks.slice(6, 8).map((value, key) => {
        const k = key + 8;
        return (
          <li
            onMouseEnter={() => handleMouseEnter(k)}
            onMouseLeave={() => handleMouseLeave(k)}
            key={k}
            className="sidebar-row"
            id={activeClass(value)}
          >
            <div id="icon">
              {window.location.pathname === value.link || hoveredButtons[k]
                ? value.iconWhite
                : value.icon}
            </div>{" "}
            <div id="title">{value.title}</div>
          </li>
        );
      })}

      <p className="user-panel">SUPPORT</p>

      {dataLinks.slice(8, 10).map((value, key) => {
        const k = key + 12;
        return (
          <li
            onMouseEnter={() => handleMouseEnter(k)}
            onMouseLeave={() => handleMouseLeave(k)}
            key={k}
            className="sidebar-row"
            id={activeClass(value)}
          >
            <div id="icon">
              {window.location.pathname === value.link || hoveredButtons[k]
                ? value.iconWhite
                : value.icon}
            </div>{" "}
            <div id="title">{value.title}</div>
          </li>
        );
      })}
    </ul>
  );
}

export default Sidebar;
