import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import { Element } from "react-scroll";

import "./calculator.scss";

import { ReactComponent as LineForSlider } from "../../../../resources/icon/Calcilator/LineForSlider.svg";
import { ReactComponent as LoanAmount } from "../../../../resources/icon/Calcilator/LoanAmount.svg";
import { ReactComponent as Return } from "../../../../resources/icon/Calcilator/Return.svg";
import { ReactComponent as Interest } from "../../../../resources/icon/Calcilator/Interest.svg";
import { ReactComponent as Duration } from "../../../../resources/icon/Calcilator/Duration.svg";
import { ReactComponent as DlendoInstantInterest } from "../../../../resources/icon/Calcilator/DlendoInstantInterest.svg";
import { ReactComponent as LineVertical } from "../../../../resources/icon/Calcilator/LineVertical.svg";
import { ReactComponent as CheckBox } from "../../../../resources/icon/Calcilator/CheckBox.svg";

function Calculator(props) {
  const [value, setValue] = useState(75);
  const [valueDlendoInstantInterest, setValueDlendoInstantInterest] =
    useState("6.000");

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const calculatorFanction = (event) => {
    event.preventDefault();

    let percent = 8;
    let result = ((value / 100) * percent).toFixed(3);
    return setValueDlendoInstantInterest(result);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{ display: "flex", alignItems: "stretch" }}
      >
        <Grid item xs={12} sm={12} md={7}>
          <Element name="Calculator">
            <h2 className="h2-title">Calculator</h2>
            <div className="box">
              <div className="amount-slider">
                <div className="amount-fixed">0%</div>
                <div className="amount-center">€{value}.000</div>
                <div className="amount-fixed">€100.000</div>
              </div>

              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Slider
                      className="slider-counter"
                      value={typeof value === "number" ? value : 0}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                    />
                    <LineForSlider />
                  </Grid>
                </Grid>

                <form
                  defaultValue
                  onSubmit={calculatorFanction}
                  className="input-form"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <label htmlFor="Loan-amount" className="input-name">
                        Loan amount
                      </label>
                      <div className="input-label">
                        <div className="input-icon">
                          <LoanAmount />
                        </div>
                        <input
                          type="text"
                          value="8% p.a."
                          id="Loan-amount"
                          className="input-calculator"
                          placeholder="8% p.a."
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="input-name">Return</label>
                      <div className="input-label">
                        <div className="input-icon">
                          <Return />
                        </div>
                        <input
                          type="text"
                          value="8% p.a."
                          className="input-calculator"
                          placeholder="8% p.a."
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="input-name">Interest</label>
                      <div className="input-label">
                        <div className="input-icon">
                          <Interest />
                        </div>
                        <input
                          type="text"
                          value="8% p.a."
                          className="input-calculator"
                          placeholder="8% p.a."
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="input-name">Duration</label>
                      <div className="input-label">
                        <div className="input-icon">
                          <Duration />
                        </div>
                        <input
                          type="text"
                          value="6 months"
                          className="input-calculator"
                          placeholder="6 months"
                          readOnly
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <label className="input-name">
                        Dlendo instant interest
                      </label>
                      <label className="input-label">
                        <div className="input-icon">
                          <DlendoInstantInterest />
                        </div>

                        <div className="input-calculator">
                          {valueDlendoInstantInterest}
                        </div>
                      </label>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <button
                        type="submit"
                        className="button-general"
                        style={{
                          width: "-webkit-fill-available",
                          marginTop: "23px",
                          flexGrow: "1",
                        }}
                      >
                        Calculate
                      </button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </div>
          </Element>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
        >
          <Element name="Project milestones">
            <h2 className="h2-title">Project milestones</h2>
            <div className="box padding-box">
              <div className="project-milestones">
                <div className="project-milestones-number circle-bg">1</div>
                <div className="project-milestones-info">
                  <div className="project-milestones-check">
                    <CheckBox />
                  </div>
                  <div className="project-milestones-text max">
                    Property purchased
                  </div>
                </div>
              </div>

              <div className="project-milestones-line">
                <LineVertical />
              </div>

              <div className="project-milestones">
                <div className="project-milestones-number circle-bg">2</div>
                <div className="project-milestones-info">
                  <div className="project-milestones-check">
                    <CheckBox />
                  </div>
                  <div className="project-milestones-text">
                    Start of construction
                  </div>
                </div>
              </div>

              <div className="project-milestones-line">
                <LineVertical />
              </div>

              <div className="project-milestones">
                <div className="project-milestones-number circle-bg">3</div>
                <div className="project-milestones-info">
                  <div className="project-milestones-check">
                    <CheckBox />
                  </div>
                  <div className="project-milestones-text max">
                    Building permit
                  </div>
                </div>
              </div>

              <div className="project-milestones-line">
                <LineVertical />
              </div>

              <div className="project-milestones-disabled">
                <div className="project-milestones-number-disabled circle-bg">
                  4
                </div>
                <div className="project-milestones-info">
                  <div className="project-milestones-check">
                    <CheckBox className="-disabled" />
                  </div>
                  <div className="project-milestones-text-disabled">
                    Start sales
                  </div>
                </div>
              </div>
            </div>
          </Element>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Calculator;
