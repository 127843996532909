import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { companyNewData } from "../../../data/companyNewe/companyNewData";
import "./companyNewsItem.scss";
import { InfoNewsForMenu } from "../companyNews/CompanyNews";
import { ReactComponent as CompanyNewIcon } from "../../../resources/icon/CompanyNews/CompanyNews.svg";
import { ReactComponent as Views } from "../../../resources/icon/CompanyNews/views.svg";
import SkeletonCompanyNewsItem from "./SkeletonCompanyNewsItem";
import { SkInfoNews } from "../companyNews/SkeletonCompanyNews";
import { ReactComponent as SkeletonImg } from "../../../resources/icon/CompanyNews/SkeletonImg.svg";
import { ButtonExit } from "../../sidebar/SidebarWrapper";
import { ReactComponent as ButtonList } from "../../../resources/elements/ButtonList.svg";

function CompanyNewsItem(props) {
  const itemById = useSelector(
    (state) => state.itemProjectSlice.filteredCompanyNewById[0]
  );

  const [visibleCards, setVisibleCards] = React.useState(4);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingLastCompanyNews, setIsLoadingLastCompanyNews] =
    React.useState(false);

  const cardsContainerRef = React.useRef(null);

  const handleLoadMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 2);
    setIsLoadingLastCompanyNews(true);
  };

  React.useEffect(() => {
    if (cardsContainerRef.current) {
      const lastCard = cardsContainerRef.current.lastChild;
      if (lastCard) {
        lastCard.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [visibleCards]);

  React.useEffect(() => {
    if (itemById) {
      localStorage.setItem("newItemById", JSON.stringify(itemById));
    }
  }, [itemById]);

  const dataId = itemById || JSON.parse(localStorage.getItem("newItemById"));

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  React.useEffect(() => {
    setIsLoadingLastCompanyNews(true);
    setTimeout(() => {
      setIsLoadingLastCompanyNews(false);
    }, 2500);
  }, [visibleCards]);

  const propsAll = {
    visibleCards,
    handleLoadMore,
    cardsContainerRef,
    isLoadingLastCompanyNews,
  };

  return (
    <>
      {isLoading ? (
        <SkeletonCompanyNewsItem />
      ) : (
        <ViewComponent {...dataId} {...propsAll} />
      )}
    </>
  );
}

export default CompanyNewsItem;

function ViewComponent(props) {
  const [isOpen, setIsOpen] = React.useState(false);

  const {
    visibleCards,
    handleLoadMore,
    cardsContainerRef,
    isLoadingLastCompanyNews,
  } = props;
  const { pictures } = props;
  return (
    <div className="comp-news">
      <div className="comp-news__main-content">
        <div className="comp-news__box-title">
          <div className="comp-news__icon">
            <CompanyNewIcon />
          </div>
          <h2 className="comp-news__title">Company news</h2>
        </div>
        <div className="main-news-item">
          <div className="box-p main-news__item company-news-item__img">
            <img src={pictures} alt="photos" />
            <div className="main-news__info-wrapper">
              <InfoNews {...props} />
            </div>
          </div>
        </div>
        <Link to="/company-news" className="comp-news__btn">
          <button className="button-general">Back to company news page</button>
        </Link>
        <div className="main-news__b-menu">
          <button
            className="circle-bg main-news__but-b-menu main-news__style-center-btn"
            onClick={() => setIsOpen(!isOpen)}
          >
            Last company news
          </button>

          <button
            className="toggle-button main-news__but-b-menu "
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <ButtonExit /> : <ButtonList />}
          </button>
        </div>
      </div>

      <div className={`comp-news__left-panel ${isOpen ? "close-menu" : ""}`}>
        <div className="box-p project-news">
          <div className="comp-news__box-title">
            <div className="comp-news__icon">
              <CompanyNewIcon />
            </div>
            <h2 className="comp-news__title">Last company news</h2>
          </div>
        </div>

        <div
          className="project-news__container project-news__menu"
          ref={cardsContainerRef}
        >
          {companyNewData.slice(0, visibleCards).map((data, i) => {
            return (
              <div key={i} className="card-news__item project-news__item">
                <div className="project-news__wrapper">
                  {isLoadingLastCompanyNews &&
                  i >= visibleCards - 2 &&
                  i < visibleCards ? (
                    <>
                      <div className="skeleton-project-news__img">
                        <SkeletonImg />
                      </div>
                      <SkInfoNews />
                    </>
                  ) : (
                    <>
                      <img src={data.pictures} alt="photos" />
                      <InfoNewsForMenu title={data.title} />
                    </>
                  )}
                </div>
                <div className="info-news__divider"></div>
              </div>
            );
          })}
          {visibleCards < companyNewData.length - 2 && (
            <div
              className="comp-news__btn"
              style={{ marginTop: "22px" }}
              onClick={handleLoadMore}
            >
              <button className="button-general">More news</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function InfoNews(props) {
  const { title, description } = props;
  return (
    <div className="info-news">
      <div className="info-news__wrapper">
        <div className="info-news__views">
          <div className="info-news__data">08/11/2020</div>
          <div className="info-news__divider-y"></div>
          <div className="info-news__view">
            <div className="info-news__icon">
              <Views />
            </div>
            <div className="info-news__amount">635</div>
          </div>
        </div>
        <div className="info-news__hashtag">#hashtag</div>
      </div>
      <div className="info-news__divider"></div>
      <div className="info-news__description" style={{ fontSize: "22px" }}>
        {title}
      </div>
      <div className="info-news__full-description">
        {description} Atque ratione quaerat perspiciatis asperiores ab numquam
        velit et accusantium repudiandae consequuntur recusandae maxime, commodi
        impedit natus voluptatibus veritatis quos. Neque impedit exercitationem
        aliquam natus? Ea quo aliquid perferendis facilis?
      </div>
    </div>
  );
}
