const countryChartMap = [
  {
    value: "au",
    affiliates: 981,
    procentAffiliates: 19,
    label: "Australia",
  },
  {
    value: "br",
    affiliates: 1794,
    procentAffiliates: 36,
    label: "Brazil",
  },
  {
    value: "ca",
    affiliates: 1198,
    procentAffiliates: 24,
    label: "Canada",
  },
  {
    value: "de",
    affiliates: 483,
    procentAffiliates: 9,
    label: "Germany",
  },
  {
    value: "tr",
    affiliates: 245,
    procentAffiliates: 5,
    label: "Turkey",
  },
  {
    value: "es",
    affiliates: 86,
    procentAffiliates: 2,
    label: "Spain",
  },
  {
    value: "ua",
    affiliates: 173,
    procentAffiliates: 4,
    label: "Ukraine",
  },
];

export { countryChartMap };
