import { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Container from "@mui/material/Container";
import "./layout.scss";

import SidebarWrapper from "../../sidebar/SidebarWrapper";

const stylesContainer = {
  maxWidth: "1435px",
  padding: "0",
  display: "flex",
};

function Layout(props) {
  const [overlayContent, setOverlayContent] = useState(false);
  const [isSpecialPage, setIsSpecialPage] = useState(false);
  const { pathname } = useLocation();

  const toggleOverlay = (value) => setOverlayContent(value);

  useEffect(() => {
    setIsSpecialPage(pathname.includes("/company-news"));
  }, [pathname]);

  return (
    <Container maxWidth="sm" style={stylesContainer}>
      <SidebarWrapper toggleOverlay={toggleOverlay} />

      <div className={isSpecialPage ? "content-special" : "content"}>
        <Outlet />
        {overlayContent && <div className="overlay"></div>}
      </div>
    </Container>
  );
}

export { Layout };
