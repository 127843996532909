const countryChartMap = [
  { value: "au", affiliates: 1, procentAffiliates: 1, label: "Australia" },
  { value: "br", affiliates: 1, procentAffiliates: 1, label: "Brazil" },
  { value: "ca", affiliates: 1, procentAffiliates: 1, label: "Canada" },
  { value: "de", affiliates: 1, procentAffiliates: 1, label: "Germany" },
  { value: "us", affiliates: 1, procentAffiliates: 1, label: "United States" },
  { value: "es", affiliates: 1, procentAffiliates: 1, label: "Spain" },
  { value: "ua", affiliates: 1, procentAffiliates: 1, label: "Ukraine" },
];

const country = [
  { value: "au", label: "Australia" },
  { value: "bg", label: "Bulgaria" },
  { value: "br", label: "Brazil" },
  { value: "ca", label: "Canada" },
  { value: "cn", label: "China" },
  { value: "de", label: "Germany" },
  { value: "fr", label: "France" },
  { value: "in", label: "India" },
  { value: "ru", label: "Russia" },
  { value: "us", label: "United States" },
  { value: "tr", label: "Turkey" },
  { value: "es", label: "Spain" },
  { value: "ua", label: "Ukraine" },
  { value: "uy", label: "Uruguay" },
];

const dataPlaces = [
  { label: "Canberra" },
  { label: "Sofia" },
  { label: "Brasília" },
  { label: "Ottawa" },
  { label: "Beijing" },
  { label: "Berlin" },
  { label: "Paris" },
  { label: "New Delhi" },
  { label: "Moscow" },
  { label: "Washington, D.C." },
  { label: "Ankara" },
  { label: "Madrid" },
  { label: "Kyiv" },
  { label: "Montevideo" },
];

const Language = [
  { value: "au", label: "AU" },
  { value: "bg", label: "BG" },
  { value: "br", label: "BR" },
  { value: "ca", label: "CA" },
  { value: "cn", label: "CN" },
  { value: "de", label: "DE" },
  { value: "fr", label: "FR" },
  { value: "in", label: "IN" },
  { value: "ru", label: "RU" },
  { value: "us", label: "US" },
  { value: "tr", label: "TR" },
  { value: "es", label: "ES" },
  { value: "ua", label: "UA" },
  { value: "uy", label: "UY" },
];

export { country, dataPlaces, Language, countryChartMap };
