import { createSlice } from "@reduxjs/toolkit";

import data from "../../data/data";

const initialState = {
  data: data,
  sortByAllDataPayload: "",
  assetClassPayload: "",
  statusPayload: "",
  durationPayload: "",
  returnDataPayload: "",
};

export const filterCardsSlice = createSlice({
  name: "filterCards",
  initialState,
  reducers: {
    filteredByAssetClass: (state, action) => {
      state.assetClassPayload = action.payload;
    },
    filteredByStatus: (state, action) => {
      state.statusPayload = action.payload;
    },
    filteredByDuration: (state, action) => {
      state.durationPayload = action.payload;
    },
    filteredByReturnData: (state, action) => {
      state.returnDataPayload = action.payload;
    },
    filteredSortByAllData: (state, action) => {
      state.sortByAllDataPayload = action.payload;
    },
  },
});

export const {
  filteredByAssetClass,
  filteredByStatus,
  filteredByDuration,
  filteredByReturnData,
  filteredSortByAllData,
} = filterCardsSlice.actions;

export default filterCardsSlice.reducer;
