import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Grid, Modal } from "@mui/material";
import PersonalData from "../../settingsProfile/personalData/PersonalData";
import Security from "../../settingsProfile/security/Security";
import BillingInfo from "../../settingsProfile/billingInfo/BillingInfo";
import { ReactComponent as Logout } from "../../../resources/icon/SettingProfile/Logout.svg";

import "./settingsPage.scss";
import PopUpInfo from "../../popUps/popUpInfo/PopUpInfo";

const info = "Congratulations! Your new settings have taken effect.";
const title = "New settings has been saved";

const validationSchema = Yup.object().shape({
  telephone: Yup.string().matches(
    /^\+[0-9]{8,12}$/,
    "Invalid telephone number format, example +00000000"
  ),
  email: Yup.string().email("Invalid email format"),
  dateOfBirth: Yup.string().matches(
    /^\d{2}-\d{2}-\d{4}$/,
    "Invalid date format, example DD-MM-YYYY"
  ),
  address: Yup.string()
    .min(5, "Invalid: Address must be at least 5 characters long")
    .max(50, "Invalid: Address can be up to 50 characters"),
  postalCode: Yup.string()
    .nullable()
    .matches(/^\d{5}$/, "Invalid: Post code should be a 5-digit number"),
  facebook: Yup.string().matches(
    /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:[a-zA-Z0-9_.]+\/)?(?:[a-zA-Z0-9_.]+)/,
    "Invalid Facebook URL"
  ),
  twitter: Yup.string().matches(
    /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/(?:[a-zA-Z0-9_]+\/)?(?:[a-zA-Z0-9_]+)/,
    "Invalid Twitter URL"
  ),
  instagram: Yup.string().matches(
    /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:[a-zA-Z0-9_]+\/)?(?:[a-zA-Z0-9_]+)/,
    "Invalid Instagram URL"
  ),
  password: Yup.string().min(
    6,
    "Invalid: Password must be at least 6 characters long"
  ),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Invalid: Passwords must match"
  ),
  bankName: Yup.string()
    .nullable()
    .matches(/^\d{5}$/, "Invalid bank name format, should be a 5-digit number"),
  bankAddress: Yup.string()
    .nullable()
    .matches(
      /^\d{5}$/,
      "Invalid bank address format, should be a 5-digit number"
    ),
  iban: Yup.string()
    .nullable()
    .matches(/^\d{5}$/, "Invalid IBAN format, should be a 5-digit number"),
  bic: Yup.string()
    .nullable()
    .matches(/^\d{5}$/, "Invalid BIC format, should be a 5-digit number"),
  bitcoinBic: Yup.string()
    .nullable()
    .matches(
      /^\d{5}$/,
      "Invalid Bitcoin (BTC) format, should be a 5-digit number"
    ),
  etheriumEth: Yup.string()
    .nullable()
    .matches(
      /^\d{5}$/,
      "Invalid Etherium (ETH) format, should be a 5-digit number"
    ),
});

function SettingsPage(props) {
  const [openModal, setOpenModal] = React.useState(false);

  const handleClose = () => setOpenModal(false);

  const initialValues = {
    telephone: "",
    email: "",
    dateOfBirth: "",
    address: "",
    postalCode: "",
    facebook: "facebook.com/laurens",
    twitter: "twitter.com/l.berst",
    instagram: "instagram.com/lauber",
    password: "",
    confirmPassword: "",
    oldPassword: "",
    bankName: "",
    bankAddress: "",
    iban: "",
    bic: "",
    bitcoinBic: "",
    etheriumEth: "",
  };

  const handleSubmit = (values) => {
    console.log(values);
    setOpenModal(true);
  };

  return (
    <div>
      <h2 className="h2-title" style={{ marginTop: "10px" }}>
        Settings
      </h2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form>
            <Grid container spacing={3}>
              <PersonalData isValid={isValid} />
              <Security />
              <BillingInfo />
            </Grid>

            <div className="settings-page">
              <div className="settings-page__wrapper">
                <button className="button-general settings-page__logout d-flex">
                  <Logout />
                  <span>Logout</span>
                </button>

                <button className="button-general" type="submit">
                  Save new settings
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Modal className="custom-modal" open={openModal} onClose={handleClose}>
        <PopUpInfo handleClose={handleClose} title={title} info={info} />
      </Modal>
    </div>
  );
}

export default SettingsPage;
