import React from "react";
import Grid from "@mui/material/Grid";
import { Element } from "react-scroll";

import MapComponent from "../../../map/MapComponent";
import ArrowPrimary from "../../../../resources/icon/ArrowPtimary";

function MapLocation(props) {
  return (
    <Element id="Location">
      <h2 className="h2-title">Location</h2>
      <div className="box">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <MapComponent data={[props.itemId]} />
            <p className="style-text">
              The 22nd district, also called 'Donaustadt', is located east of
              the city centre of Vienna and is Vienna’s most populous district.
              The 22nd District covers almost a quarter of the total area of the
              Vienna municipal territory and is characterized by its
              comprehensive appearance. In addition to numerous green spaces and
              local recreation areas, which make up almost 50% of the total area
              of the 22nd District, there are numerous large housing estates and
              detached houses, such as the Donau City district, which is
              characterised by office and high-rise
            </p>
            <div className="deal-highlights">
              <div className="deal-highlights__title-wrapper">
                <ArrowPrimary />
                <h3 className="deal-highlights__title">Seestadt Aspern</h3>
              </div>

              <p className="deal-highlights__description">
                The Urban Lakeside Aspern in the 22nd district of Vienna is one
                of the largest urban development projects in Europe. Over the
                next few years, the district will be home to over 20,000 people
                who will live and work here. In the middle of the Urban Lakeside
                is the artificial lake that gives the district its name and
                serves as a recreational area for
              </p>
            </div>
            <div className="deal-highlights">
              <div className="deal-highlights__title-wrapper">
                <ArrowPrimary />
                <h3 className="deal-highlights__title">Donaupark</h3>
              </div>

              <p className="deal-highlights__description">
                The Donaupark is a park of about 604,000 m² in the 22nd district
                of Vienna Donaustadt. The Donaupark is conveniently located
                between Wagramer Strasse, the Bruckhaufen housing estate,
                Arbeiterstrandbadstrasse and Hubertusdamm.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </Element>
  );
}

export default MapLocation;
