import React from "react";
import { Grid } from "@mui/material";
import { Bar } from "react-chartjs-2";

import "./referralCommissions.scss";
import { ReactComponent as ReferralCommissionsIcon } from "../../../resources/icon/Table/ReferralCommissions.svg";

function ReferralCommissions(props) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <div className="boxs-chart">
        <div className="boxs-chart__title">
          <h4>Referral commissions</h4>
        </div>
        <div className="boxs-chart__container">
          <div className="investor-chart">
            <ChartComponentForAnnual
              timeoutForSkeleton={props.timeoutForSkeleton}
            />
          </div>
          <div className="panel-info__wrapper">
            <div className="panel-info">
              <div className="panel-info__icon">
                <ReferralCommissionsIcon />
              </div>
              <div className="panel-info__title">
                <div>Today</div>
                <div>€ {props.timeoutForSkeletonValue ? "342.00" : "0.00"}</div>
              </div>
            </div>

            <div className="panel-info">
              <div className="panel-info__icon">
                <ReferralCommissionsIcon />
              </div>
              <div className="panel-info__title">
                <div>This week</div>
                <div>
                  € {props.timeoutForSkeletonValue ? "3987.00" : "0.00"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default ReferralCommissions;

const ChartComponentForAnnual = (props) => {
  const [value, setValue] = React.useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue([357, 547, 472, 555, 555, 470, 615, 407, 555, 445, 488, 442]);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  function convertValueToText(value) {
    return "€" + value.toFixed(0);
  }
  const data = {
    labels: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
    datasets: [
      {
        type: "line",
        label: "Line",
        data: value,
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(67, 252, 255, 0)",
            "rgba(67, 252, 255, 0.102)",
          ];
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[0]);
          return gradientBg;
        },
        borderColor: "#43FCFF",
        borderWidth: 1,
        pointBackgroundColor: "#FA544D",
        pointBorderColor: "#fff",
        tension: 0.3,
        order: 1,
        radius: 3,
        fill: true,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    categoryPercentage: 0.4,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "#43FCFF",
          font: {
            size: 8,
            family: "Montserrat",
          },
          callback: (value) => {
            return convertValueToText(value);
          },
        },
        grid: {
          color: "rgba(255, 255, 255, 0.02)",
          borderColor: "rgba(255, 255, 255, 0.02)",
          tickColor: "rgba(255, 255, 255, 0.02)",
        },
        border: {
          display: false,
        },
      },
      x: {
        beginAtZero: true,
        ticks: {
          color: "#43FCFF",
          font: {
            size: 7,
            family: "Montserrat",
            type: "line",
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    pointLabels: {
      color: "#8186AF",
    },
  };

  return <Bar data={data} options={options} />;
};
