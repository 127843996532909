import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import "./inputSelectById.scss";

const InputSelectById = ({ dataButton, action, placeHolder, active }) => {
  const dispatch = useDispatch();

  const [type, setType] = useState(placeHolder[0]);
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setType(event.target.value);
    dispatch(action(event.target.value));
  };

  useEffect(() => {
    if (active === placeHolder[0]) {
      setType(placeHolder[0]);
    }
    // eslint-disable-next-line
  }, [active]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hiddenSelect = { display: "none" };

  return (
    <Box sx={{ minWidth: 160 }}>
      <FormControl focused={false} fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={handleChange}
        >
          <MenuItem style={!open ? hiddenSelect : null} value={placeHolder[0]}>
            {placeHolder[0]}
          </MenuItem>
          {dataButton?.map((item, i) => {
            return (
              <MenuItem key={i} value={item}>
                {item.charAt(0).toUpperCase() + item.slice(1)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default InputSelectById;
