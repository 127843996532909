import React from "react";
import { Field, useFormikContext } from "formik";

import { Grid } from "@mui/material";

import "./billingInfo.scss";

import { ReactComponent as FocusedInput } from "../../../resources/icon/SettingProfile/FocusedInput.svg";
import { ReactComponent as BankAddress } from "../../../resources/icon/SettingProfile/BankAddress.svg";
import { ReactComponent as BankName } from "../../../resources/icon/SettingProfile/BankName.svg";
import { ReactComponent as CheckingBic } from "../../../resources/icon/SettingProfile/CheckingBic.svg";
import { ReactComponent as Bitcoin } from "../../../resources/icon/SettingProfile/Bitcoin.svg";
import { ReactComponent as Etherium } from "../../../resources/icon/SettingProfile/Etherium.svg";

const filedProps = [
  "bankAddress",
  "bankName",
  "iban",
  "bic",
  "bitcoinBic",
  "etheriumEth",
];

function BillingInfo(props) {
  const inputRefs = React.useRef([null]);
  const { errors, touched, getFieldProps } = useFormikContext();
  const showError =
    filedProps.some((field) => field in errors) &&
    Object.keys(errors).length !== 0
      ? true
      : false;

  const handleIconClick = (index) => {
    inputRefs.current[index].focus();
  };

  const styleValidInput = (errors, touched, getFieldProp) => {
    if (errors?.includes("Invalid") && touched) {
      return "іnvalid";
    } else if (
      errors === undefined &&
      touched &&
      getFieldProps(getFieldProp).value !== ""
    ) {
      return "valid";
    }
  };

  const creatElement = (title) => {
    let creatElementes = React.createElement(title);
    return creatElementes;
  };

  const dataInputLeftPart = [
    {
      title: "Bank name",
      errors: errors.bankName,
      touched: touched.bankName,
      getFieldProp: "bankName",
      icon: BankName,
    },
    {
      title: "Bank Address",
      errors: errors.bankAddress,
      touched: touched.bankAddress,
      getFieldProp: "bankAddress",
      icon: BankAddress,
    },
    {
      title: "IBAN",
      errors: errors.iban,
      touched: touched.iban,
      getFieldProp: "iban",
      icon: CheckingBic,
    },
    {
      title: "BIC",
      errors: errors.bic,
      touched: touched.bic,
      getFieldProp: "bic",
      icon: CheckingBic,
    },
    {
      title: "Bitcoin (BTC)",
      errors: errors.bitcoinBic,
      touched: touched.bitcoinBic,
      getFieldProp: "bitcoinBic",
      icon: Bitcoin,
    },
    {
      title: "Etherium (ETH)",
      errors: errors.etheriumEth,
      touched: touched.etheriumEth,
      getFieldProp: "etheriumEth",
      icon: Etherium,
    },
  ];

  return (
    <Grid item xs={12}>
      <div className="box">
        <div className="box-title">Billing info</div>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <div className="billing-info-title d-flex">
              <div className="billing-info-title__divider"></div>
              <div className="billing-info-title__title">Fiat information</div>
              <div className="billing-info-title__divider"></div>
            </div>

            <Grid container spacing={2} mt={0}>
              {dataInputLeftPart
                .slice(0, 4)
                .map(({ title, errors, touched, getFieldProp, icon }, i) => {
                  return (
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="data-input">
                        <div
                          className={`data-input__wrapper ${styleValidInput(
                            errors,
                            touched,
                            getFieldProp
                          )}`}
                        >
                          <div className="data-input__left-icon">
                            {creatElement(icon)}
                          </div>
                          <div className="data-input__input-general">
                            <Field
                              type="text"
                              name={getFieldProp}
                              innerRef={(el) => (inputRefs.current[i] = el)}
                            />
                            <label>{title}</label>
                          </div>
                          <div
                            className="data-input__icon-right"
                            onClick={() => handleIconClick(i)}
                          >
                            <FocusedInput />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <div className="billing-info-title d-flex">
              <div className="billing-info-title__divider"></div>
              <div className="billing-info-title__title">
                Crypto information
              </div>
              <div className="billing-info-title__divider"></div>
            </div>

            <Grid container spacing={2} mt={0}>
              {dataInputLeftPart
                .slice(4)
                .map(({ title, errors, touched, getFieldProp, icon }, i) => {
                  return (
                    <Grid item xs={12}>
                      <div className="data-input">
                        <div
                          className={`data-input__wrapper ${styleValidInput(
                            errors,
                            touched,
                            getFieldProp
                          )}`}
                        >
                          <div className="data-input__left-icon">
                            {creatElement(icon)}
                          </div>
                          <div className="data-input__input-general">
                            <Field
                              type="text"
                              name={getFieldProp}
                              innerRef={(el) => (inputRefs.current[i] = el)}
                            />
                            <label>{title}</label>
                          </div>
                          <div
                            className="data-input__icon-right"
                            onClick={() => handleIconClick(i)}
                          >
                            <FocusedInput />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>

          {showError && (
            <Grid item xs={12} mt={-3}>
              <div className="error-description" style={{ marginTop: "25px" }}>
                {Object.entries(errors)
                  .filter(([fieldName]) => filedProps.includes(fieldName))
                  .map(([fieldName, errorMessage], i) => (
                    <ul key={i} className="error-description__error-list">
                      <li className="error-description__error-item">
                        {errorMessage}
                      </li>
                    </ul>
                  ))}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </Grid>
  );
}

export default BillingInfo;
