import CompanyNews from "../../../resources/icon/Sidebar/CompanyNews";
import Dashboard from "../../../resources/icon/Sidebar/Dashboard";
import Dlendo from "../../../resources/icon/Sidebar/Dlendo";
import DlendoPortfoli from "../../../resources/icon/Sidebar/DlendoPortfoli";
import FAQ from "../../../resources/icon/Sidebar/FAQ";
import Facebook from "../../../resources/icon/Sidebar/Facebook";
import MyPortfolio from "../../../resources/icon/Sidebar/MyPortfolio";
import MyWallet from "../../../resources/icon/Sidebar/MyWallet";
import Partnership from "../../../resources/icon/Sidebar/Partnership";
import Support from "../../../resources/icon/Sidebar/Support";

const color = "icon";

export const dataLinks = [
  {
    title: "Dashboard",
    icon: <Dashboard />,
    iconWhite: <Dashboard color={color} />,
    link: "/",
  },
  {
    title: "Dlendo portfolio",
    icon: <DlendoPortfoli />,
    iconWhite: <DlendoPortfoli color={color} />,
    link: "/dlendo-portfolio",
  },
  {
    title: "My portfolio",
    icon: <MyPortfolio />,
    iconWhite: <MyPortfolio color={color} />,
    link: "/my-portfolio",
  },
  {
    title: "My wallet",
    icon: <MyWallet />,
    iconWhite: <MyWallet color={color} />,
    link: "/my-wallet",
  },
  {
    title: "Partnership",
    icon: <Partnership />,
    iconWhite: <Partnership color={color} />,
    link: "/partnership",
  },
  {
    title: "Company news",
    icon: <CompanyNews />,
    iconWhite: <CompanyNews color={color} />,
    link: "/company-news",
  },
  {
    title: "Dlendo",
    icon: <Dlendo />,
    iconWhite: <Dlendo color={color} />,
    link: "/dlendo",
  },
  {
    title: "FAQ",
    icon: <FAQ />,
    iconWhite: <FAQ color={color} />,
    link: "/faq",
  },
  {
    title: "Support@dlendo.com",
    icon: <Support />,
    iconWhite: <Support color={color} />,
    link: "/support@dlendo.com",
  },
  {
    title: "Facebook",
    icon: <Facebook />,
    iconWhite: <Facebook color={color} />,
    link: "/facebook",
  },
];
