import React from "react";
import { Grid } from "@mui/material";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";

import "./globalInvolvement.scss";
import { countryChartMap } from "./dataSelectMap";
import { ReactComponent as Path } from "../../../resources/icon/SettingProfile/Path.svg";

function GlobalInvolvement(props) {
  const [selectedCountry, setSelectedCountry] = React.useState({
    value: "de",
    affiliates: 483,
    procentAffiliates: 9,
    label: "Germany",
  });

  React.useEffect(() => {
    const handleDocumentWheel = (event) => {
      if (
        mapContainerRef.current &&
        mapContainerRef.current.contains(event.target)
      ) {
        event.preventDefault();
      }
    };

    document.addEventListener("wheel", handleDocumentWheel, { passive: false });

    return () => {
      document.removeEventListener("wheel", handleDocumentWheel);
    };
  }, []);

  const mapContainerRef = React.useRef(null);

  const handleWheel = (event) => {
    if (event.deltaY > 0) {
      event.preventDefault();
    }
  };

  const onSelectChangeCountry = (value) => {
    setSelectedCountry(value);
  };
  return (
    <Grid item xs={12} sm={12} md={6}>
      <div
        className="boxs-chart"
        style={{ height: "380px", overflow: "hidden" }}
      >
        <div className="boxs-chart__title">
          <h4>Global involvement</h4>
        </div>
        <div className="boxs-chart__container">
          <div className="select-map-chart">
            <div className="panel-info">
              <CustomSelect
                options={countryChartMap}
                onSelectChange={onSelectChangeCountry}
                timeoutForSkeletonValue={props.timeoutForSkeletonValue}
              />
            </div>
            <div className="panel-info">
              <div className="panel-info__icon">
                {props.timeoutForSkeletonValue ? (
                  <img
                    src={`https://hatscripts.github.io/circle-flags/flags/${selectedCountry.value}.svg`}
                    width="12"
                    alt={selectedCountry.label}
                  ></img>
                ) : (
                  <div className="chart-partfolio__color"></div>
                )}
              </div>
              <div className="panel-info__title">
                <div>Affiliates</div>
                <div>
                  {props.timeoutForSkeletonValue
                    ? `${selectedCountry.affiliates} | ${selectedCountry.procentAffiliates}%`
                    : "0 | 0%"}
                </div>
              </div>
            </div>
          </div>
          <div
            className="map-chart"
            ref={mapContainerRef}
            onWheel={handleWheel}
          >
            <MapChart
              selectedCountry={selectedCountry}
              timeoutForSkeletonValue={props.timeoutForSkeletonValue}
            />
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default GlobalInvolvement;

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

function MapChart(props) {
  React.useEffect(() => {
    fetch(geoUrl).then((response) => response.json());
    // .then((commits) =>
    //   commits.objects.countries1.geometries.forEach((i) =>
    //     console.log(i.properties.name)
    //   )
    // );
    // .then((commits) => console.log(commits.objects.countries1.geometries.id));
  }, []);

  return (
    <ComposableMap projection="geoMercator" style={{ fill: "#8186AF" }}>
      <ZoomableGroup disableScrolling center={[0, -19]} zoom={1}>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              let fill = "#323054";
              if (props.timeoutForSkeletonValue) {
                fill = "#8186AF";

                for (var i = 0; i < countryChartMap.length; i++) {
                  if (props.selectedCountry.label === geo.properties.name) {
                    fill = "rgb(67, 252, 255)";
                  } else if (countryChartMap[i].label === geo.properties.name) {
                    fill = "#FA544D";
                  }
                }
              }

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={fill}
                  onClick={() => console.log(geo.properties.name)}
                />
              );
            })
          }
        </Geographies>
      </ZoomableGroup>
    </ComposableMap>
  );
}

function CustomSelect({ options, onSelectChange, timeoutForSkeletonValue }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelectChange(option);
    setIsOpen(false);
  };

  return (
    <div
      className={`custom-selects custom-selects__map-chart ${
        isOpen ? "open" : ""
      }`}
    >
      <div className="selected-option-castom d-flex" onClick={handleToggle}>
        <div className="status-input">
          {!timeoutForSkeletonValue ? (
            <>
              <div
                className="chart-partfolio__color"
                style={{ marginRight: "10px" }}
              ></div>
              <div className="status-input__title">Country</div>
            </>
          ) : selectedOption ? (
            <>
              <img
                src={`https://hatscripts.github.io/circle-flags/flags/${selectedOption.value}.svg`}
                width="12"
                alt={selectedOption.label}
                style={{ paddingTop: 0 }}
              ></img>
              <div className="status-input__title">{selectedOption.label}</div>
            </>
          ) : (
            <>
              <img
                src={`https://hatscripts.github.io/circle-flags/flags/${options[3].value}.svg`}
                width="12"
                alt={options[3].label}
                style={{ paddingTop: 0 }}
              ></img>
              <div className="status-input__title">{options[0].label} </div>
            </>
          )}
        </div>
      </div>
      <div className={`arrow ${isOpen ? "opened" : ""}`} style={{ right: 0 }}>
        <Path />
      </div>
      {isOpen && (
        <div className="options-castom" style={{ top: "16px" }}>
          {options.map((option, i) => (
            <div
              key={i}
              className="option-castom d-flex"
              onClick={() => handleOptionClick(option)}
            >
              <div className="status-input" stye>
                <img
                  src={`https://hatscripts.github.io/circle-flags/flags/${option.value}.svg`}
                  width="12"
                  alt={option.label}
                ></img>

                <div className="status-input__title">{option.label} </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
