import React from "react";

function SkeletonInvestment(props) {
  return (
    <div className="s-deposit-table">
      <div className="s-table__Date">
        <div className="s-table__Date-icon">
          <div></div>
        </div>
        <div className="s-table__Date-title">
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="s-deposit-table__Project">
        <div className="s-deposit-table__Project-img"></div>
        <div className="s-deposit-table__Project-title">
          <div></div>
        </div>
      </div>

      <div className="s-deposit-table__Distribution">
        <div className="s-deposit-table__Status-title">
          <div></div>
        </div>
      </div>
      <div className="s-deposit-table__Return">
        <div className="s-deposit-table__Date-icon">
          <div></div>
        </div>
        <div className="s-deposit-table__Status-title">
          <div></div>
        </div>
      </div>
      <div className="s-deposit-table__Investment">
        <div className="s-deposit-table__Investment-icon">
          <div></div>
        </div>
        <div className="s-deposit-table__Investment-title">
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="s-deposit-table__Return">
        <div className="s-deposit-table__Date-icon">
          <div></div>
        </div>
        <div className="s-deposit-table__Status-title">
          <div></div>
        </div>
        <div
          className="s-deposit-table__Project-img"
          style={{ marginLeft: "6px" }}
        ></div>
      </div>
    </div>
  );
}

export default SkeletonInvestment;
