import React from "react";
import "./deposit.scss";

import { Grid, Pagination } from "@mui/material";

import data from "../../../data/data";
import { ReactComponent as AmountFiat } from "../../../resources/icon/Table/AmountFiat.svg";
import { ReactComponent as TransactionLink } from "../../../resources/icon/Table/TransactionLink.svg";
import { ReactComponent as TransactionInfo } from "../../../resources/icon/Table/TransactionInfo.svg";
import { ReactComponent as Date } from "../../../resources/icon/Table/Date.svg";
import { ReactComponent as CurrencyBtc } from "../../../resources/icon/Table/CurrencyBtc.svg";
import SkeletonInvestment from "./SkeletonDeposit";

function Deposit(props) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loadingSkeleton, setLoadingSkeleton] = React.useState(true);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoadingSkeleton(false);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  const cardsPerPage = 6;

  const totalCards = data.length;
  const pageCount = Math.ceil(totalCards / cardsPerPage);

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  //Get the current set of cards to display on the current page
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const currentCards = data.slice(startIndex, endIndex);

  const calcCarrency = (euroValue) => {
    const parsedEuroValue = parseFloat(euroValue);
    const bitcoinAmount = parsedEuroValue / 27918.85;
    return bitcoinAmount.toFixed(8);
  };

  return (
    <Grid item xs={12}>
      <h2 className="h2-title" style={{ marginTop: "25px" }}>
        Deposit / Withdrawal
      </h2>
      <p className="h2-title__discription">
        Recent deposit / withdrawal crypto transactions
      </p>
      <div className="box table-box" style={{ height: "520px" }}>
        <div className="deposit-titles-table">
          <div className="titles-table__item" style={{ paddingLeft: "25px" }}>
            Date
          </div>
          <div className="titles-table__item">Username</div>
          <div className="titles-table__item">Action</div>
          <div className="titles-table__item">Amount (fiat)</div>
          <div className="titles-table__item">Amount (Crypto)</div>
          <div className="titles-table__item">Transaction link</div>
        </div>

        <div style={{ minHeight: "338px" }}>
          {loadingSkeleton
            ? [...new Array(6)].map((_, i) => <SkeletonInvestment key={i} />)
            : currentCards.map((items, i) => {
                const actionName = getRandomTransactionType();
                const styleName = {
                  color: `${actionName === "Deposit" ? "#43FCFF" : "#fa544d"}`,
                };
                function getRandomTransactionType() {
                  const transactionTypes = ["Deposit", "Withdrawal"];
                  const randomIndex = Math.floor(
                    Math.random() * transactionTypes.length
                  );
                  return transactionTypes[randomIndex];
                }
                return (
                  <div className="deposit-table" key={i}>
                    <div className="table__Date">
                      <div className="table__Date-icon">
                        <Date />
                      </div>
                      <div className="table__Date-title">
                        <div>{items.dateCreated}</div>
                        <div>18:42 PM</div>
                      </div>
                    </div>
                    <div className="deposit-table__UserName">
                      <div className="deposit-table__UserName-img">
                        <img
                          src="https://hatscripts.github.io/circle-flags/flags/de.svg"
                          width="18"
                          alt="Germany"
                        ></img>
                      </div>
                      <div className="deposit-table__UserName-title">
                        {items.userName.name} {items.userName.secondname}
                      </div>
                    </div>
                    <div className="deposit-table__Action">
                      <div
                        className="deposit-table__Action-title"
                        style={styleName}
                      >
                        {actionName}
                      </div>
                    </div>

                    <div className="deposit-table__Amount">
                      <div className="deposit-table__Amount-icon">
                        <AmountFiat />
                      </div>
                      <div className="deposit-table__Amount-title">
                        <div style={{ color: "#43fcff" }}>
                          € {items.investmentTable.investment}
                        </div>
                      </div>
                    </div>
                    <div className="deposit-table__Investment">
                      <div className="deposit-table__Investment-icon">
                        <CurrencyBtc />
                      </div>
                      <div className="deposit-table__Investment-title">
                        <div>
                          {calcCarrency(items.investmentTable.investment)} BTC
                        </div>
                        <div>€1.00 ≈ 0.00003582 BTC</div>
                      </div>
                    </div>
                    <div className="deposit-table__TransactionLink">
                      <div className="deposit-table__TransactionLink-icon">
                        <TransactionLink />
                      </div>
                      <div className="deposit-table__TransactionLink-title">
                        <a href="http://extej.com/">
                          e8e81dbdcd9338c071f9c7969…
                        </a>
                      </div>
                      <div className="deposit-table__TransactionLink-icon">
                        <TransactionInfo />
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
        <p
          className="h2-title__discription"
          style={{ paddingTop: "20px", textAlign: "end" }}
        >
          Latest actions (Showing {startIndex + 1} to {endIndex} of{" "}
          {data.length})
        </p>
        {!loadingSkeleton && (
          <div className="table-pagination">
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
            />
          </div>
        )}
      </div>
    </Grid>
  );
}
export default Deposit;
