import * as React from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export function ResponsiveDatePickers(props) {
  const [selectedDate, setSelectedDate] = React.useState();

  const handleSelectDate = (value) => {
    setSelectedDate(value);
    props.handleDateChange(value.format("YYYY-MM-DD"));
  };

  const theme = createTheme({
    components: {
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor: "#13153A",
            color: "#8186AF",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: "#8186AF",
          },
          today: {
            border: "1px solid #323054 !important",
          },
        },
      },
      MuiDayCalendar: {
        styleOverrides: {
          weekDayLabel: {
            color: "#8186AF",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "#FA544D",
          },
          overline: {
            color: "#FA544D !important",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "#FA544D",
          },
        },
      },
    },

    palette: {
      primary: {
        main: "#FA544D",
      },
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <MobileDatePicker
          format={"YY-MM-DD"}
          value={selectedDate}
          onChange={(newDate) => handleSelectDate(dayjs(newDate))}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
}
