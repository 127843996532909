import React from "react";

function Support(props) {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.594 6H5.406C5.03335 6.00079 4.67619 6.14918 4.41268 6.41268C4.14918 6.67619 4.00079 7.03335 4 7.406V16.781C4.00079 17.1536 4.14918 17.5108 4.41268 17.7743C4.67619 18.0378 5.03335 18.1862 5.406 18.187H18.594C18.9667 18.1862 19.3238 18.0378 19.5873 17.7743C19.8508 17.5108 19.9992 17.1536 20 16.781V7.406C19.9992 7.03335 19.8508 6.67619 19.5873 6.41268C19.3238 6.14918 18.9667 6.00079 18.594 6ZM18.4 6.938L12.03 13.308L5.6 6.938H18.4ZM4.938 16.587V7.6L9.453 12.076L4.938 16.587ZM5.601 17.25L10.119 12.732L11.7 14.3C11.7879 14.3873 11.9067 14.4362 12.0305 14.4362C12.1543 14.4362 12.2731 14.3873 12.361 14.3L13.905 12.756L18.4 17.25H5.601ZM19.063 16.587L14.57 12.094L19.063 7.6V16.587Z"
        fill="#8186AF"
        className={color}
      />
      <path
        d="M18.594 6H5.406C5.03335 6.00079 4.67619 6.14918 4.41268 6.41268C4.14918 6.67619 4.00079 7.03335 4 7.406V16.781C4.00079 17.1536 4.14918 17.5108 4.41268 17.7743C4.67619 18.0378 5.03335 18.1862 5.406 18.187H18.594C18.9667 18.1862 19.3238 18.0378 19.5873 17.7743C19.8508 17.5108 19.9992 17.1536 20 16.781V7.406C19.9992 7.03335 19.8508 6.67619 19.5873 6.41268C19.3238 6.14918 18.9667 6.00079 18.594 6ZM18.4 6.938L12.03 13.308L5.6 6.938H18.4ZM4.938 16.587V7.6L9.453 12.076L4.938 16.587ZM5.601 17.25L10.119 12.732L11.7 14.3C11.7879 14.3873 11.9067 14.4362 12.0305 14.4362C12.1543 14.4362 12.2731 14.3873 12.361 14.3L13.905 12.756L18.4 17.25H5.601ZM19.063 16.587L14.57 12.094L19.063 7.6V16.587Z"
        fill="#8186AF"
        className={color}
      />
    </svg>
  );
}

export default Support;
