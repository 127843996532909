import { useState } from "react";
import { Element } from "react-scroll";
import Grid from "@mui/material/Grid";

import "./interestRateComparison.scss";

function InterestRateComparison(props) {
  const [valueAllSum, SetValueAllSum] = useState("");

  const rateComparison = [
    {
      profitMarginPercent: 0.8,
      profitMarginEur: 0,
    },
    {
      profitMarginPercent: 1.0,
      profitMarginEur: 0,
    },
    {
      profitMarginPercent: 0.85,
      profitMarginEur: 0,
    },
    {
      profitMarginPercent: 2.55,
      profitMarginEur: 0,
    },
  ];

  const calculateDifference = (valuePercent, valueEur) => {
    return (valuePercent * valueEur).toFixed(2);
  };

  const [valuePlaceH, setValuePlaceH] = useState(true);
  const [value, setValue] = useState("");

  const handleKeyUp = (event) => {
    if (event.key === "Backspace") {
      setValue("€ ");
      setValuePlaceH(false);
      event.preventDefault();
      return;
    }
    setValue("€ ");
    const newValue = (value + event.key)
      .replace(/€/g, "")
      .replace(/\D/g, "")
      .replace(/\s/g, "");
    setValue("€ " + newValue);
    SetValueAllSum(newValue);
    event.preventDefault();
  };

  return (
    <Element name="Interest rates">
      <h2 className="h2-title">Interest rate comparison</h2>

      <div className="box">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="conditions-p-presentation">
              <div className="condition-p-presentation">
                <div className="conditions-sum-p-presentation-left">1.2%</div>
                <p className="conditions-name-p-presentation-left">
                  Dlendo rate
                </p>
              </div>

              <div className="condition-p-presentation">
                <label>
                  <div className="conditions-name-p-presentation-rigth">
                    Loan amount
                  </div>
                  <input
                    className="conditions-sum-p-presentation-rigth"
                    id="test"
                    value={value}
                    onKeyUp={handleKeyUp}
                    placeholder={valuePlaceH ? "€ 0.00" : ""}
                    onClick={(e) => {
                      setValuePlaceH(false);
                      setValue("€ ");
                    }}
                  />
                </label>
              </div>
            </div>

            <div className="table-container">
              <div className="table-head">Bank name</div>
              <div className="table-head">Interest rate</div>
              <div className="table-head">Loan rate</div>
              <div className="table-head table-head-mg">Profit margin (%)</div>
              <div className="table-head">Profit margin (€)</div>

              <div className="table-row border-first">Deutsche Bank</div>
              <div className="table-row column-sec-third">3.75 %</div>
              <div className="table-row column-sec-third">2.95 %</div>
              <div className="table-row column-for-fifth">
                0.80 %<div className="bg-item"></div>
              </div>
              <div className="table-row column-for-fifth border-last">
                €{" "}
                {calculateDifference(
                  rateComparison[0].profitMarginPercent,
                  valueAllSum
                )}{" "}
                <div className="bg-item"></div>
              </div>

              <div className="table-row border-first ">Commerzbank</div>
              <div className="table-row column-sec-third">3.75 %</div>
              <div className="table-row column-sec-third">2.75 %</div>
              <div className="table-row column-for-fifth">
                1.00 %<div className="bg-item"></div>
              </div>
              <div className="table-row column-for-fifth border-last">
                €{" "}
                {calculateDifference(
                  rateComparison[1].profitMarginPercent,
                  valueAllSum
                )}{" "}
                <div className="bg-item"></div>
              </div>

              <div className="table-row border-first ">KFW Bankgruppe</div>
              <div className="table-row column-sec-third">3.75 %</div>
              <div className="table-row column-sec-third">
                <div className="width-50">2.90 %</div>
              </div>
              <div className="table-row column-for-fifth">
                0.85 %<div className="bg-item"></div>
              </div>
              <div className="table-row column-for-fifth border-last">
                €{" "}
                {calculateDifference(
                  rateComparison[2].profitMarginPercent,
                  valueAllSum
                )}{" "}
                <div className="bg-item"></div>
              </div>

              <div className="table-row border-first ">Dlendo</div>
              <div className="table-row column-sec-third">3.75 %</div>
              <div className="table-row column-sec-third">1.20 %</div>
              <div className="table-row column-for-fifth">
                2.55 %<div className="bg-item"></div>
              </div>
              <div className="table-row column-for-fifth border-last">
                €{" "}
                {calculateDifference(
                  rateComparison[3].profitMarginPercent,
                  valueAllSum
                )}{" "}
                <div className="bg-item"></div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Element>
  );
}

export default InterestRateComparison;
