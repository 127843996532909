import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: "all projects",
};

export const filterMapSlice = createSlice({
  name: "filterMap",
  initialState,
  reducers: {
    setCatecoryById: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCatecoryById } = filterMapSlice.actions;

export default filterMapSlice.reducer;
