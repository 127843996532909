import React from "react";
import { Grid } from "@mui/material";

import "./walletTitleChart.scss";
import { ReactComponent as WalletBalance } from "../../../resources/icon/Table/WalletBalance.svg";
import { ReactComponent as WithdrawFunds } from "../../../resources/icon/Table/WithdrawFunds.svg";
import { ReactComponent as FundYourWallet } from "../../../resources/icon/Table/FundYourWallet.svg";
import { ReactComponent as WalletBalanceChart } from "../../../resources/icon/Table/WalletBalanceChart.svg";

function WalletTitleChart(props) {
  return (
    <>
      <Grid item xs={12}>
        <h2 className="h2-title mt-0">Wallet</h2>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <div className="chart-title">
          <div className="chart-title__icon">
            <WalletBalance />
          </div>
          <div className="chart-title__info">
            <div className="chart-title__top">
              <div>Wallet Balance</div>
              <div>
                <WalletBalanceChart />
              </div>
            </div>
            <div className="chart-title__divider"></div>
            <div className="chart-title__bottom">
              <div>${props.timeoutForSkeletonValue ? "1244.00" : "0.00"}</div>
              <div>{props.timeoutForSkeletonValue ? "+23.11" : "+0"}</div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <div className="chart-title chart-title__wallet">
          <div className="chart-title__icon">
            <FundYourWallet />
          </div>
          <div className="chart-title__info">
            <div className="chart-title__top">
              <div>Fund your Wallet</div>
              <div></div>
            </div>
            <div className="chart-title__divider"></div>
            <div className="chart-title__bottom">
              <button className="button-general btn-greey__wallet">
                Top Up
              </button>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <div className="chart-title chart-title__wallet">
          <div className="chart-title__icon">
            <WithdrawFunds />
          </div>
          <div className="chart-title__info">
            <div className="chart-title__top">
              <div>Withdraw Funds</div>
              <div></div>
            </div>
            <div className="chart-title__divider"></div>
            <div className="chart-title__bottom">
              <button className="button-general btn-red__wallet">
                Withdraw
              </button>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
}

export default WalletTitleChart;
