import { Grid } from "@mui/material";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import "./portfolio.scss";
import { ReactComponent as AllProject } from "../../../resources/icon/Table/AllProjects.svg";
import data from "../../../data/data";

const colors = [
  "#FF45D4",
  "#7EFF45",
  "#F8FF45",
  "#AB45FF",
  "#FA544D",
  "#2bff00",
  "#43FCFF",
  "#4E7CFF",
  "#FFB100",
  "#533691",
];

const investment = data
  .slice(0, 10)
  .map((item) => Number(item.investmentTable.investment).toFixed(0) * 100);
const title = data.slice(0, 10).map((item) => item.title);

function Portfolio(props) {
  const [loading, setLoading] = React.useState(true);
  const [hoverData, setHoverData] = React.useState({
    label: "",
    color: "#323054",
    value: "0000000",
  });

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
      setHoverData({ label: title[0], color: "#323054", value: "6530000" });
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  const handleHover = (value) => {
    setHoverData({
      label: title[value],
      color: colors[value],
      value: investment[value],
    });
  };

  const data = {
    labels: title,
    datasets: [
      {
        label: "check",
        data: loading ? [] : investment,
        backgroundColor: colors,
        borderWidth: 0,
        hoverOffset: 10,
      },
    ],
  };

  const options = {
    cutout: 37,
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    onHover: function (event, elements) {
      if (elements.length) {
        var color = data.datasets[0].backgroundColor[elements[0].index];
        var value = data.datasets[0].data[elements[0].index];
        var label = data.labels[elements[0].index];
        setHoverData({ color, value, label });
      }
    },
  };

  const styleBackground = { background: hoverData.color };
  return (
    <Grid item xs={12} sm={6} md={4}>
      <h2 className="h2-title mt-0">Portfolio</h2>
      <p className="h2-title__discription">Dlendo’s portfolio segmentation</p>
      <div className="box-p box-col box-chart">
        <p className="title-rortfolio">Whole portfolio</p>

        <div className="both-container">
          <div className="chart-container">
            <div className="chart-partfolio__wrapper">
              <div className="chart-partfolio__circle-outside"> </div>
              <div className="chart-partfolio__circle"> </div>
              <div
                className="chart-partfolio__color"
                style={styleBackground}
              ></div>
              <h4 className="chart-partfolio__amount">€{hoverData.value}</h4>
            </div>
            <div className="chart-partfolio">
              <Doughnut data={data} options={options} />
            </div>
          </div>

          <div className="all-project">
            <div className="all-project__color">
              <div className="all-project__all-name">All</div>
              <div className="all-project__color-item">
                {colors.map((item, i) => {
                  const styleBackground = { background: item };
                  return (
                    <div
                      key={i}
                      className="color-item"
                      style={styleBackground}
                      onMouseEnter={() => handleHover(i)}
                    ></div>
                  );
                })}

                <div className="color-item-hover"></div>
              </div>
            </div>
            <div className="all-project__name">
              <div>Project</div>
              <div>Invested</div>
            </div>
            <div className="all-project__item" style={{ marginTop: "5px" }}>
              <div className="all-project__icon">
                <div className="color-item" style={styleBackground}></div>
              </div>
              <div className="all-project__title">
                <div>{hoverData.label}</div>
                <div>€{hoverData.value}</div>
              </div>
            </div>

            <div className="all-project__item" style={{ marginTop: "20px" }}>
              <div className="all-project__icon">
                <AllProject />
              </div>
              <div className="all-project__title">
                <div>All projects </div>
                <div>€{loading ? "0000000" : "4233300"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default Portfolio;
