import React from "react";
import { Grid } from "@mui/material";

import "./partnershipTitleChart.scss";
import { ReactComponent as Referrals } from "../../../resources/icon/Table/Referrals.svg";
import { ReactComponent as Funded } from "../../../resources/icon/Table/Funded.svg";
import { ReactComponent as Earned } from "../../../resources/icon/Table/Earned.svg";
import { ReactComponent as LineChartIcon } from "../../../resources/icon/Table/LineChartIcon.svg";
import { ReactComponent as LineChartIconEarned } from "../../../resources/icon/Table/LineChartIconEarned.svg";

function PartnershipTitleChart(props) {
  return (
    <>
      <Grid item xs={12}>
        <h2 className="h2-title mt-0">Partnership</h2>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <div className="chart-title">
          <div className="chart-title__icon">
            <Referrals />
          </div>
          <div className="chart-title__info">
            <div className="chart-title__top">
              <div>Referrals</div>
              <div></div>
            </div>
            <div className="chart-title__divider"></div>
            <div className="chart-title__bottom">
              <div>{props.timeoutForSkeletonValue ? "37" : "0"}</div>
              <div>{props.timeoutForSkeletonValue ? "+3" : "+0"}</div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <div className="chart-title">
          <div className="chart-title__icon">
            <Funded />
          </div>
          <div className="chart-title__info">
            <div className="chart-title__top">
              <div>Funded</div>
              <div>
                <LineChartIcon />
              </div>
            </div>
            <div className="chart-title__divider"></div>
            <div className="chart-title__bottom">
              <div>${props.timeoutForSkeletonValue ? "14244.00" : "0.00"}</div>
              <div>{props.timeoutForSkeletonValue ? "+23.11" : "+0"}</div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <div className="chart-title">
          <div className="chart-title__icon">
            <Earned />
          </div>
          <div className="chart-title__info">
            <div className="chart-title__top">
              <div>Earned</div>
              <div>
                <LineChartIconEarned />
              </div>
            </div>
            <div className="chart-title__divider"></div>
            <div className="chart-title__bottom">
              <div>${props.timeoutForSkeletonValue ? "762.00" : "0.00"}</div>
              <div>{props.timeoutForSkeletonValue ? "+23.11" : "+0"}</div>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
}

export default PartnershipTitleChart;
