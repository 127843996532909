export const pictures = [
  [
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
    {
      url: "https://dekorin.me/wp-content/uploads/2017/11/5-dvuhetajnii-krasivii-dom.jpg",
    },
    {
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
    },
  ],
  [
    {
      url: "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
    },
    {
      url: "https://eco-house.kiev.ua/wp-content/uploads/2019/06/dom-delta.jpg",
    },
    {
      url: "https://stroimdomavspb.com/d/940005/d/58-16.jpg",
    },
  ],
  [
    {
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
    },
    {
      url: "https://stroimdomavspb.com/d/940005/d/58-16.jpg",
    },
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
    {
      url: "https://dekorin.me/wp-content/uploads/2017/11/5-dvuhetajnii-krasivii-dom.jpg",
    },
  ],
  [
    {
      url: "https://dekorin.me/wp-content/uploads/2017/11/5-dvuhetajnii-krasivii-dom.jpg",
    },
    {
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
    },
  ],
  [
    {
      url: "https://stroimdomavspb.com/d/940005/d/58-16.jpg",
    },
    {
      url: "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
    },
    {
      url: "https://eco-house.kiev.ua/wp-content/uploads/2019/06/dom-delta.jpg",
    },
  ],
  [
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
    {
      url: "https://dekorin.me/wp-content/uploads/2017/11/5-dvuhetajnii-krasivii-dom.jpg",
    },
    {
      url: "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
    },
  ],
  [
    {
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
    },
    {
      url: "https://stroimdomavspb.com/d/940005/d/58-16.jpg",
    },
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
  ],
  [
    {
      url: "https://eco-house.kiev.ua/wp-content/uploads/2019/06/dom-delta.jpg",
    },
    {
      url: "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
    },
    {
      url: "https://stroimdomavspb.com/d/940005/d/58-16.jpg",
    },
  ],
  [
    {
      url: "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
    },
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
    {
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
    },
  ],
  [
    {
      url: "https://stroimdomavspb.com/d/940005/d/58-16.jpg",
    },
    {
      url: "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
    },
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
  ],
  [
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
    {
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
    },
    {
      url: "https://eco-house.kiev.ua/wp-content/uploads/2019/06/dom-delta.jpg",
    },
  ],
  [
    {
      url: "https://stroimdomavspb.com/d/940005/d/58-16.jpg",
    },
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
    {
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
    },
  ],
  [
    {
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
    },
    {
      url: "https://stroimdomavspb.com/d/940005/d/58-16.jpg",
    },
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
    {
      url: "https://dekorin.me/wp-content/uploads/2017/11/5-dvuhetajnii-krasivii-dom.jpg",
    },
  ],
  [
    {
      url: "https://dekorin.me/wp-content/uploads/2017/11/5-dvuhetajnii-krasivii-dom.jpg",
    },
    {
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
    },
  ],
  [
    {
      url: "https://stroimdomavspb.com/d/940005/d/58-16.jpg",
    },
    {
      url: "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
    },
    {
      url: "https://eco-house.kiev.ua/wp-content/uploads/2019/06/dom-delta.jpg",
    },
  ],
  [
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
    {
      url: "https://dekorin.me/wp-content/uploads/2017/11/5-dvuhetajnii-krasivii-dom.jpg",
    },
    {
      url: "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
    },
  ],
  [
    {
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
    },
    {
      url: "https://stroimdomavspb.com/d/940005/d/58-16.jpg",
    },
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
  ],
  [
    {
      url: "https://eco-house.kiev.ua/wp-content/uploads/2019/06/dom-delta.jpg",
    },
    {
      url: "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
    },
    {
      url: "https://stroimdomavspb.com/d/940005/d/58-16.jpg",
    },
  ],
  [
    {
      url: "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
    },
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
    {
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
    },
  ],
  [
    {
      url: "https://stroimdomavspb.com/d/940005/d/58-16.jpg",
    },
    {
      url: "https://sadurscy.pl/ru/blog/wp-content/uploads/2019/12/d48ac6040e3478cdf0e32f1919539f5d.jpg",
    },
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
  ],
  [
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
    {
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
    },
    {
      url: "https://eco-house.kiev.ua/wp-content/uploads/2019/06/dom-delta.jpg",
    },
  ],
  [
    {
      url: "https://stroimdomavspb.com/d/940005/d/58-16.jpg",
    },
    {
      url: "https://klike.net/uploads/posts/2020-01/1579858769_1.jpg",
    },
    {
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWj25jqtXNS5ucc06RZBDdpqOacGVr43Xm_0AvKoZ&s",
    },
  ],
];
