import React from "react";

function DlendoPortfoli(props) {
  const { color } = props;
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4368 14.3419L12.4298 14.353C12.4045 14.397 12.3781 14.44 12.3508 14.482C12.0916 14.8798 11.7372 15.2066 11.3196 15.4326C10.902 15.6585 10.4346 15.7766 9.95978 15.7759H7.28878C7.07628 15.7761 6.86564 15.7364 6.66778 15.659C6.47767 15.5849 6.30302 15.476 6.15278 15.338L6.14177 15.327C5.96806 15.1651 5.82964 14.9691 5.73517 14.7513C5.64071 14.5334 5.59223 14.2984 5.59278 14.0609V11.8149C6.1897 11.9883 6.80819 12.0762 7.42978 12.076C7.49978 12.076 7.79077 12.086 7.89177 12.09C7.89177 12.108 7.89177 12.126 7.89177 12.145C7.89228 12.5549 8.05454 12.948 8.34327 13.239C8.632 13.53 9.02388 13.6953 9.43378 13.6989H9.44777C9.49621 13.6999 9.54466 13.6976 9.59278 13.692C9.64278 13.685 9.66579 13.6839 9.70179 13.6779C10.6634 13.5666 11.6332 13.8021 12.4368 14.3419Z"
        stroke="#FA544D"
        className={color}
        strokeWidth="0.6"
      />
      <path
        d="M7.66999 13.7969C7.42633 13.2784 7.30879 12.7096 7.32699 12.1369C7.32699 12.1729 7.25798 12.1369 7.25498 12.0719C6.69343 12.0195 6.13547 11.9341 5.58399 11.8159V14.0659C5.58338 14.3034 5.63182 14.5384 5.72629 14.7562C5.82076 14.9741 5.95922 15.1701 6.13299 15.3319L6.14399 15.3429C6.45446 15.6268 6.86032 15.7835 7.28098 15.7819H9.95199C10.4267 15.7827 10.8941 15.6648 11.3116 15.439C11.7292 15.2132 12.0837 14.8866 12.343 14.4889C12.3703 14.4469 12.3967 14.4039 12.422 14.3599V14.3549C12.395 14.3459 8.87199 15.7039 7.66999 13.7969Z"
        stroke="#FA544D"
        strokeWidth="0.6"
        className={color}
      />
      <path
        d="M7.88583 12.088C7.78583 12.088 7.49383 12.074 7.42383 12.074C7.50883 12.074 7.67884 12.074 7.67984 12.074C7.74884 12.08 7.81783 12.083 7.88583 12.088Z"
        fill="url(#paint0_linear_0_4508)"
        className={color}
      />
      <path
        d="M12.4371 14.3359L12.3672 14.257C12.3935 14.2805 12.4169 14.307 12.4371 14.3359Z"
        fill="url(#paint1_linear_0_4508)"
        className={color}
      />
      <path
        d="M7.91092 8.09703C7.51821 8.09752 7.12633 8.133 6.73991 8.20303C6.65125 8.21903 6.56358 8.23669 6.47691 8.25603C6.18287 8.30959 5.88551 8.343 5.58691 8.35603V6.80103C5.81099 7.00627 6.0948 7.13451 6.39691 7.16703H6.43791C6.47991 7.16703 6.59092 7.17503 6.63792 7.17603H6.68892C6.9546 7.21154 7.20663 7.31495 7.42068 7.47628C7.63474 7.63761 7.80359 7.85141 7.91092 8.09703Z"
        stroke="#FA544D"
        className={color}
        strokeWidth="0.6"
      />
      <path
        d="M7.06493 8.12703C6.80513 8.14179 6.54694 8.17757 6.29292 8.23403C6.24026 8.24336 6.18825 8.25403 6.13692 8.26603C5.96246 8.29767 5.78605 8.31738 5.60892 8.32503L5.58691 6.80103C5.75508 7.17787 6.03056 7.49681 6.37892 7.71803C6.54892 7.80803 6.91993 7.88203 7.06493 8.10303C7.09293 8.14503 7.04393 8.08003 7.06493 8.12703Z"
        stroke="#FA544D"
        className={color}
        strokeWidth="0.6"
      />
      <path
        d="M12.9515 12.355C12.94 13.047 12.763 13.7262 12.4355 14.336C12.2778 13.8828 12.0737 13.4472 11.8265 13.036C11.4115 12.4133 10.8938 11.8655 10.2955 11.416C9.58762 10.8845 8.77933 10.5022 7.91949 10.292L7.76449 10.256C7.55242 10.2096 7.33815 10.1738 7.12249 10.149C7.02949 10.138 6.93649 10.135 6.84249 10.128C6.80449 10.128 6.76449 10.123 6.72649 10.123C6.56183 10.123 6.39849 10.117 6.23649 10.105C6.03649 10.0903 5.83816 10.0666 5.64149 10.034L5.58549 10.024H5.57349C4.98841 9.92068 4.42016 9.73799 3.88449 9.48097C2.65748 8.8904 1.64289 7.93518 0.979492 6.74597C2.09314 7.71804 3.50289 8.28477 4.97949 8.35397C5.08549 8.3593 5.19183 8.36197 5.29849 8.36197C5.39516 8.36197 5.49183 8.35997 5.58849 8.35597C5.88709 8.34295 6.18445 8.30954 6.47849 8.25597C6.56649 8.23997 6.65449 8.22197 6.74149 8.20297C7.12758 8.133 7.51912 8.09752 7.91149 8.09697H7.91949C8.90282 8.09573 9.87382 8.31584 10.7605 8.74097L10.7515 8.74797C11.3307 9.04718 11.8297 9.48099 12.2065 10.013L12.2115 10.021C12.6937 10.7037 12.9523 11.5191 12.9515 12.355Z"
        stroke="#FA544D"
        className={color}
        strokeWidth="0.6"
      />
      <path
        d="M12.4369 14.336L12.3669 14.257L12.3359 14.222C12.2732 14.1417 12.2029 14.0677 12.1259 14.001L12.0699 13.946L12.0319 13.908C11.9426 13.8226 11.8509 13.7396 11.7569 13.659L11.6999 13.611C11.6046 13.531 11.5073 13.454 11.4079 13.38C10.395 12.623 9.18465 12.1753 7.92294 12.091H7.88794C7.78794 12.091 7.49594 12.077 7.42594 12.077C6.80439 12.0776 6.1859 11.9901 5.58894 11.817C5.35943 11.7504 5.13375 11.6713 4.91294 11.58C4.40326 11.3691 3.92214 11.095 3.48094 10.764C3.30498 10.6319 3.13577 10.491 2.97394 10.342C1.93149 9.38616 1.22548 8.11933 0.960938 6.72998L0.979937 6.74598C1.64376 7.93497 2.6587 8.88985 3.88594 9.47998C4.4216 9.737 4.98985 9.91968 5.57494 10.023H5.58694L5.64294 10.033C5.83827 10.0656 6.0366 10.0893 6.23794 10.104C6.39994 10.116 6.56327 10.122 6.72794 10.122H6.75494C6.77694 10.122 6.80794 10.122 6.84394 10.122L6.96094 10.13H7.02994H7.08394H7.09594H7.12694C7.3426 10.1548 7.55687 10.1906 7.76894 10.237L7.92394 10.273L8.00594 10.293C8.34689 10.3813 8.68108 10.4939 9.00594 10.63C9.48904 10.8284 9.94582 11.0857 10.3659 11.396C10.5875 11.5626 10.7951 11.7469 10.9869 11.947C11.2985 12.2774 11.5796 12.6352 11.8269 13.016C12.0755 13.4336 12.2799 13.876 12.4369 14.336Z"
        stroke="#FA544D"
        className={color}
        strokeWidth="0.6"
      />
      <path
        d="M15.1559 9.00993C14.3178 9.66695 13.772 10.6276 13.6368 11.6839C13.6288 11.7439 13.6229 11.8049 13.6179 11.8659C13.5878 12.3889 13.4564 12.9011 13.2309 13.3739C13.4666 12.5273 13.4308 11.628 13.1285 10.8028C12.8262 9.97759 12.2727 9.26798 11.5459 8.77393C12.0291 8.48115 12.5402 8.23727 13.0718 8.04593C13.1518 8.01793 13.2332 7.99159 13.3159 7.96693C13.4325 7.93924 13.552 7.92515 13.6719 7.92493C14.0024 7.92487 14.3243 8.02998 14.5911 8.22504C14.8579 8.4201 15.0557 8.69499 15.1559 9.00993Z"
        stroke="#FA544D"
        className={color}
        strokeWidth="0.6"
      />
      <path
        d="M16.3704 9.14901C15.9318 9.32647 15.5278 9.57928 15.1764 9.896C15.0549 10.0057 14.94 10.1226 14.8324 10.246C14.4635 10.6692 14.1863 11.1643 14.0184 11.7C13.9669 11.8639 13.9262 12.0309 13.8964 12.2L13.8654 12.305C13.8544 12.34 13.8434 12.374 13.8314 12.405L13.8154 12.454C13.7687 12.594 13.7174 12.7317 13.6614 12.867C13.3309 13.4726 12.9139 14.0268 12.4234 14.512C12.3084 14.651 12.1234 14.778 11.9414 14.973C12.4265 14.6045 12.8272 14.1366 13.1166 13.6005C13.406 13.0645 13.5775 12.4727 13.6194 11.865C13.6274 11.805 13.6334 11.744 13.6384 11.683C13.7736 10.6267 14.3194 9.66609 15.1574 9.009C15.3441 8.88702 15.5676 8.83424 15.7892 8.85982C16.0107 8.88539 16.2163 8.9877 16.3704 9.14901Z"
        stroke="#FA544D"
        className={color}
        strokeWidth="0.6"
      />
      <path
        d="M4.56395 17.657C4.56395 17.7596 4.58415 17.8611 4.6234 17.9559C4.66265 18.0506 4.72018 18.1367 4.7927 18.2092C4.86523 18.2818 4.95132 18.3393 5.04608 18.3785C5.14083 18.4178 5.24239 18.438 5.34495 18.438H16.04C16.0959 18.4383 16.1512 18.4497 16.2027 18.4715C16.2542 18.4933 16.3008 18.5251 16.34 18.565L17.049 19.287C17.1066 19.3455 17.1458 19.4197 17.1616 19.5003C17.1774 19.5809 17.1692 19.6643 17.138 19.7403C17.1068 19.8163 17.0539 19.8814 16.986 19.9276C16.9181 19.9737 16.8381 19.9989 16.756 20H5.34495C4.72355 20 4.1276 19.7531 3.6882 19.3138C3.2488 18.8744 3.00195 18.2784 3.00195 17.657V14.757C3.00204 14.602 3.04826 14.4505 3.13471 14.3219C3.22116 14.1932 3.34393 14.0932 3.48741 14.0345C3.63089 13.9759 3.78857 13.9612 3.94039 13.9925C4.09221 14.0237 4.2313 14.0995 4.33995 14.21C4.4834 14.3559 4.56383 14.5524 4.56395 14.757V17.657ZM19.093 9.457V12.769C19.0931 12.9242 19.0469 13.0759 18.9604 13.2048C18.8739 13.3337 18.751 13.4339 18.6073 13.4926C18.4636 13.5513 18.3057 13.5658 18.1537 13.5344C18.0017 13.5029 17.8625 13.4269 17.754 13.316C17.6105 13.1701 17.5301 12.9736 17.53 12.769V8.029L11.047 2.115L7.12395 5.7C6.97956 5.82971 6.79205 5.90101 6.59795 5.9C6.44046 5.89993 6.28667 5.85225 6.15676 5.76321C6.02685 5.67418 5.9269 5.54795 5.87001 5.40109C5.81313 5.25423 5.80198 5.0936 5.83801 4.94029C5.87405 4.78697 5.9556 4.64814 6.07195 4.542L11.047 0L20.478 8.6C20.63 8.73944 20.7207 8.93338 20.7303 9.13948C20.7399 9.34558 20.6675 9.54709 20.529 9.7C20.3895 9.85207 20.1956 9.9428 19.9895 9.95235C19.7834 9.96191 19.5819 9.88952 19.429 9.751L19.093 9.457Z"
        fill="#8186AF"
        className={color}
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_4508"
          x1="7.82613"
          y1="12.2659"
          x2="7.82589"
          y2="11.9168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_4508"
          x1="12.4541"
          y1="14.3612"
          x2="12.3435"
          y2="14.2518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default DlendoPortfoli;
