import React from "react";
import { Link } from "react-router-dom";
import { Grid, Grow } from "@mui/material";

import "./listOfNews.scss";
import { companyNewData } from "../../../data/companyNewe/companyNewData";
import { ReactComponent as SearchIcon } from "../../../resources/icon/Table/SearchIcon.svg";
import { SkInfoNews } from "../../pages/companyNews/SkeletonCompanyNews";
import { ReactComponent as SkeletonImg } from "../../../resources/icon/CompanyNews/SkeletonImg.svg";
import { InfoNews } from "../../pages/companyNews/CompanyNews";

function ListOfNews(props) {
  const [dataState, setDataState] = React.useState(companyNewData);
  const [searchValue, setSearchValue] = React.useState("");
  const [visibleCards, setVisibleCards] = React.useState(6);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingSectionNew, setIsLoadingSectionNew] = React.useState(true);

  const cardsContainerRef = React.useRef(null);
  const searchInputRef = React.useRef(null);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoadingSectionNew(false);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  const handleLoadMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 3);
    setIsLoading(true);
  };

  React.useEffect(() => {
    if (visibleCards > visibleCards - 2 && visibleCards >= 7) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [visibleCards]);

  React.useEffect(() => {
    if (cardsContainerRef.current && visibleCards >= 6) {
      const lastCard = cardsContainerRef.current.lastChild;
      if (lastCard) {
        lastCard.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [visibleCards]);

  React.useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, [visibleCards]);

  React.useEffect(() => {
    if (searchValue) {
      const filteredSearchData = filterById(companyNewData);
      setDataState(filteredSearchData);
    }
    // eslint-disable-next-line
  }, [searchValue]);

  const filterById = (arrData) => {
    const filtered = arrData.filter((item) =>
      String(item.title).includes(searchValue)
    );
    return filtered;
  };
  return (
    <Grid item xs={12}>
      <div className="title-wrapper">
        <div>
          <h2 className="h2-title">News</h2>
          <p className="h2-title__discription">
            News about of changing the Dlendo portfolio
          </p>
        </div>

        <div className="search-filter-table">
          <div
            className="search-filter-table__icon"
            onClick={() => searchInputRef.current.focus()}
          >
            <SearchIcon />
          </div>
          <div className="search-filter-table__input">
            <input
              ref={searchInputRef}
              type="text"
              placeholder="Search by keyword"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="main-news">
        <Grid container spacing={3} justifyContent="center">
          {isLoadingSectionNew ? (
            [...new Array(6)].map((_, i) => {
              return (
                <Grid key={i} item xs={12} sm={6} md={4}>
                  <div className="box-p card-news__item">
                    <div className="skeleton-card-news__img">
                      <SkeletonImg />
                    </div>
                    <SkInfoNews />
                  </div>
                </Grid>
              );
            })
          ) : (
            <>
              {dataState
                .slice(0, visibleCards)
                .map(({ title, pictures, id }, i) => (
                  <Grid item xs={12} sm={6} md={4} key={i}>
                    <Grow
                      in={true}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(true ? { timeout: 400 } : {})}
                    >
                      <Link
                        key={i}
                        to={`/company-news/new/${id}`}
                        className="box-p card-news__item"
                      >
                        {isLoading &&
                        i >= visibleCards - 3 &&
                        i < visibleCards ? (
                          <>
                            <div className="skeleton-card-news__img">
                              <SkeletonImg />
                            </div>
                            <SkInfoNews />
                          </>
                        ) : (
                          <>
                            <img src={pictures} alt="photos" />
                            <InfoNews title={title} />
                          </>
                        )}
                      </Link>
                    </Grow>
                  </Grid>
                ))}
            </>
          )}
        </Grid>
      </div>
      {isLoadingSectionNew ? (
        <div className="comp-news-btn">
          <div className="comp-news-btn__center">
            <div className="sk"></div>
          </div>
        </div>
      ) : (
        visibleCards < dataState.length - 2 && (
          <div className="comp-news__btn" onClick={handleLoadMore}>
            <button className="button-general">More news</button>
          </div>
        )
      )}
    </Grid>
  );
}

export default ListOfNews;
