import React from "react";
import { Grid } from "@mui/material";
import { Line } from "react-chartjs-2";

import "./investmentsAndAccruals.scss";
import { ReactComponent as SaveIcon } from "../../../resources/icon/Table/SaveIcon.svg";

function InvestmentsAndAccruals(props) {
  return (
    <Grid item xs={12} sm={12} md={6}>
      <div className="boxs-chart" style={{ height: "340px" }}>
        <div className="boxs-chart__title">
          <h4>Investments & accruals</h4>
        </div>
        <div className="boxs-chart__container">
          <div className="info-title">
            <div className="info-title__wrapper">
              <div className="info-title__name">Investments</div>
              <div className="info-title__amoutn">
                $ {props.timeoutForSkeletonValue ? "245 347.00" : "0.00"}
              </div>
            </div>
            <div className="info-title__wrapper ">
              <div className="info-title__name">Accruals</div>
              <div className="info-title__amoutn">
                $ {props.timeoutForSkeletonValue ? "122 348.00" : "0.00"}
              </div>
            </div>
            <a href="./test.xlsx" download>
              <SaveIcon />
            </a>
          </div>

          <div className="chart-investment-and-accruals">
            <ChartComponentForAnnual
              timeoutForSkeleton={props.timeoutForSkeleton}
            />
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default InvestmentsAndAccruals;

const ChartComponentForAnnual = (props) => {
  const [value, setValue] = React.useState([0, 0, 0, 0, 0, 0, 0]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue([
        [910, 641, 712, 682, 922, 711, 980],
        [1457, 1747, 1872, 1655, 1935, 2070, 1715],
      ]);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  function convertValueToText(value) {
    if (value === 0) {
      return 0;
    }
    if (value > 999) {
      return value / 1000 + "K";
    }
    return value.toFixed(0);
  }
  const data = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        type: "line",
        label: "Accruals",
        data: value[0],
        backgroundColor: (context) => {
          const bgColor = ["rgba(250, 84, 77, 0)", "rgba(250, 83, 77, 0.26)"];

          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[0]);
          return gradientBg;
        },
        borderColor: "#FA544D",
        borderWidth: 1.5,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#FA544D",
        tension: 0,
        radius: 4,
        fill: true,
      },
      {
        type: "line",
        label: "Investments",
        data: value[1],
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(67, 252, 255, 0)",
            "rgba(67, 252, 255, 0.102)",
          ];
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[0]);
          return gradientBg;
        },
        borderColor: "#43FCFF",
        borderWidth: 1.5,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#43FCFF",
        tension: 0,
        radius: 4,
        fill: true,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,

    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: " #8186AF",
          font: {
            size: 12,
            family: "Montserrat",
          },
          callback: (value) => {
            return convertValueToText(value);
          },
        },
        grid: {
          color: "rgba(255, 255, 255, 0.02)",
          borderColor: "rgba(255, 255, 255, 0.02)",
          tickColor: "rgba(255, 255, 255, 0.02)",
        },
      },
      x: {
        beginAtZero: true,
        ticks: {
          color: " #8186AF",
          font: {
            size: 12,
            family: "Montserrat",
            type: "line",
          },
        },
        grid: {
          color: "#242648",
          tickColor: "#242648",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    pointLabels: {
      color: "#8186AF",
    },
  };

  return <Line options={options} data={data} />;
};
