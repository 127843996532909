import React from "react";
import { Grid } from "@mui/material";

import "./myKeyPerformance.scss";
import { ReactComponent as Balance } from "../../../resources/icon/MyPortfolio/Balance.svg";
import { ReactComponent as TotalInvestment } from "../../../resources/icon/MyPortfolio/TotalInvestment.svg";
import { ReactComponent as YearlyInvested } from "../../../resources/icon/MyPortfolio/YearlyInvested.svg";
import { ReactComponent as CurrentMonth } from "../../../resources/icon/MyPortfolio/CurrentMonth.svg";
import { ReactComponent as AverageInterest } from "../../../resources/icon/MyPortfolio/AverageInterest.svg";
import { ReactComponent as Distribution } from "../../../resources/icon/MyPortfolio/Distribution.svg";
import { ReactComponent as Duration } from "../../../resources/icon/MyPortfolio/Duration.svg";

const dataBalance = [
  {
    title: "Balance",
    topAmount: "1953.43",
    bottonAmount: "0.01389479",
    icon: Balance,
  },
  {
    title: "Total investment",
    topAmount: "28765.98",
    bottonAmount: "0.01389479",
    icon: TotalInvestment,
  },
  {
    title: "Yearly invested",
    topAmount: "576.44",
    bottonAmount: "0.01389479",
    icon: YearlyInvested,
  },
  {
    title: "Distribution",
    topAmount: "26 projects",
    bottonAmount: "Total amount",
    icon: Distribution,
  },
  {
    title: "Duration",
    topAmount: "10/05/2023 ",
    bottonAmount: "Investment up to",
    icon: Duration,
  },
];

const dataProcent = [
  {
    title: "Current month interest",
    topAmount: "6.7",
    icon: CurrentMonth,
  },
  {
    title: "Average interest rate",
    topAmount: "5.9",
    icon: AverageInterest,
  },
];

const dataBalanceSkeleton = [
  {
    title: "Balance",
    topAmount: "0",
    bottomAmount: "0",
    icon: Balance,
  },
  {
    title: "Total investment",
    topAmount: "0",
    bottomAmount: "0",
    icon: TotalInvestment,
  },
  {
    title: "Yearly invested",
    topAmount: "0",
    bottomAmount: "0",
    icon: YearlyInvested,
  },
  {
    title: "Distribution",
    topAmount: "0",
    bottomAmount: "Total amount",
    icon: Distribution,
  },
  {
    title: "Duration",
    topAmount: "0/0/0000",
    bottomAmount: "Investment up to",
    icon: Duration,
  },
];

const dataProcentSkeleton = [
  {
    title: "Current month interest",
    topAmount: "0",
    icon: CurrentMonth,
  },
  {
    title: "Average interest rate",
    topAmount: "0",
    icon: AverageInterest,
  },
];

function MyKeyPerformance(props) {
  const [dataBalanceState, setDataBalanceState] =
    React.useState(dataBalanceSkeleton);
  const [dataProcentState, setDataProcentState] =
    React.useState(dataProcentSkeleton);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDataBalanceState(dataBalance);
      setDataProcentState(dataProcent);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  const creatElements = (title) => {
    let creatElementes = React.createElement(title);
    return creatElementes;
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <h2 className="h2-title mt-0">My key performance</h2>
      <div className="box-p box-col box-special">
        <div className="key-performance">
          {dataBalanceState
            .slice(0, 3)
            .map(({ title, topAmount, bottonAmount, icon }, i) => {
              return (
                <div key={i} className="key-performance__item">
                  <div className="key-performance__title">
                    <div className="key-performance__icon">
                      {creatElements(icon)}
                    </div>
                    <div className="key-performance__text">{title}</div>
                  </div>
                  <div className="key-performance__amount">
                    <div className="key-performance__top">€ {topAmount}</div>
                    <div className="key-performance__bottom">
                      {bottonAmount} BTC
                    </div>
                  </div>
                </div>
              );
            })}

          {dataProcentState.map(({ title, topAmount, icon }, i) => {
            return (
              <div key={i} className="key-performance__item">
                <div className="key-performance__title">
                  <div className="key-performance__icon">
                    {creatElements(icon)}
                  </div>
                  <div className="key-performance__text">{title}</div>
                </div>
                <div className="key-performance__amount-center">
                  <div className="key-performance__top">{topAmount} %</div>
                </div>
              </div>
            );
          })}

          {dataBalanceState
            .slice(3)
            .map(({ title, topAmount, bottonAmount, icon }, i) => {
              return (
                <div key={i} className="key-performance__item">
                  <div className="key-performance__title">
                    <div className="key-performance__icon">
                      {creatElements(icon)}
                    </div>
                    <div className="key-performance__text">{title}</div>
                  </div>
                  <div className="key-performance__amount">
                    <div className="key-performance__top">{topAmount}</div>
                    <div className="key-performance__bottom">
                      {bottonAmount}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Grid>
  );
}

export default MyKeyPerformance;
