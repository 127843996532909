import Grid from "@mui/material/Grid";
import { Element } from "react-scroll";

import "./projectPresentation.scss";

function ProjectPresentation(props) {
  return (
    <Element name="Project presentation">
      <h2 className="h2-title">Project presentation</h2>
      <div className="box">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p className="style-text">
              Project is a development property in 1220 Vienna, with a total
              area of 2,660 m². Within the next 30 months the project is to be
              made ready for construction and then sold to a property developer.
              According to current planning, it is expected that 36 residential
              units with a total living space of 2,527 sqm and a weighted total
              usable space of 2,717 sqm can be built on the property.
              <br />
              <br /> The size of the efficiently cut residential units is
              between 47 and 110 m². In addition, there are many terraces of up
              to 15 m² and private garden areas of up to 208 m² for all ground
              floor apartments. In addition, a children’s playground, 84 bicycle
              parking spaces and the construction of an underground garage with
              25 parking spaces are planned.
            </p>
          </Grid>
        </Grid>
      </div>
    </Element>
  );
}

export default ProjectPresentation;
