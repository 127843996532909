import Grid from "@mui/material/Grid";

import "./projectCosting.scss";
import { Element } from "react-scroll";

function ProjectCosting(props) {
  return (
    <Element id="Project coasting">
      <h2 className="h2-title">Project costing</h2>
      <div className="box">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="project-costing">
              <div className="project-costing__first-container">
                <div className="project-costing__row first-child">
                  Cost of acquisition
                </div>
                <div className="project-costing__row"></div>
                <div className="project-costing__row last-child">
                  €2.078.600
                </div>
                <div className="project-costing__row first-child">
                  Construction costs
                </div>
                <div className="project-costing__row"></div>
                <div className="project-costing__row last-child">
                  €5.342.000
                </div>
                <div className="project-costing__row first-child">Fees</div>
                <div className="project-costing__row"></div>
                <div className="project-costing__row last-child">€250.000</div>
                <div className="project-costing__row first-child">
                  Financing costs
                </div>
                <div className="project-costing__row"></div>
                <div className="project-costing__row last-child">€593.480</div>
                <div className="project-costing__row first-child">Reserve</div>
                <div className="project-costing__row"></div>
                <div className="project-costing__row last-child">€55.920</div>
              </div>

              <div className="project-costing__second-container">
                <div className="project-costing__total-investment">
                  Total investment
                </div>
                <div className="project-costing__amount">€2.078.600</div>
              </div>

              <div className="project-costing__first-container">
                <div className="project-costing__row first-child">
                  Expected sales proceeds
                </div>
                <div className="project-costing__row"></div>
                <div className="project-costing__row last-child">
                  €10.184.000
                </div>
                <div className="project-costing__row first-child">
                  Gross profit
                </div>
                <div className="project-costing__row"></div>
                <div className="project-costing__row last-child">
                  €1.864.000
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Element>
  );
}

export default ProjectCosting;
