import React from "react";
import { Grid, Pagination } from "@mui/material";

import { nDate } from "./nData";
import data from "../../../data/data";
import "./investments.scss";
import { ResponsiveDatePickers } from "../../fewSimpleComponents/calender/Calendar";
import { ReactComponent as Path } from "../../../resources/icon/SettingProfile/Path.svg";
import { ReactComponent as Date } from "../../../resources/icon/Table/Date.svg";
import { ReactComponent as Active } from "../../../resources/icon/Table/Active.svg";
import { ReactComponent as Pending } from "../../../resources/icon/Table/Pending.svg";
import { ReactComponent as Status } from "../../../resources/icon/Table/Status.svg";
import { ReactComponent as Completed } from "../../../resources/icon/Table/Completed.svg";
import { ReactComponent as TotalInvestment } from "../../../resources/icon/Table/TotalInvestment.svg";
import { ReactComponent as Distribution } from "../../../resources/icon/Dashboard/Distribution.svg";
import { ReactComponent as InvestmentIcon } from "../../../resources/icon/PopUp/Investment.svg";
import { ReactComponent as ReturnProcent } from "../../../resources/icon/Table/ReturnProcent.svg";
import { ReactComponent as Term } from "../../../resources/icon/Table/Term.svg";
import SkeletonInvestment from "./SkeletonInvestment";

function Investments(props) {
  const [filtereData, setFiltereData] = React.useState(data);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState(null);
  const [selectedManthly, setSelectedManthly] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState(null);
  const [loadingSkeleton, setLoadingSkeleton] = React.useState(true);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoadingSkeleton(false);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    let filteredDate = filterByDate(data);
    let filteredStatus = filterByStatus(filteredDate);
    let filteredManthly = filterByManthly(filteredStatus);
    let passFilter = [...filteredManthly];

    setFiltereData(passFilter);
    // eslint-disable-next-line
  }, [selectedDateFrom, selectedDateTo, selectedStatus, selectedManthly]);

  const onSelectChangeManthly = (value) => {
    setSelectedManthly(value);
  };

  const onSelectChangeStatus = (value) => {
    setSelectedStatus(value);
  };

  const handleDateChangeFrom = (date) => {
    setSelectedDateFrom(date);
  };

  const handleDateChangeTo = (date) => {
    setSelectedDateTo(date);
  };

  const fromDate = selectedDateFrom;
  const toDate = selectedDateTo;

  const filterByManthly = (dataArr) => {
    return dataArr.filter((item) => {
      if (!selectedManthly || selectedManthly === "All period") {
        return item;
      } else {
        return item.investmentTable.distribution === selectedManthly;
      }
    });
  };

  const filterByStatus = (dataArr) => {
    return dataArr.filter((item) => {
      if (!selectedStatus || selectedStatus.title === "All status") {
        return item;
      } else {
        return item.investmentTable.status === selectedStatus.title;
      }
    });
  };

  const filterByDate = (data) => {
    return data.filter((item) => {
      let value;
      const itemDate = nDate(item.dateCreated);
      const filterFromDate = nDate(fromDate);
      const filterToDate = nDate(toDate);

      if (fromDate === null && toDate === null) {
        value = item;
      } else if (fromDate && toDate) {
        value = itemDate > filterFromDate && itemDate < filterToDate;
      } else if (fromDate) {
        value = itemDate > filterFromDate;
      } else if (toDate) {
        value = itemDate < filterToDate;
      }
      return value;
    });
  };

  const handleToggleCalendar = () => {
    setIsOpen(!isOpen);
  };
  const cardsPerPage = 6;

  const totalCards = filtereData.length;
  const pageCount = Math.ceil(totalCards / cardsPerPage);

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  //Get the current set of cards to display on the current page
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const currentCards = filtereData.slice(startIndex, endIndex);

  const amoutnAllSum = (array1) => {
    const sumWithInitial = array1.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return sumWithInitial;
  };

  const calcCarrency = (euroValue) => {
    const parsedEuroValue = parseFloat(euroValue);
    const bitcoinAmount = parsedEuroValue / 27918.85;
    return bitcoinAmount.toFixed(8);
  };

  const showIconStatus = (value) => {
    switch (value) {
      case "Pending":
        return Pending;
      case "Completed":
        return Completed;
      case "Active":
        return Active;
      default:
        return value;
    }
  };
  const creatElements = (value) => {
    let creatElementes = React.createElement(value);
    return creatElementes;
  };

  const totalInvestmentEur = amoutnAllSum(
    filtereData.map((item) => +item.investmentTable.investment)
  ).toFixed(2);
  const totalInvestmentBTC = calcCarrency(
    amoutnAllSum(
      filtereData.map((item) => +item.investmentTable.investment)
    ).toFixed(2)
  );
  return (
    <Grid item xs={12}>
      <h2 className="h2-title" style={{ marginTop: "75px" }}>
        Investments
      </h2>
      <p className="h2-title__discription">
        List of active/pending/completed investments{" "}
      </p>
      <div className="box table-box">
        <div className="filter-table__container">
          <div className="filter-table__wrapper">
            <div className="filter-table">
              <div
                className="filter-table__icon"
                onClick={handleToggleCalendar}
              >
                <Date />
              </div>
              <div
                className="filter-table__input"
                onClick={handleToggleCalendar}
              >
                <ResponsiveDatePickers
                  handleDateChange={handleDateChangeFrom}
                />
              </div>
            </div>
            {selectedDateFrom && (
              <div
                onClick={() => {
                  setSelectedDateFrom(null);
                }}
                className="cancel-filter"
              >
                &#215;
              </div>
            )}

            <div className="filter-table" style={{ marginRight: "30px" }}>
              <div className="filter-table__icon">
                <Date />
              </div>
              <div className="filter-table__input">
                <ResponsiveDatePickers handleDateChange={handleDateChangeTo} />
              </div>
            </div>

            {selectedDateTo && (
              <div
                onClick={() => {
                  setSelectedDateTo(null);
                }}
                className="cancel-filter"
                style={{ marginLeft: "-25px", marginRight: "25px" }}
              >
                &#215;
              </div>
            )}

            <div
              className="filter-table"
              style={{ width: "120px", marginRight: "30px" }}
            >
              <CustomSelect onSelectChange={onSelectChangeStatus} />
            </div>

            <div className="filter-table" style={{ width: "117px" }}>
              <div className="filter-table__icon">
                <Distribution />
              </div>

              <CustomSelectForDate onSelectChange={onSelectChangeManthly} />
            </div>
          </div>

          <div
            className="filter-table"
            style={{ width: "fit-content", marginRight: 0 }}
          >
            <div className="filter-table__icon">
              <TotalInvestment />
            </div>
            <div className="filter-table__title">
              Total investment <div></div>{" "}
              <span>
                € {!loadingSkeleton ? totalInvestmentEur : (0.0).toFixed(1)}/
              </span>
              {!loadingSkeleton ? totalInvestmentBTC : (0.0).toFixed(6)} BTC
            </div>
          </div>
        </div>

        <div className="titles-table">
          <div className="titles-table__item" style={{ paddingLeft: "25px" }}>
            Date
          </div>
          <div className="titles-table__item">Project</div>
          <div className="titles-table__item">Status</div>
          <div className="titles-table__item">Distribution </div>
          <div className="titles-table__item">Return</div>
          <div className="titles-table__item">Term</div>
          <div className="titles-table__item">Investment</div>
          <div className="titles-table__item">Funding state</div>
        </div>

        <div style={{ minHeight: "338px" }}>
          {loadingSkeleton
            ? [...new Array(6)].map((item) => <SkeletonInvestment />)
            : currentCards.map((items, i) => {
                return (
                  <div className="table" key={i}>
                    <div className="table__Date">
                      <div className="table__Date-icon">
                        <Date />
                      </div>
                      <div className="table__Date-title">
                        <div>{items.dateCreated}</div>
                        <div>18:42 PM</div>
                      </div>
                    </div>
                    <div className="table__Project">
                      <div className="table__Project-img">
                        <img src={items.pictures[0].url} alt={items.title} />
                      </div>
                      <div className="table__Project-title">{items.title}</div>
                    </div>
                    <div className="table__Status">
                      <div className="table__Status-icon">
                        {creatElements(
                          showIconStatus(items.investmentTable.status)
                        )}
                      </div>
                      <div className="table__Status-title">
                        {items.investmentTable.status}
                      </div>
                    </div>
                    <div className="table__Distribution">
                      <div className="table__Distribution-icon">
                        <Distribution />
                      </div>
                      <div className="table__Distribution-title">
                        {items.investmentTable.distribution}
                      </div>
                    </div>
                    <div className="table__Return">
                      <div className="table__Return-icon">
                        <ReturnProcent />
                      </div>
                      <div className="table__Return-title">
                        {items.procent.return}%
                      </div>
                    </div>
                    <div className="table__Term">
                      <div className="table__Term-icon">
                        <Term />
                      </div>
                      <div className="table__Term-title">
                        {items.procent.duration} Month
                      </div>
                    </div>
                    <div className="table__Investment">
                      <div className="table__Investment-icon">
                        <InvestmentIcon />
                      </div>
                      <div className="table__Investment-title">
                        <div>€ {items.investmentTable.investment}</div>
                        <div>
                          {calcCarrency(items.investmentTable.investment)} BTC
                        </div>
                      </div>
                    </div>
                    <div className="table__Funding">
                      <div className="table__Funding-title">
                        <div className="table__Funding-title">
                          €124000/<span>€670000</span>
                        </div>
                      </div>
                      <div className="table__Term-range">
                        <div className="range-castom">
                          <div className="range-castom__track">
                            <div
                              className="range-castom__fill"
                              style={{
                                width: `${items.investmentTable.rangeProcent}%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
        <p
          className="h2-title__discription"
          style={{ paddingTop: "20px", textAlign: "end" }}
        >
          Latest actions (Showing {startIndex + 1} to {endIndex} of{" "}
          {filtereData.length})
        </p>
        {!loadingSkeleton && (
          <div className="table-pagination">
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
            />
          </div>
        )}
      </div>
    </Grid>
  );
}
export default Investments;

function CustomSelect({ options, onSelectChange }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelectChange(option);
    setIsOpen(false);
  };

  const status = [
    {
      title: "All status",
      icon: Status,
    },
    {
      title: "Active",
      icon: Active,
    },
    {
      title: "Completed",
      icon: Completed,
    },
    {
      title: "Pending",
      icon: Pending,
    },
  ];

  const creatElements = (value) => {
    let creatElementes = React.createElement(value);
    return creatElementes;
  };

  return (
    <div className={`custom-selects ${isOpen ? "open" : ""}`}>
      <div className="selected-option-castom d-flex" onClick={handleToggle}>
        {selectedOption ? (
          <div className="status-input">
            <div className="status-input__icon">
              {" "}
              {creatElements(selectedOption.icon)}
            </div>
            <div className="status-input__title">{selectedOption.title} </div>
          </div>
        ) : (
          <div className="status-input">
            <div className="status-input__icon">
              {creatElements(status[0].icon)}
            </div>
            <div className="status-input__title">{status[0].title} </div>
          </div>
        )}
      </div>
      <div className={`arrow ${isOpen ? "opened" : ""}`} style={{ right: 0 }}>
        <Path />
      </div>
      {isOpen && (
        <div className="options-castom">
          {status.map((option, i) => (
            <div
              key={i}
              className="option-castom d-flex"
              onClick={() => handleOptionClick(option)}
            >
              <div className="status-input">
                <div className="status-input__icon">
                  {creatElements(option.icon)}
                </div>
                <div className="status-input__title">{option.title} </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function CustomSelectForDate({ options, onSelectChange }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelectChange(option);
    setIsOpen(false);
  };

  return (
    <div className={`custom-selects ${isOpen ? "open" : ""}`}>
      <div className="selected-option-castom d-flex" onClick={handleToggle}>
        {selectedOption ? (
          <span style={{ paddingLeft: "3px" }}>{selectedOption}</span>
        ) : (
          <span style={{ paddingLeft: "3px" }}>All period</span>
        )}
      </div>
      <div className={`arrow ${isOpen ? "opened" : ""}`} style={{ right: 0 }}>
        <Path />
      </div>
      {isOpen && (
        <div className="options-castom">
          {["All period", "Yearly", "Monthly", "Quarterly"].map((option, i) => (
            <div
              key={i}
              className="option-castom d-flex"
              onClick={() => handleOptionClick(option)}
            >
              <span style={{ paddingLeft: "3px" }}>{option}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
