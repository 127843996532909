import React from "react";
import { Grid } from "@mui/material";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

import data from "../../../data/data";
import "./diversifiaction.scss";
import { ReactComponent as Path } from "../../../resources/icon/SettingProfile/Path.svg";
import SelectOptionSkeleton from "../../../resources/img/SelectOptionSkeleton.png";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    r: {
      beginAtZero: true,
      min: 0,
      max: 10,
      pointLabels: {
        color: "#8186AF",
        font: {
          size: 8,
          family: "Montserrat",
        },
      },
      ticks: {
        display: false,
      },
      grid: {
        color: "rgb(50, 48, 84)",
      },
      angleLines: {
        color: "rgb(50, 48, 84)",
      },
    },
  },
};

const loanData = {
  loanAmount: 0,
  interestRate: 0,
  duration: 0,
  investmentProduct: 0,
  assetClass: 0,
  location: 0,
  amount: 0,
};

const newData = {
  loanAmount: 5,
  interestRate: 8,
  duration: 9,
  investmentProduct: 10,
  assetClass: 7,
  location: 2,
  amount: 41,
};

function Diversifiaction(props) {
  const [selectedValue, setSelectedValue] = React.useState(loanData);

  function getRandomNumber() {
    return Math.floor(Math.random() * 11);
  }

  const sumNumbers = (numbers) => {
    return Object.values(numbers)
      .slice(0, 6)
      .reduce((sum, number) => sum + number, 0);
  };

  const handleSelectChange = (value) => {
    const { loanAmount, invest } = value.procent;
    const data = {
      loanAmount: loanAmount,
      interestRate: getRandomNumber(),
      duration: getRandomNumber(),
      investmentProduct: invest,
      assetClass: getRandomNumber(),
      location: getRandomNumber(),
    };

    setSelectedValue({ ...data, amount: sumNumbers(data) });
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSelectedValue(newData);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  const dataChar = {
    labels: [
      "Loan amount",
      "Interest rate",
      "Duration",
      "Investment product",
      "Asset class",
      "Location",
    ],
    datasets: [
      {
        data: [
          selectedValue.loanAmount,
          selectedValue.interestRate,
          selectedValue.duration,
          selectedValue.investmentProduct,
          selectedValue.assetClass,
          selectedValue.location,
        ],
        backgroundColor: "rgba(250, 84, 77, 0.6)",
        borderColor: "#43FCFF",
        borderWidth: 1,
        radius: 2,
      },
    ],
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <h2 className="h2-title mt-0">Diversifiaction</h2>
      <div className="box-p box-col box-special" style={{ paddingTop: "0" }}>
        <div className="radar-chrt">
          <Radar data={dataChar} options={options} />
        </div>

        <div className="diversifiaction-select" style={{ marginTop: "25px" }}>
          <CustomSelect
            options={data}
            onSelectChange={handleSelectChange}
            timeoutForSkeleton={props.timeoutForSkeleton}
          />
        </div>

        <div className="total-score">
          <div className="total-score__text">
            Total score <span>{selectedValue.amount}/100</span>
          </div>
        </div>

        <div className="total-scores">
          <div className="total-scores__wrapper">
            <div className="total-scores__item">
              <div className="total-scores__title">Loan amount</div>
              <div className="total-scores__amount">
                {selectedValue.loanAmount}/10
              </div>
            </div>
            <div className="total-scores__item">
              <div className="total-scores__title">Interest rate</div>
              <div className="total-scores__amount">
                {selectedValue.interestRate}/10
              </div>
            </div>
            <div className="total-scores__item">
              <div className="total-scores__title">Duration</div>
              <div className="total-scores__amount">
                {selectedValue.duration}/10
              </div>
            </div>
          </div>

          <div className="total-scores__wrapper">
            <div className="total-scores__item">
              <div className="total-scores__title">Investment product</div>
              <div className="total-scores__amount">
                {selectedValue.investmentProduct}/10
              </div>
            </div>
            <div className="total-scores__item">
              <div className="total-scores__title">Asset class</div>
              <div className="total-scores__amount">
                {selectedValue.assetClass}/10
              </div>
            </div>
            <div className="total-scores__item">
              <div className="total-scores__title">Location</div>
              <div className="total-scores__amount">
                {selectedValue.location}/10
              </div>
            </div>
          </div>
        </div>

        <div className="diversifiaction-text">
          Build up a portfolio! The more different projects you choose, the
          better your diversification. A balanced investment strategy is the
          right way to achieve optimal.
        </div>
      </div>
    </Grid>
  );
}

export default Diversifiaction;

export function CustomSelect({ options, onSelectChange, timeoutForSkeleton }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [dataState, setDataState] = React.useState(true);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDataState(false);
    }, timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelectChange(option);
    setIsOpen(false);
  };

  return (
    <div className={`custom-selects ${isOpen ? "open" : ""}`}>
      <div className="selected-option-castom d-flex" onClick={handleToggle}>
        {dataState ? (
          <InputOption
            title={"Select the project"}
            img={SelectOptionSkeleton}
          />
        ) : selectedOption ? (
          <InputOption
            title={selectedOption.title}
            img={selectedOption.pictures[0].url}
          />
        ) : (
          <InputOption
            title={options[5].title}
            img={options[5].pictures[0].url}
          />
        )}
      </div>
      <div className={`arrow ${isOpen ? "opened" : ""}`}>
        <Path />
      </div>
      {isOpen && (
        <div className="options-castom">
          {options.map((option, i) => (
            <div
              key={i}
              className="option-castom d-flex"
              onClick={() => handleOptionClick(option)}
            >
              <InputOption title={option.title} img={option.pictures[0].url} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const InputOption = ({ title, img }) => {
  return (
    <>
      <div className="input-option-img">
        <img src={img} alt={title} />
      </div>
      <span>{title}</span>
    </>
  );
};
