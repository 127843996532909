import React from "react";

function IconFunded(props) {
  return (
    <div>
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.25207 11.4981H4.91207C4.79352 11.4981 4.67982 11.451 4.59599 11.3672C4.51216 11.2834 4.46507 11.1697 4.46507 11.0511V9.45111L4.59607 9.58212C4.68049 9.6629 4.79319 9.7074 4.91003 9.70612C5.02687 9.70483 5.13856 9.65785 5.22118 9.57523C5.3038 9.4926 5.35079 9.38091 5.35208 9.26407C5.35337 9.14723 5.30885 9.03453 5.22807 8.9501L4.33507 8.05713C4.25125 7.97335 4.13758 7.92627 4.01907 7.92627C3.90056 7.92627 3.78689 7.97335 3.70307 8.05713L2.81007 8.9501C2.72929 9.03453 2.68477 9.14723 2.68606 9.26407C2.68735 9.38091 2.73433 9.4926 2.81695 9.57523C2.89957 9.65785 3.01127 9.70483 3.12811 9.70612C3.24495 9.7074 3.35764 9.6629 3.44207 9.58212L3.57307 9.45111V11.0511C3.57334 11.4064 3.7146 11.7471 3.96584 11.9984C4.21708 12.2496 4.55776 12.3908 4.91307 12.3911H6.25307C6.31348 12.394 6.37385 12.3846 6.43051 12.3634C6.48717 12.3423 6.53895 12.3098 6.58271 12.2681C6.62648 12.2263 6.66132 12.1761 6.68512 12.1205C6.70893 12.0649 6.7212 12.0051 6.7212 11.9446C6.7212 11.8841 6.70893 11.8243 6.68512 11.7687C6.66132 11.7131 6.62648 11.6629 6.58271 11.6212C6.53895 11.5794 6.48717 11.547 6.43051 11.5258C6.37385 11.5047 6.31248 11.4952 6.25207 11.4981Z"
          fill="#8186AF"
        />
        <path
          d="M12.3752 3.59C12.2914 3.50622 12.1777 3.45916 12.0592 3.45916C11.9407 3.45916 11.827 3.50622 11.7432 3.59L11.6122 3.721V2.12101C11.6119 1.7657 11.4707 1.42502 11.2195 1.17377C10.9682 0.922533 10.6275 0.781271 10.2722 0.781006H8.93222C8.81746 0.786439 8.70918 0.835851 8.62988 0.918991C8.55059 1.00213 8.50635 1.11261 8.50635 1.2275C8.50635 1.3424 8.55059 1.45288 8.62988 1.53602C8.70918 1.61916 8.81746 1.66857 8.93222 1.674H10.2722C10.3908 1.674 10.5045 1.7211 10.5883 1.80493C10.6721 1.88876 10.7192 2.00245 10.7192 2.12101V3.721L10.5882 3.59C10.5471 3.54699 10.4977 3.51264 10.4431 3.48895C10.3885 3.46526 10.3297 3.45272 10.2702 3.45207C10.2106 3.45141 10.1516 3.46265 10.0965 3.48513C10.0413 3.50761 9.99128 3.54087 9.94919 3.58297C9.90709 3.62506 9.87383 3.67514 9.85135 3.73026C9.82887 3.78538 9.81764 3.84444 9.8183 3.90397C9.81895 3.9635 9.83148 4.02229 9.85516 4.0769C9.87885 4.13152 9.91323 4.18085 9.95624 4.22201L10.8492 5.11501C10.9332 5.19854 11.0468 5.24544 11.1652 5.24544C11.2837 5.24544 11.3973 5.19854 11.4812 5.11501L12.3742 4.22201C12.4582 4.13832 12.5054 4.02473 12.5056 3.90621C12.5058 3.7877 12.4589 3.67396 12.3752 3.59Z"
          fill="#8186AF"
        />
        <path
          d="M0 0.559998V4.903C0 5.05152 0.0589998 5.19396 0.16402 5.29898C0.269041 5.404 0.411479 5.463 0.56 5.463H7.3C7.37354 5.463 7.44636 5.44851 7.5143 5.42037C7.58224 5.39223 7.64398 5.35098 7.69598 5.29898C7.74798 5.24698 7.78923 5.18524 7.81737 5.1173C7.84551 5.04936 7.86 4.97654 7.86 4.903V0.559998C7.86 0.486457 7.84551 0.413638 7.81737 0.345695C7.78923 0.277753 7.74798 0.216018 7.69598 0.164017C7.64398 0.112016 7.58224 0.0707681 7.5143 0.0426254C7.44636 0.0144828 7.37354 0 7.3 0H0.56C0.48646 0 0.41364 0.0144828 0.345697 0.0426254C0.277755 0.0707681 0.216021 0.112016 0.16402 0.164017C0.112019 0.216018 0.0707701 0.277753 0.0426275 0.345695C0.0144848 0.413638 0 0.486457 0 0.559998ZM7.075 0.780998V1.494C6.91072 1.43546 6.76152 1.34111 6.63821 1.21779C6.51489 1.09448 6.42054 0.945276 6.362 0.780998H7.075ZM0.78 0.780998H1.493C1.43446 0.945276 1.34011 1.09448 1.21679 1.21779C1.09348 1.34111 0.944277 1.43546 0.78 1.494V0.780998ZM0.78 4.681V3.968C0.944277 4.02654 1.09348 4.12088 1.21679 4.2442C1.34011 4.36752 1.43446 4.51672 1.493 4.681H0.78ZM7.075 4.681H6.362C6.42054 4.51672 6.51489 4.36752 6.63821 4.2442C6.76152 4.12088 6.91072 4.02654 7.075 3.968V4.681ZM7.075 3.16C6.70045 3.2371 6.35669 3.42224 6.0862 3.69255C5.81571 3.96286 5.63034 4.3065 5.553 4.681H2.3C2.22262 4.30695 2.03748 3.96371 1.76738 3.69362C1.49729 3.42352 1.15405 3.23837 0.78 3.161V2.302C1.15432 2.22448 1.49774 2.03903 1.76786 1.76856C2.03798 1.49808 2.22297 1.15442 2.3 0.779999H5.554C5.63134 1.1545 5.81671 1.49814 6.0872 1.76845C6.35769 2.03876 6.70145 2.2239 7.076 2.301L7.075 3.16Z"
          fill="#8186AF"
        />
        <path
          d="M3.92784 1.56104C3.69623 1.56104 3.46983 1.62971 3.27726 1.75838C3.08469 1.88706 2.9346 2.06994 2.84597 2.28391C2.75734 2.49788 2.73415 2.73334 2.77934 2.96049C2.82452 3.18764 2.93604 3.39629 3.09981 3.56006C3.26358 3.72383 3.47223 3.83535 3.69938 3.88053C3.92653 3.92571 4.16198 3.90253 4.37596 3.8139C4.58993 3.72527 4.77281 3.57518 4.90148 3.38261C5.03016 3.19004 5.09883 2.96363 5.09883 2.73203C5.09857 2.42155 4.97511 2.12385 4.75556 1.9043C4.53602 1.68476 4.23832 1.5613 3.92784 1.56104ZM3.92784 3.12203C3.8507 3.12203 3.7753 3.09916 3.71116 3.0563C3.64703 3.01345 3.59704 2.95254 3.56752 2.88128C3.538 2.81002 3.53028 2.73161 3.54533 2.65595C3.56038 2.5803 3.59752 2.5108 3.65206 2.45626C3.70661 2.40172 3.7761 2.36457 3.85175 2.34953C3.9274 2.33448 4.00582 2.3422 4.07708 2.37172C4.14835 2.40124 4.20925 2.45123 4.25211 2.51537C4.29496 2.5795 4.31784 2.6549 4.31784 2.73203C4.31757 2.83539 4.2764 2.93443 4.20332 3.00751C4.13024 3.0806 4.03119 3.12177 3.92784 3.12203Z"
          fill="#8186AF"
        />
        <path
          d="M11.5142 6.77396L7.8962 9.64896C7.85445 9.68162 7.81961 9.72228 7.7937 9.76852C7.7678 9.81477 7.75134 9.8657 7.74529 9.91837C7.73924 9.97103 7.74372 10.0244 7.75847 10.0753C7.77322 10.1262 7.79795 10.1737 7.83121 10.215C7.86387 10.2567 7.9045 10.2916 7.95075 10.3175C7.997 10.3434 8.04794 10.3598 8.1006 10.3659C8.15327 10.3719 8.20661 10.3674 8.25752 10.3527C8.30844 10.3379 8.35592 10.3132 8.3972 10.28L9.06619 9.74796V13.315C9.06619 13.4054 9.10213 13.4921 9.16608 13.5561C9.23003 13.62 9.31676 13.6559 9.4072 13.6559H11.1422C11.2326 13.6559 11.3194 13.62 11.3833 13.5561C11.4473 13.4921 11.4832 13.4054 11.4832 13.315V11.423H12.2882V13.315C12.2882 13.4054 12.3241 13.4921 12.3881 13.5561C12.452 13.62 12.5388 13.6559 12.6292 13.6559H14.3612C14.4516 13.6559 14.5384 13.62 14.6023 13.5561C14.6663 13.4921 14.7022 13.4054 14.7022 13.315V9.76395L15.3442 10.278C15.3853 10.3113 15.4327 10.3362 15.4836 10.3511C15.5344 10.366 15.5877 10.3707 15.6404 10.3648C15.693 10.3589 15.744 10.3427 15.7903 10.317C15.8366 10.2912 15.8774 10.2566 15.9102 10.215C15.9436 10.1738 15.9684 10.1264 15.9833 10.0756C15.9982 10.0247 16.0029 9.97145 15.997 9.91879C15.9912 9.86613 15.9749 9.81515 15.9492 9.76883C15.9235 9.72251 15.8888 9.68177 15.8472 9.64896L12.2612 6.77496C12.1551 6.69037 12.0235 6.64423 11.8879 6.64404C11.7522 6.64386 11.6205 6.68965 11.5142 6.77396ZM13.8982 12.8499H13.0932V10.955C13.0932 10.9106 13.0845 10.8666 13.0675 10.8256C13.0505 10.7846 13.0256 10.7474 12.9942 10.716C12.9628 10.6846 12.9256 10.6597 12.8846 10.6427C12.8435 10.6257 12.7996 10.617 12.7552 10.617H11.0162C10.9718 10.617 10.9279 10.6257 10.8869 10.6427C10.8459 10.6597 10.8086 10.6846 10.7772 10.716C10.7458 10.7474 10.7209 10.7846 10.7039 10.8256C10.6869 10.8666 10.6782 10.9106 10.6782 10.955V12.8499H9.8732V9.10696L11.8852 7.50696L13.8992 9.11795V12.849L13.8982 12.8499Z"
          fill="#FA544D"
        />
      </svg>
    </div>
  );
}

export default IconFunded;
