import React from "react";
import { Grid, Pagination } from "@mui/material";

import data from "../../../data/data";
import "./profitableProjects.scss";
import { ReactComponent as Date } from "../../../resources/icon/Table/Date.svg";
import { ReactComponent as InvestorsGroup } from "../../../resources/icon/Table/InvestorsGroup.svg";
import { ReactComponent as ReturnProcent } from "../../../resources/icon/Table/ReturnProcent.svg";
import { ReactComponent as AmountFiat } from "../../../resources/icon/Table/AmountFiat.svg";

import SkeletonInvestment from "./SkeletonProfitableProjects";

function ProfitableProjects(props) {
  const [currentPage, setCurrentPage] = React.useState(1);

  const [loadingSkeleton, setLoadingSkeleton] = React.useState(true);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoadingSkeleton(false);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  const cardsPerPage = 6;

  const totalCards = data.length;
  const pageCount = Math.ceil(totalCards / cardsPerPage);

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  //Get the current set of cards to display on the current page
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const currentCards = data.slice(startIndex, endIndex);

  return (
    <Grid item xs={12}>
      <h2 className="h2-title" style={{ marginTop: "25px" }}>
        Profitable projects
      </h2>
      <p className="h2-title__discription">
        List of Dlendo profitable projects
      </p>
      <div className="box table-box" style={{ height: "520px" }}>
        <div className="profiable-titles-table">
          <div className="titles-table__item" style={{ paddingLeft: "25px" }}>
            Date
          </div>
          <div className="titles-table__item">Project</div>
          <div className="titles-table__item">Investors</div>
          <div className="titles-table__item">Funding state</div>
          <div className="titles-table__item">ROI </div>
          <div className="titles-table__item">Net profit</div>
        </div>

        <div style={{ minHeight: "338px" }}>
          {loadingSkeleton
            ? [...new Array(6)].map((_, i) => <SkeletonInvestment key={i} />)
            : currentCards.map((items, i) => {
                return (
                  <div key={i} className="profiable-table">
                    <div className="table__Date">
                      <div className="table__Date-icon">
                        <Date />
                      </div>
                      <div className="table__Date-title">
                        <div>{items.dateCreated}</div>
                        <div>18:42 PM</div>
                      </div>
                    </div>
                    <div className="profiable-table__Project">
                      <div className="profiable-table__Project-img">
                        <img src={items.pictures[0].url} alt={items.title} />
                      </div>
                      <div className="profiable-table__Project-title">
                        {items.title}
                      </div>
                    </div>

                    <div className="profiable-table__Distribution">
                      <div className="profiable-table__Distribution-icon">
                        <InvestorsGroup />
                      </div>
                      <div className="profiable-table__Distribution-title">
                        {(items.category.transactionId / 100).toFixed(0)}
                      </div>
                    </div>
                    <div className="profiable-table__Funding">
                      <div className="profiable-table__Funding-title">
                        <div className="profiable-table__Funding-title">
                          €124000/<span>€670000</span>
                        </div>
                      </div>
                      <div className="profiable-table__Term-range">
                        <div className="range-castom">
                          <div className="range-castom__track">
                            <div
                              className="range-castom__fill"
                              style={{
                                width: `${items.investmentTable.rangeProcent}%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profiable-table__Return">
                      <div className="profiable-table__Return-icon">
                        <ReturnProcent />
                      </div>
                      <div className="profiable-table__Return-title">
                        <div>€ {items.investmentTable.investment}</div>
                      </div>
                    </div>

                    <div className="profiable-table__Investment">
                      <div className="profiable-table__Investment-icon">
                        <AmountFiat />
                      </div>
                      <div className="profiable-table__Investment-title">
                        <div>
                          € {(items.investmentTable.investment / 3).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>

        <p
          className="h2-title__discription"
          style={{ paddingTop: "20px", textAlign: "end" }}
        >
          Latest actions (Showing {startIndex + 1} to {endIndex} of{" "}
          {data.length})
        </p>
        {!loadingSkeleton && (
          <div className="table-pagination">
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
            />
          </div>
        )}
      </div>
    </Grid>
  );
}
export default ProfitableProjects;
