function Dashboard(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.78393V13.5529C0 13.7601 0.0822837 13.9587 0.22875 14.1052C0.375216 14.2516 0.573866 14.3339 0.781 14.3339H9.369V16.5949H4.079C3.92305 16.5949 3.77349 16.6569 3.66322 16.7672C3.55295 16.8774 3.491 17.027 3.491 17.1829C3.491 17.3389 3.55295 17.4884 3.66322 17.5987C3.77349 17.709 3.92305 17.7709 4.079 17.7709H15.921C15.9982 17.7709 16.0747 17.7557 16.146 17.7262C16.2174 17.6966 16.2822 17.6533 16.3368 17.5987C16.3914 17.5441 16.4347 17.4793 16.4642 17.4079C16.4938 17.3366 16.509 17.2601 16.509 17.1829C16.509 17.027 16.4471 16.8774 16.3368 16.7672C16.2265 16.6569 16.0769 16.5949 15.921 16.5949H10.546V14.3349H19.219C19.3216 14.3349 19.4233 14.3147 19.5181 14.2754C19.6129 14.2361 19.6991 14.1785 19.7716 14.1058C19.8441 14.0332 19.9016 13.947 19.9408 13.8521C19.98 13.7572 20.0001 13.6556 20 13.5529V0.78393C20 0.576796 19.9177 0.378146 19.7712 0.23168C19.6248 0.085214 19.4261 0.00292969 19.219 0.00292969H0.781C0.573866 0.00292969 0.375216 0.085214 0.22875 0.23168C0.0822837 0.378146 0 0.576796 0 0.78393ZM18.824 13.1579H1.176V11.6159H9.412V11.7989C9.41279 11.9544 9.47509 12.1031 9.58528 12.2128C9.69546 12.3224 9.84457 12.3839 10 12.3839C10.0772 12.3839 10.1537 12.3687 10.225 12.3392C10.2964 12.3096 10.3612 12.2663 10.4158 12.2117C10.4704 12.1571 10.5137 12.0923 10.5432 12.0209C10.5728 11.9496 10.588 11.8731 10.588 11.7959V11.6129H18.823L18.824 13.1579ZM1.176 10.4399V1.17593H18.824V10.4399H1.176Z"
        fill="#8186AF"
        className={color}
      />
      <path
        d="M5.22289 3.95394H5.02289C4.46929 3.94545 3.92576 4.10239 3.4619 4.40467C2.99803 4.70695 2.63495 5.14081 2.41915 5.65069C2.20335 6.16057 2.14466 6.72326 2.2506 7.2667C2.35653 7.81014 2.62226 8.30959 3.01376 8.70109C3.40526 9.09259 3.90471 9.35831 4.44814 9.46424C4.99158 9.57017 5.55427 9.51149 6.06414 9.29569C6.57402 9.0799 7.00788 8.71682 7.31017 8.25295C7.61245 7.78909 7.76939 7.24555 7.76089 6.69195V6.51194C7.76089 6.41514 7.72244 6.3223 7.65399 6.25385C7.58554 6.1854 7.4927 6.14695 7.39589 6.14695H5.91589C5.82386 6.14695 5.7356 6.11039 5.67053 6.04531C5.60545 5.98024 5.56889 5.89198 5.56889 5.79995V4.29995C5.56863 4.20827 5.53209 4.12041 5.46726 4.05558C5.40243 3.99075 5.31458 3.9542 5.22289 3.95394ZM6.47489 7.32294C6.37629 7.56847 6.21834 7.78571 6.01518 7.95522C5.81202 8.12473 5.57 8.24122 5.31078 8.29424C5.05156 8.34727 4.78324 8.33518 4.52983 8.25906C4.27643 8.18294 4.04586 8.04516 3.85877 7.85807C3.67168 7.67098 3.53391 7.44041 3.45779 7.187C3.38167 6.9336 3.36958 6.66528 3.4226 6.40606C3.47562 6.14684 3.5921 5.90481 3.76161 5.70165C3.93112 5.49849 4.14837 5.34055 4.39389 5.24195V7.32195L6.47489 7.32294Z"
        fill="#FA544D"
        className={color}
      />
      <path
        d="M6.86241 2.073C6.70646 2.073 6.55691 2.13494 6.44664 2.24522C6.33637 2.35549 6.27441 2.50505 6.27441 2.661V4.853C6.27441 5.00894 6.33637 5.1585 6.44664 5.26878C6.55691 5.37905 6.70646 5.44099 6.86241 5.44099H9.05441C9.21036 5.44099 9.35991 5.37905 9.47018 5.26878C9.58046 5.1585 9.64241 5.00894 9.64241 4.853C9.64215 4.11578 9.34917 3.40882 8.82788 2.88753C8.30659 2.36623 7.59963 2.07326 6.86241 2.073ZM7.45041 4.26499V3.365C7.65363 3.44612 7.83821 3.56775 7.99294 3.72247C8.14766 3.8772 8.26929 4.06178 8.35042 4.26499H7.45041Z"
        fill="#FA544D"
        className={color}
      />
      <path
        d="M11.5951 4.30298H12.4061C12.4546 4.30298 12.5012 4.32225 12.5355 4.35657C12.5698 4.39089 12.5891 4.43744 12.5891 4.48598V5.76997C12.5891 5.81851 12.5698 5.86506 12.5355 5.89938C12.5012 5.9337 12.4546 5.95297 12.4061 5.95297H11.5951C11.5466 5.95297 11.5 5.9337 11.4657 5.89938C11.4314 5.86506 11.4121 5.81851 11.4121 5.76997V4.48598C11.4121 4.43744 11.4314 4.39089 11.4657 4.35657C11.5 4.32225 11.5466 4.30298 11.5951 4.30298Z"
        fill="#FA544D"
        className={color}
      />
      <path
        d="M11.5951 2.48584H12.4061C12.4546 2.48584 12.5012 2.50512 12.5355 2.53944C12.5698 2.57376 12.5891 2.6203 12.5891 2.66884V3.14584C12.5891 3.19437 12.5698 3.24092 12.5355 3.27524C12.5012 3.30956 12.4546 3.32884 12.4061 3.32884H11.5951C11.5466 3.32884 11.5 3.30956 11.4657 3.27524C11.4314 3.24092 11.4121 3.19437 11.4121 3.14584V2.66884C11.4121 2.6203 11.4314 2.57376 11.4657 2.53944C11.5 2.50512 11.5466 2.48584 11.5951 2.48584Z"
        fill="#FA544D"
        className={color}
      />
      <path
        d="M13.9477 4.30298H14.7587C14.8072 4.30298 14.8537 4.32225 14.8881 4.35657C14.9224 4.39089 14.9417 4.43744 14.9417 4.48598V5.76997C14.9417 5.81851 14.9224 5.86506 14.8881 5.89938C14.8537 5.9337 14.8072 5.95297 14.7587 5.95297H13.9477C13.8991 5.95297 13.8526 5.9337 13.8183 5.89938C13.7839 5.86506 13.7646 5.81851 13.7646 5.76997V4.48598C13.7646 4.43744 13.7839 4.39089 13.8183 4.35657C13.8526 4.32225 13.8991 4.30298 13.9477 4.30298Z"
        fill="#FA544D"
        className={color}
      />
      <path
        d="M13.9477 2.48584H14.7587C14.8072 2.48584 14.8537 2.50512 14.8881 2.53944C14.9224 2.57376 14.9417 2.6203 14.9417 2.66884V3.14584C14.9417 3.19437 14.9224 3.24092 14.8881 3.27524C14.8537 3.30956 14.8072 3.32884 14.7587 3.32884H13.9477C13.8991 3.32884 13.8526 3.30956 13.8183 3.27524C13.7839 3.24092 13.7646 3.19437 13.7646 3.14584V2.66884C13.7646 2.6203 13.7839 2.57376 13.8183 2.53944C13.8526 2.50512 13.8991 2.48584 13.9477 2.48584Z"
        fill="#FA544D"
        className={color}
      />
      <path
        d="M16.3002 4.30298H17.1112C17.1597 4.30298 17.2063 4.32225 17.2406 4.35657C17.2749 4.39089 17.2942 4.43744 17.2942 4.48598V5.76997C17.2942 5.81851 17.2749 5.86506 17.2406 5.89938C17.2063 5.9337 17.1597 5.95297 17.1112 5.95297H16.3002C16.2516 5.95297 16.2051 5.9337 16.1708 5.89938C16.1365 5.86506 16.1172 5.81851 16.1172 5.76997V4.48598C16.1172 4.43744 16.1365 4.39089 16.1708 4.35657C16.2051 4.32225 16.2516 4.30298 16.3002 4.30298Z"
        fill="#FA544D"
        className={color}
      />
      <path
        d="M16.3002 2.48584H17.1112C17.1597 2.48584 17.2063 2.50512 17.2406 2.53944C17.2749 2.57376 17.2942 2.6203 17.2942 2.66884V3.14584C17.2942 3.19437 17.2749 3.24092 17.2406 3.27524C17.2063 3.30956 17.1597 3.32884 17.1112 3.32884H16.3002C16.2516 3.32884 16.2051 3.30956 16.1708 3.27524C16.1365 3.24092 16.1172 3.19437 16.1172 3.14584V2.66884C16.1172 2.6203 16.1365 2.57376 16.1708 2.53944C16.2051 2.50512 16.2516 2.48584 16.3002 2.48584Z"
        fill="#FA544D"
        className={color}
      />
      <path
        d="M16.4144 7.00704L15.3864 7.74503C15.3402 7.77762 15.2858 7.79651 15.2293 7.79952C15.1729 7.80252 15.1168 7.78953 15.0674 7.76202L13.9994 7.15404C13.9538 7.12771 13.9021 7.11385 13.8494 7.11385C13.7967 7.11385 13.745 7.12771 13.6994 7.15404L12.1994 8.05403C12.0659 8.13454 11.9698 8.26479 11.9323 8.41612C11.8948 8.56746 11.9189 8.7275 11.9994 8.86104C12.0799 8.99455 12.2101 9.09062 12.3615 9.12813C12.5128 9.16563 12.6729 9.1415 12.8064 9.06104L13.7144 8.51404C13.76 8.48771 13.8117 8.47385 13.8644 8.47385C13.9171 8.47385 13.9688 8.48771 14.0144 8.51404L15.1384 9.15404C15.1878 9.18154 15.2439 9.19454 15.3004 9.19153C15.3568 9.18852 15.4112 9.16962 15.4574 9.13702L17.0984 7.96004C17.1612 7.91502 17.2145 7.85807 17.2553 7.79244C17.2961 7.7268 17.3235 7.65376 17.3361 7.57752C17.3486 7.50127 17.346 7.42329 17.3284 7.34805C17.3108 7.27282 17.2785 7.2018 17.2334 7.13904C17.1421 7.01324 17.0046 6.92871 16.8511 6.90398C16.6977 6.87924 16.5406 6.9163 16.4144 7.00704Z"
        fill="#FA544D"
        className={color}
      />
    </svg>
  );
}

export default Dashboard;
