import React from "react";
import { Link, useLocation } from "react-router-dom";

import "./userInfo.scss";

import IsonUserPhoto from "../../../resources/icon/UserInfo/IsonUserPhoto";
import IconBalance from "../../../resources/icon/UserInfo/IconBalance";
import IconFunded from "../../../resources/icon/UserInfo/IconFunded";
import IconPanding from "../../../resources/icon/UserInfo/IconPanding";
import { ReactComponent as IconLot } from "../../../resources/icon/UserInfo/IconLot.svg";
import { ReactComponent as IconMeccage } from "../../../resources/icon/UserInfo/IconMeccage.svg";
import { ReactComponent as IconSetting } from "../../../resources/icon/UserInfo/IconSetting.svg";

const dataButton = [
  {
    clazz: "user-button",
    componentIcon: IconLot,
    path: "/comparison",
  },
  {
    clazz: "user-button",
    componentIcon: IconSetting,
    path: "/settings",
  },
  {
    clazz: "user-button",
    componentIcon: IconMeccage,
    path: null,
  },
];

function UserInfo({ notificationsVisible, toggleNotifications }) {
  // console.log("props", props);
  const [hoveredButtons, setHoveredButtons] = React.useState([]);

  const { pathname } = useLocation();

  const handleMouseEnter = (index) => {
    setHoveredButtons((prevHoveredButtons) => {
      const updatedHoveredButtons = [...prevHoveredButtons];
      updatedHoveredButtons[index] = true;
      return updatedHoveredButtons;
    });
  };

  const handleMouseLeave = (index) => {
    setHoveredButtons((prevHoveredButtons) => {
      const updatedHoveredButtons = [...prevHoveredButtons];
      updatedHoveredButtons[index] = false;
      return updatedHoveredButtons;
    });
  };

  const creatElement = (title, clazz) => {
    let creatE = React.createElement(title, { className: clazz });
    return creatE;
  };

  // const activeClazz = (i, path) => {
  //   if (pathname === path || hoveredButtons[i]) {
  //     return "hover-user-info";
  //   } else {
  //     return "";
  //   }
  // };
  const activeNotifications =
    notificationsVisible && "hover-user-info hover-svg";

  const activeClazz = (i, path, clazzName) =>
    pathname === path || hoveredButtons[i] ? clazzName : "";

  return (
    <div className="user-info">
      <div className="button-group">
        {dataButton.map(({ clazz, componentIcon, path }, i) => {
          return (
            <Link
              to={path}
              key={i}
              className={`${clazz} ${activeClazz(i, path, "hover-user-info")} ${
                i === 2 && activeNotifications
              }`}
              onMouseEnter={() => handleMouseEnter(i)}
              onMouseLeave={() => handleMouseLeave(i)}
              onClick={i === 2 ? () => toggleNotifications() : null}
            >
              {creatElement(componentIcon, activeClazz(i, path, "hover-svg"))}
            </Link>
          );
        })}
      </div>
      <div className="icon-user-photo">
        <IsonUserPhoto />
      </div>
      <div className="user-name">
        <span className="user-name-text">Laurens Bernstein</span>
      </div>

      <div className="icon-balance">
        <IconBalance />
      </div>
      <div className="icon-funded">
        <IconFunded />
      </div>
      <div className="icon-panding">
        <IconPanding />
      </div>

      <div className="grid-container">
        <div className="name-item">Balance</div>
        <div className="amount-item">120267.09</div>

        <div className="name-item">Funded</div>
        <div className="amount-item">369092.02</div>

        <div className="name-item">Pending</div>
        <div className="amount-item">5647.00</div>
      </div>
    </div>
  );
}

export default UserInfo;
