import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import "./investment.scss";
import { ButtonExit } from "../../sidebar/SidebarWrapper";
import { ReactComponent as LogoModal } from "../../../resources/icon/LogoModal.svg";
import { ReactComponent as InvestmentIcon } from "../../../resources/icon/PopUp/Investment.svg";

function Investment(props) {
  const [bitcoinAmount, setBitcoinAmount] = useState("0.00");
  const [euroAmount, setEuroAmount] = useState("0");

  const handleInputChange = (event) => {
    let euroValue = event.target.value;
    euroValue = euroValue.replace(/€/g, "").trim();
    euroValue = euroValue.replace(/\D/g, "");

    const formattedValue = euroValue ? `€ ${euroValue}` : "€ ";
    setValue(formattedValue);
    setEuroAmount(euroValue);

    if (euroValue) {
      const parsedEuroValue = parseFloat(euroValue);
      const bitcoinAmount = parsedEuroValue / 27918.85;
      setBitcoinAmount(bitcoinAmount.toFixed(8));
    } else {
      setBitcoinAmount("");
    }
  };

  const changeModel = () => {
    props.handleClose();
    props.handleOpenNext();
  };

  const [value, setValue] = useState("");
  const [isInputActive, setInputActive] = useState(false);

  const handleInputFocus = () => {
    if (!isInputActive) {
      setValue("€ ");
      setInputActive(true);
    }
  };

  return (
    <Box className="s-modal">
      <div className="s-modal__top">
        <div className="s-modal__logo">
          <LogoModal />
        </div>
        <button onClick={props.handleClose} className="circle-bg">
          <ButtonExit />
        </button>
      </div>
      <h2 className="h2-title s-modal__title">Investment</h2>
      <div className="s-modal__main-content">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <label className="input-name">Investment amount</label>
            <label className="input-label">
              <div className="input-icon">
                <InvestmentIcon />
              </div>
              <input
                type="text"
                placeholder={isInputActive ? "" : "€  0.00"}
                className="input-calculator s-modal__input-modal inp-placeholder"
                value={value}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </label>
            <div className="info" style={{ marginTop: "30px" }}>
              <div className="left-title">Crypto rate</div>
              <div className="rigth-title">€1.00 ≈ 0.00003582 BTC</div>
            </div>
            <div className="info">
              <div className="left-title">Amount</div>
              <div className="rigth-title">
                €{euroAmount}/{`${bitcoinAmount} BTC`}
              </div>
            </div>
            <button
              className={`button-general s-modal__bt ${
                !bitcoinAmount ? "disable-bt" : ""
              }`}
              disabled={!bitcoinAmount}
              onClick={changeModel}
            >
              Proceed
            </button>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default Investment;
