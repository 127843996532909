import React from "react";
import { Field, useFormikContext } from "formik";

import { Grid } from "@mui/material";

import "./security.scss";
import AntSwitch from "../../fewSimpleComponents/switch/AntSwitch";
import { ReactComponent as FocusedInput } from "../../../resources/icon/SettingProfile/FocusedInput.svg";
import { ReactComponent as PasswordBig } from "../../../resources/icon/SettingProfile/PasswordBig.svg";
import { ReactComponent as PasswordSmall } from "../../../resources/icon/SettingProfile/PasswordSmall.svg";
import { ReactComponent as PasswordSmallClose } from "../../../resources/icon/SettingProfile/PasswordSmallClose.svg";
import { ReactComponent as Attention } from "../../../resources/icon/SettingProfile/Attention.svg";
import BpCheckbox from "../../fewSimpleComponents/checkbox/checkbox";

const filedProps = ["confirmPassword", "password", "oldPassword"];

function Security(props) {
  const inputRefs = React.useRef([null]);
  const { errors, touched, getFieldProps } = useFormikContext();
  const showError =
    filedProps.some((field) => field in errors) &&
    Object.keys(errors).length !== 0
      ? true
      : false;

  const handleIconClick = (index) => {
    inputRefs.current[index].focus();
  };

  const styleValidInput = (errors, touched, getFieldProp) => {
    if (errors?.includes("Invalid") && touched) {
      return "іnvalid";
    } else if (
      errors === undefined &&
      touched &&
      getFieldProps(getFieldProp).value !== ""
    ) {
      return "valid";
    }
  };

  return (
    <Grid item xs={12} sm={12} md={6}>
      <div className="box box-col">
        <div className="box-title">Security</div>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="data-input">
              <div
                className={`data-input__wrapper ${styleValidInput(
                  errors.oldPassword,
                  touched.oldPassword,
                  "oldPassword"
                )}`}
              >
                <div className="data-input__left-icon">
                  <PasswordBig />
                </div>
                <div className="data-input__input-general">
                  <Field
                    type="password"
                    name="oldPassword"
                    innerRef={(el) => (inputRefs.current[0] = el)}
                  />
                  <label>Old password</label>
                  <div className="attention-label attention-label__old-password">
                    <PasswordSmallClose />
                  </div>
                </div>
                <div
                  className="data-input__icon-right"
                  onClick={() => handleIconClick(0)}
                >
                  <FocusedInput />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="data-input">
              <div
                className={`data-input__wrapper ${styleValidInput(
                  errors.password,
                  touched.password,
                  "password"
                )}`}
              >
                <div className="data-input__left-icon">
                  <PasswordBig />
                </div>
                <div className="data-input__input-general">
                  <Field
                    type="text"
                    name="password"
                    innerRef={(el) => (inputRefs.current[1] = el)}
                  />
                  <label>New password</label>
                  <div className="attention-label attention-label__new-password">
                    <PasswordSmall />
                  </div>
                </div>
                <div
                  className="data-input__icon-right"
                  onClick={() => handleIconClick(1)}
                >
                  <FocusedInput />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="data-input">
              <div
                className={`data-input__wrapper ${styleValidInput(
                  errors.confirmPassword,
                  touched.confirmPassword,
                  "confirmPassword"
                )}`}
              >
                <div className="data-input__left-icon">
                  <PasswordBig />
                </div>
                <div className="data-input__input-general">
                  <Field
                    type="text"
                    name="confirmPassword"
                    innerRef={(el) => (inputRefs.current[2] = el)}
                  />
                  <label>Confirm new password</label>
                  <div className="attention-label attention-label__confirm-new-password">
                    <PasswordSmall />
                  </div>
                </div>
                <div
                  className="data-input__icon-right"
                  onClick={() => handleIconClick(2)}
                >
                  <FocusedInput />
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} mb={-2}>
            <div className="additional-setting d-flex">
              <AntSwitch />
              <div className="taxt-setting">
                Two-factor authentication by email
              </div>
              <div className="taxt-setting__attention">
                <Attention />
              </div>
            </div>
            <div className="d-flex">
              <div className="taxt-setting__description-attention">
                <Attention />
              </div>
              <div className="taxt-setting__description">
                Simple and reliable 2FA check protects your account from being
                modified without confirmation by email. Enabling this
                protection, no one can change your account or billing
                information.
              </div>
            </div>

            <div className="additional-setting">
              <div className="d-flex">
                <AntSwitch />
                <div className="taxt-setting">Company news</div>
              </div>

              <div className="setting-checkbox d-flex">
                <div className="setting-checkbox__item d-flex">
                  <div>
                    <BpCheckbox />
                  </div>
                  <p className="setting-checkbox__text">Company news</p>
                </div>

                <div className="setting-checkbox__item d-flex">
                  <div>
                    <BpCheckbox />
                  </div>
                  <p className="setting-checkbox__text">Projects news</p>
                </div>

                <div className="setting-checkbox__item d-flex">
                  <div>
                    <BpCheckbox />
                  </div>
                  <p className="setting-checkbox__text">Dlendo reports</p>
                </div>
              </div>

              <div className="d-flex">
                <AntSwitch />
                <div className="taxt-setting">I want to delete my account</div>
                <div className="taxt-setting__attention">
                  <Attention />
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div className="taxt-setting__description-attention">
                <Attention />
              </div>
              <div className="taxt-setting__description">
                I want to get information of the news to my email
              </div>
            </div>
          </Grid>

          {showError && (
            <Grid item xs={12} mt={-1} mb={-2}>
              <div className="error-description" style={{ marginTop: "25px" }}>
                {Object.entries(errors)
                  .filter(([fieldName]) => filedProps.includes(fieldName))
                  .map(([fieldName, errorMessage], i) => (
                    <ul key={i} className="error-description__error-list">
                      <li className="error-description__error-item">
                        {errorMessage}
                      </li>
                    </ul>
                  ))}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </Grid>
  );
}

export default Security;
