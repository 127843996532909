import { useDispatch } from "react-redux";
import { filter } from "../../store/itemProjectSlice/itemProjectSlice";

import { Carousel } from "react-responsive-carousel";
import Slider from "../../components/fewSimpleComponents/slider/Slider";
import Grow from "@mui/material/Grow";

import "./productCard.scss";

import { Link } from "react-router-dom";

import { ReactComponent as Weight } from "../../resources/icon/Dashboard/Weight.svg";
import { ReactComponent as New } from "../../resources/icon/Dashboard/New.svg";
import { ReactComponent as Fire } from "../../resources/icon/Dashboard/Fire.svg";
import { ReactComponent as Distribution } from "../../resources/icon/Dashboard/Distribution.svg";
import { ReactComponent as Location } from "../../resources/icon/Dashboard/Location.svg";

function ProductCard(props) {
  const dispatch = useDispatch();

  const { id, title, description, pictures, procent, location } = props;

  const addDescription =
    description?.length < 55
      ? description + "A charming cottage nestled in the countryside"
      : description;

  const onChooseProject = (id) => {
    dispatch(filter(id));
  };

  return (
    <Grow
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      {...(true ? { timeout: 1000 } : {})}
    >
      <div className="card">
        <div className="card__carousel">
          {" "}
          <Carousel
            showArrows={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
          >
            {pictures?.map((picture, i) => {
              return (
                <div key={i}>
                  <div className="card__carousel-item">
                    <Link
                      onClick={() => {
                        onChooseProject(id);
                        document.getElementById("Project-details");
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      to={`/item/${id}`}
                      className="card__carousel-img"
                      style={{
                        backgroundImage: `url(${picture.url})`,
                      }}
                      onError={(event) => {
                        event.target.style.backgroundImage = `url('https://aelektro.ru/images/nofoto.png')`;
                      }}
                    ></Link>
                  </div>
                  <p className="legend">{title}</p>
                </div>
              );
            })}
          </Carousel>
          <div className="box card__content">
            <div className="d-flex card__buttons">
              <div className="card__wrapper">
                <div className="d-flex">
                  {" "}
                  <button className="circle-bg">
                    <Fire />
                  </button>
                  <button className="circle-bg">
                    <New />
                  </button>
                </div>
                <button className="circle-bg card__button">
                  <Weight />
                </button>
              </div>
            </div>

            <Slider />
            <div className="conditions">
              <div className="condition">
                <div className="conditions-sum">{procent?.return}% p.a.</div>

                <p className="conditions-name">Return</p>
              </div>
              <div className="condition">
                <div className="conditions-sum">{procent?.duration} Month</div>
                <div className="conditions-name">Duration</div>
              </div>
            </div>

            <div className="card__devider"></div>

            <div className="details-row">
              <div className="details-name">
                <Location style={{ position: "relative", left: "-1px" }} />
                <div className="detail-name">Location</div>
              </div>
              <div className="detail">
                <a href={location?.name} style={{ color: "#4E7CFF" }}>
                  {location?.name}
                </a>
              </div>
            </div>

            <div className="details-row">
              <div className="details-name">
                <Distribution style={{ position: "relative", left: "-2px" }} />
                <div
                  className="detail-name"
                  style={{ position: "relative", left: "-2px" }}
                >
                  Distribution
                </div>
              </div>
              <div className="detail">Yearly</div>
            </div>

            <div className="card__devider"></div>
            <p className="card__discription">
              {addDescription?.slice(0, 50) +
                `${addDescription?.length > 49 ? "..." : ""}`}
            </p>
          </div>
        </div>
      </div>
    </Grow>
  );
}

export default ProductCard;
