import React from "react";
import { Grid } from "@mui/material";
import WalletTitleChart from "../../charts/walletTitleChart/WalletTitleChart";
import TransactionsWallet from "../../table/transactionsWallet/TransactionsWallet";

function MyWallet(props) {
  const timeoutForSkeleton = 3000;

  const [value, setValue] = React.useState(false);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue(true);
    }, timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container spacing={3}>
      <WalletTitleChart
        timeoutForSkeleton={timeoutForSkeleton}
        timeoutForSkeletonValue={value}
      />
      <TransactionsWallet timeoutForSkeleton={timeoutForSkeleton} />
    </Grid>
  );
}

export default MyWallet;
