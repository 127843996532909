import { useState } from "react";
import { Element } from "react-scroll";
import Grid from "@mui/material/Grid";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

import "./financeStructure.scss";

const data = {
  labels: ["Bank loan", "Crowdinvesting", "Equity"],
  datasets: [
    {
      type: "doughnut",
      label: "Pie",
      data: [6530000, 1000000, 790000],
      backgroundColor: ["#FA544D", "#CC4749", "#863443"],
      hoverOffset: 10,
      borderWidth: 0,
    },
  ],
};

function FinanceStructure(props) {
  const [hoverData, setHoverData] = useState({
    label: "Bank loan",
    value: "6530000",
  });

  const options = {
    cutout: 67,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    onHover: function (event, elements) {
      if (elements.length) {
        var label = data.labels[elements[0].index];
        var value = data.datasets[0].data[elements[0].index];
        setHoverData({ label, value });
      }
    },
    animation: {
      animateRotate: true, // Enable rotation animation
      animateScale: true, // Enable scaling animation
      duration: 50, // Set the duration of the animation in milliseconds
      easing: "easeOutQuart", // Set the easing function for the animation
    },
  };

  return (
    <Element name="Market overview">
      <h2 className="h2-title">Finance structure</h2>
      <div className="box">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={6} className="d-flex-center">
            <div>
              <div className="finance-structure">
                <div className="finance-structure__list">
                  <div className="finance-structure__wrapper">
                    <div className="finance-structure__number rectangle-bg">
                      1
                    </div>
                    <div className="finance-structure__item-wrapper rectangle-bg">
                      <div className="finance-structure__title">Bank loan</div>
                      <div className="finance-structure__money">
                        € 6,530,000
                      </div>
                    </div>
                  </div>
                  <div className="finance-structure__wrapper">
                    <div className="finance-structure__number rectangle-bg">
                      2
                    </div>
                    <div className="finance-structure__item-wrapper rectangle-bg">
                      <div className="finance-structure__title">
                        Crowdinvesting
                      </div>
                      <div className="finance-structure__money">
                        € 1,000,000
                      </div>
                    </div>
                  </div>
                  <div className="finance-structure__wrapper">
                    <div className="finance-structure__number rectangle-bg">
                      3
                    </div>
                    <div className="finance-structure__item-wrapper rectangle-bg">
                      <div className="finance-structure__title">Equity</div>
                      <div className="finance-structure__money">€ 790,000</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            style={{ position: "relative" }}
            className="d-flex-center"
          >
            <div className="doughnut__wrapper">
              <div className="subtraction__circle">
                <div className="subtraction"></div>
                <div className="subtraction__wrapper-point-one">
                  <div className="rectangle-bg subtraction__point">1</div>
                  <div className="subtraction__line-one"></div>
                </div>
                <div className="subtraction__wrapper-point-two">
                  <div className="rectangle-bg subtraction__point">2</div>
                  <div className="subtraction__line-two"></div>
                </div>
                <div className="subtraction__wrapper-point-three">
                  <div className="rectangle-bg subtraction__point">3</div>
                  <div className="subtraction__line-three"></div>
                </div>{" "}
                <div className="subtraction__wrapper">
                  <h4 className="subtraction__title">{hoverData.label}:</h4>
                  <h4 className="subtraction__amount">€{hoverData.value}</h4>
                </div>
              </div>
              <div className="doughnut-circle"></div>
              <div className="doughnut-circle__border-radius"></div>
              <div className="doughnut-circle__border-radius-into"></div>

              <div className="doughnut">
                <Doughnut data={data} options={options} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Element>
  );
}

export default FinanceStructure;
