import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setCatecoryById } from "../../../store/filterMap/filterMapSlice";
import {
  filteredByAssetClass,
  filteredByStatus,
  filteredByDuration,
  filteredByReturnData,
  filteredSortByAllData,
} from "../../../store/filterCardsSlice/filterCardsSlice";

import { Pagination, Stack } from "@mui/material";

import "./home.scss";
import NewAndHotProjects from "../../newAndHotProjects/NewAndHotProjects";
import MapComponent from "../../map/MapComponent";
import InputSelectById from "../../fewSimpleComponents/inputSelect/InputSelectById";
import FilterCardsFiveCategory from "./filterCardsFiveCategory/FilterCardsFiveCategory";

const placeholders = [
  ["Select the option"],
  ["Return"],
  ["Duration"],
  ["Asset class"],
  ["Status"],
];
const filterMapNames = ["all projects", "hot projects", "new projects"];
const assetClass = ["residential", "commercial"];
const status = [
  "open for investments",
  "coming soon",
  "funded successfully",
  "already refunded",
];
const duration = ["12-24 months", "24-36 months", "> 36 months"];
const returnData = ["< 5% p.a.", "5-6% p.a.", "6-7% p.a.", "> 7% p.a."];
const filterDataNames = [
  "date Created",
  "alphabet (A to Z)",
  "new projects",
  "hot projects",
];

const cardsPerPage = 8;

function Home(props) {
  const data = useSelector((state) => state.itemProjectSlice.initialData);
  const dataCardsFiltered = useSelector(
    (state) => state.filterCardsSlice.dataCarrently
  );
  const {
    assetClassPayload,
    statusPayload,
    durationPayload,
    returnDataPayload,
    sortByAllDataPayload,
  } = useSelector((state) => state.filterCardsSlice);
  const dispatch = useDispatch();

  const [trueCount, setTrueCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadedCards, setLoadedCards] = useState(cardsPerPage);

  useMemo(() => {
    const active =
      assetClassPayload === "Asset class" || assetClassPayload === ""
        ? false
        : true;
    const activeTwo =
      statusPayload === "Status" || statusPayload === "" ? false : true;
    const activeThree =
      durationPayload === "Duration" || durationPayload === "" ? false : true;
    const activeFour =
      returnDataPayload === "Return" || returnDataPayload === "" ? false : true;
    const activeFive =
      sortByAllDataPayload === "Select the option" ||
      sortByAllDataPayload === ""
        ? false
        : true;

    const arrActives = [active, activeTwo, activeThree, activeFour, activeFive];

    let count = arrActives.reduce(
      (count, value) => count + (value === true),
      0
    );

    setTrueCount(count);
  }, [
    assetClassPayload,
    statusPayload,
    durationPayload,
    returnDataPayload,
    sortByAllDataPayload,
  ]);

  const cancelData = () => {
    dispatch(filteredByAssetClass("Asset class"));
    dispatch(filteredByStatus("Status"));
    dispatch(filteredByDuration("Duration"));
    dispatch(filteredByReturnData("Return"));
    dispatch(filteredSortByAllData("Select the option"));
    setTrueCount(0);
  };

  useEffect(() => {
    setLoadedCards(cardsPerPage);
  }, [dataCardsFiltered]);

  let totalCards = data.length;

  let pageCount = Math.ceil(totalCards / cardsPerPage);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleLoadMore = () => {
    setLoadedCards((prevLoadedCards) => prevLoadedCards + 4);
    totalCards = -4;
  };

  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + loadedCards;

  useEffect(() => {
    if (loadedCards > cardsPerPage) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [loadedCards]);

  return (
    <div>
      <NewAndHotProjects data={data} />
      <div className="home-map">
        <div className="home-map__wrapper">
          <h2 className="h2-title">Dlendo projects map</h2>
          <div className="home-map__label">
            <div className="home-map__label-name">Sort By:</div>
            <InputSelectById
              dataButton={filterMapNames}
              placeHolder={placeholders[0]}
              action={setCatecoryById}
            />
          </div>
        </div>

        <div className="map__wrapper">
          <MapComponent data={data} />
        </div>
        <h2 className="h2-title">All projects</h2>
        <div className="all-select">
          <button
            onClick={cancelData}
            className={`all-select__amount ${
              trueCount > 0 ? "active-amount" : ""
            }`}
          >
            <div className="all-select__bt-title">Filter ({trueCount})</div>
            <div className="all-select__divider"></div>
            <div className="all-select__bt-cancel"></div>
          </button>
          <InputSelectById
            dataButton={returnData}
            placeHolder={placeholders[1]}
            action={filteredByReturnData}
            active={returnDataPayload}
          />
          <InputSelectById
            dataButton={duration}
            placeHolder={placeholders[2]}
            action={filteredByDuration}
            active={durationPayload}
          />
          <InputSelectById
            dataButton={assetClass}
            placeHolder={placeholders[3]}
            action={filteredByAssetClass}
            active={assetClassPayload}
          />
          <InputSelectById
            dataButton={status}
            placeHolder={placeholders[4]}
            action={filteredByStatus}
            active={statusPayload}
          />
          <div className="home-map__label">
            <div className="home-map__label-name">Sort By:</div>
            <InputSelectById
              dataButton={filterDataNames}
              placeHolder={placeholders[0]}
              action={filteredSortByAllData}
              active={sortByAllDataPayload}
            />
          </div>
        </div>

        <div className="product-card all-cards">
          <Stack spacing={2}>
            <FilterCardsFiveCategory
              startIndex={startIndex}
              endIndex={endIndex}
            />

            <div className="all-cards__pagination-and-button">
              <div style={{ width: "212px", height: "10px" }}></div>
              {loadedCards < totalCards && (
                <button
                  className="all-cards__button"
                  variant="outlined"
                  onClick={handleLoadMore}
                >
                  Load more projects (4)
                </button>
              )}
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={handleChangePage}
                variant="outlined"
              />
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default Home;
