import Grid from "@mui/material/Grid";
import HoverRating from "../../../fewSimpleComponents/hoverRating/HoverRating";
import { Fragment } from "react";
import { Element } from "react-scroll";

import "./dlendoRating.scss";

const data = [
  {
    category: "Location",
    assessment: "Primary",
    points: 1,
  },
  {
    category: "Vacancy",
    assessment: "Vacant",
    points: 3,
  },
  {
    category: "Development Stage",
    assessment: "New Construction",
    points: 3,
  },
  {
    category: "Track Record",
    assessment: "50-249 million developed",
    points: 2,
  },
  {
    category: "Asset Type",
    assessment: "Residential",
    points: 0,
  },
  {
    category: "Duration",
    assessment: "Up to 18 months",
    points: 1,
  },
  {
    category: "Existing Bank Financing",
    assessment: "Yes",
    points: 3,
  },
  {
    category: "Pre Sales",
    assessment: ">20%",
    points: 2,
  },
];

function DlendoRating(props) {
  const clazzPoint = (points) => {
    switch (points) {
      case 0:
        return "rating-three";
      case 1:
        return "rating-three-one";
      case 2:
        return "rating-three-two";
      case 3:
        return "rating-three-three";
      default:
        return "rating-three";
    }
  };

  function calculatePoints(data) {
    const totalPoints = data.reduce((acc, curr) => acc + curr.points, 0);
    return totalPoints;
  }

  return (
    <Element name="Dlendo rating">
      <h2 className="h2-title">Dlendo rating</h2>
      <div className="box">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="dlendo-rating">
              <HoverRating />
              <div className="dlendo-rating__container">
                <div className="dlendo-rating__info">
                  <div className="dlendo-rating__mark">E</div>
                  <div className="dlendo-rating__text">0-3</div>
                </div>
                <div className="dlendo-rating__info">
                  <div
                    className="dlendo-rating__mark"
                    style={{ color: "#BEA206" }}
                  >
                    D
                  </div>
                  <div className="dlendo-rating__text">4-7</div>
                </div>
                <div className="dlendo-rating__info">
                  <div
                    className="dlendo-rating__mark"
                    style={{ color: "#00E1FF" }}
                  >
                    C
                  </div>
                  <div className="dlendo-rating__text">8-11</div>
                </div>
                <div className="dlendo-rating__info">
                  <div
                    className="dlendo-rating__mark"
                    style={{ color: "#007FFF" }}
                  >
                    B
                  </div>
                  <div className="dlendo-rating__text">11-14</div>
                </div>
                <div className="dlendo-rating__info">
                  <div
                    className="dlendo-rating__mark"
                    style={{ color: "#06BE30" }}
                  >
                    A
                  </div>
                  <div className="dlendo-rating__text">15-20</div>
                </div>
              </div>
            </div>

            <div className="dlendo-rating__table">
              <div className="dlendo-rating__head">
                <div className="dlendo-rating__row-head">Category </div>
                <div className="dlendo-rating__row-head">Assessment</div>
                <div className="dlendo-rating__row-head head-right">Points</div>
              </div>

              <div className="dlendo-rating__list">
                {data.map(({ category, assessment, points }, i) => {
                  return (
                    <Fragment key={i}>
                      <div className="dlendo-rating__row-body first-child-row">
                        {category}
                      </div>
                      <div className="dlendo-rating__row-body">
                        {assessment}
                      </div>
                      <div className="dlendo-rating__row-body last-child-row">
                        {" "}
                        <div className={clazzPoint(points)}>
                          <div className="rating-three__item item_first"></div>
                          <div className="rating-three__item item_second"></div>
                          <div className="rating-three__item item_third"></div>
                        </div>
                        <div>{points}</div>
                      </div>
                    </Fragment>
                  );
                })}
                <div className="dlendo-rating__row-body first-child-row">
                  Location
                </div>
                <div className="dlendo-rating__row-body">
                  Existing Bank Financing
                </div>
                <div className="dlendo-rating__row-body last-child-row">
                  {" "}
                  <div className="rating-three-three">
                    <div className="rating-three__item item_first"></div>
                    <div className="rating-three__item item_second"></div>
                    <div className="rating-three__item item_third"></div>
                  </div>
                  <div>2</div>
                </div>
              </div>

              <div className="dlendo-rating__footer">
                <div className="dlendo-rating__row-footer">Total:</div>
                <div className="dlendo-rating__row-footer"></div>
                <div className="dlendo-rating__row-footer">
                  {calculatePoints(data)}
                </div>
              </div>
            </div>

            <p className="style-text">
              <span style={{ color: "#FA544D" }}>Dlendo Ratings</span> are for
              informational purposes only. Real estate projects may fail. In the
              worst case, even the total loss of the invested capital can occur.
              You should therefore only invest funds you can afford to lose.
              Moreover, funds should be spread across various projects
              (diversification). Please note the respective documents for each
              project as well as the Risk Notice.
            </p>
          </Grid>
        </Grid>
      </div>
    </Element>
  );
}

export default DlendoRating;
