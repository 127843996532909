import React from "react";
import { Grid } from "@mui/material";

import "./myPortfolio.scss";
import MyKeyPerformance from "../../charts/myKeyPerformance/MyKeyPerformance";
import Diversifiaction from "../../charts/diversifiaction/Diversifiaction";
import Income from "../../charts/income/Income";
import Investments from "../../table/investments/Investments";
import Accruals from "../../table/accruals/Accruals";

function MyPortfolio(props) {
  const timeoutForSkeleton = 3000;
  return (
    <Grid container spacing={2}>
      <MyKeyPerformance timeoutForSkeleton={timeoutForSkeleton} />
      <Diversifiaction timeoutForSkeleton={timeoutForSkeleton} />
      <Income timeoutForSkeleton={timeoutForSkeleton} />
      <Investments timeoutForSkeleton={timeoutForSkeleton} />
      <Accruals timeoutForSkeleton={timeoutForSkeleton} />
    </Grid>
  );
}

export default MyPortfolio;
