import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import Map from "./Map";
import { useSelector } from "react-redux";

import "./map.scss";

// const API_KEY = process.env.REACT_APP_API_KEY;
const API_KEY = "AIzaSyCGHkQ9V1znGQGIgL9qqp9aooD0Pd1i0Oo";

const containerStyle = {
  width: "100%",
  height: "424px",
};

const libraries = ["places"];

function Location({ data }) {
  const nameOfCatecory = useSelector((state) => state?.filterMapSlice?.value);
  let filteredData = data;
  filteredData = data?.filter((item) => {
    if (nameOfCatecory === "all projects" || item.length === 1) {
      return item;
    } else {
      return item?.category.mapBySort === nameOfCatecory;
    }
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
    libraries,
  });

  return (
    <>
      {isLoaded ? (
        <>
          <Map containerStyle={containerStyle} data={filteredData} />
        </>
      ) : (
        <h2>Error</h2>
      )}
    </>
  );
}

export default Location;
