import React, { useState, createElement, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Link as ScrollLink, scroller } from "react-scroll";

import { useLocation } from "react-router-dom";

import "./sidebarInside.scss";

import { ReactComponent as ProjectDetails } from "../../../resources/icon/SidebarInside/ProjectDetails.svg";
import { ReactComponent as Calculator } from "../../../resources/icon/SidebarInside/Calculator.svg";
import { ReactComponent as ProjectMilestones } from "../../../resources/icon/SidebarInside/ProjectMilestones.svg";
import { ReactComponent as ProjectNews } from "../../../resources/icon/SidebarInside/ProjectNews.svg";
import { ReactComponent as ProjectPresentation } from "../../../resources/icon/SidebarInside/ProjectPresentation.svg";
import { ReactComponent as InterestRates } from "../../../resources/icon/SidebarInside/InterestRates.svg";
import { ReactComponent as DealHighlights } from "../../../resources/icon/SidebarInside/DealHighlights.svg";
import { ReactComponent as Documents } from "../../../resources/icon/SidebarInside/Documents.svg";
import { ReactComponent as InvestmentCase } from "../../../resources/icon/SidebarInside/InvestmentCase.svg";
import { ReactComponent as ProjectCoasting } from "../../../resources/icon/SidebarInside/ProjectCoasting.svg";
import { ReactComponent as MarketOverview } from "../../../resources/icon/SidebarInside/MarketOverview.svg";
import { ReactComponent as DlendoRating } from "../../../resources/icon/SidebarInside/DlendoRating.svg";
import { ReactComponent as Location } from "../../../resources/icon/SidebarInside/Location.svg";
import { ButtonExit } from "../SidebarWrapper";

const dataButton = [
  {
    title: "Project details",
    componentIcon: ProjectDetails,
  },
  {
    title: "Calculator",
    componentIcon: Calculator,
  },
  {
    title: "Project milestones",
    componentIcon: ProjectMilestones,
  },
  {
    title: "Project news",
    componentIcon: ProjectNews,
  },
  {
    title: "Project presentation",
    componentIcon: ProjectPresentation,
  },
  {
    title: "Interest rates",
    componentIcon: InterestRates,
  },
  {
    title: "Deal highlights",
    componentIcon: DealHighlights,
  },
  {
    title: "Documents",
    componentIcon: Documents,
  },
  {
    title: "Investment case",
    componentIcon: InvestmentCase,
  },
  {
    title: "Project coasting",
    componentIcon: ProjectCoasting,
  },
  {
    title: "Market overview",
    componentIcon: MarketOverview,
  },
  {
    title: "Dlendo rating",
    componentIcon: DlendoRating,
  },
  {
    title: "Location",
    componentIcon: Location,
  },
];

function SidebarInsade(props) {
  const [hoveredButtons, setHoveredButtons] = useState([]);

  const { pathname } = useLocation();

  const handleMouseEnter = (index) => {
    setHoveredButtons((prevHoveredButtons) => {
      const updatedHoveredButtons = [...prevHoveredButtons];
      updatedHoveredButtons[index] = true;
      return updatedHoveredButtons;
    });
  };

  const handleMouseLeave = (index) => {
    setHoveredButtons((prevHoveredButtons) => {
      const updatedHoveredButtons = [...prevHoveredButtons];
      updatedHoveredButtons[index] = false;
      return updatedHoveredButtons;
    });
  };

  const creatElement = (title, clazz) => {
    let creatE = createElement(title, { className: clazz });
    return creatE;
  };

  const activeSidebarInside = pathname.includes("/item/");

  return (
    <>
      <BottomMenu
        hoveredButtons={hoveredButtons}
        creatElement={creatElement}
        activeSidebarInside={activeSidebarInside}
      />
      <div
        className="sidebar-inside"
        style={!activeSidebarInside ? { transform: "translateX(-100%)" } : null}
      >
        <div className="sidebar-inside__fixed">
          <h2 className="title">Project details</h2>
          <div className="devider-title"></div>
          <div className="nav-link">
            Dashboard <Arrow />
            Project details
          </div>
          <div className="devider-link">
            <div></div>
          </div>
          <div className="container-list">
            <ul className="list-item">
              {dataButton.map(({ title, componentIcon }, i) => {
                const nameC = componentIcon;
                return (
                  <ScrollLink
                    key={i}
                    spy={true}
                    to={title}
                    smooth={true}
                    offset={-20}
                    duration={1000}
                    activeClass="item-hover"
                    className={`item ${hoveredButtons[i] ? "item-hover" : ""}`}
                    onMouseEnter={() => handleMouseEnter(i)}
                    onMouseLeave={() => handleMouseLeave(i)}
                  >
                    <div
                      className={`position ${hoveredButtons[i] ? "hover" : ""}`}
                    >
                      {creatElement(
                        nameC,
                        hoveredButtons[i] ? "hover-svg" : ""
                      )}
                    </div>
                    {title}
                  </ScrollLink>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default SidebarInsade;

function BottomMenu(props) {
  const [activeIndexClass, setActiveIndexClass] = useState(null);

  const { key } = useLocation();

  const handleSetActive = (index) => {
    setActiveIndexClass(index);
  };

  const [visibleBtn, setVisibleBtn] = useState(false);

  const toggleBottomMenu = () => {
    setVisibleBtn(!visibleBtn);
  };

  React.useEffect(() => {
    setTimeout(() => setVisibleBtn(false), 300);
  }, [key]);

  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrev = () => {
    if (activeIndex === 0) {
      setActiveIndex(0);
    } else {
      setActiveIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      Math.min(prevIndex + 1, dataButton.length - 1)
    );
  };

  useEffect(() => {
    scrollToActiveElement();
    // eslint-disable-next-line
  }, [activeIndex]);

  const offSet = -100;

  const scrollToActiveElement = () => {
    scroller.scrollTo(dataButton[activeIndex].title, {
      duration: 1000,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: offSet,
    });
  };

  const { creatElement, activeSidebarInside } = props;
  return (
    <>
      <div
        className={`list-button ${!visibleBtn ? "" : "list-button__hidden"}`}
      >
        <Grid container spacing={0}>
          {dataButton.slice(0, 12).map(({ title, componentIcon }, i) => {
            const nameC = componentIcon;

            if (i % 2) {
              const activeClassI = activeIndexClass === i;
              return (
                <Grid item xs={6} sm={6} key={i}>
                  <ScrollLink
                    spy={true}
                    to={title}
                    smooth={true}
                    offset={offSet}
                    duration={1000}
                    activeClass="item-hover"
                    className="list-button__wrapper"
                    onSetActive={() => handleSetActive(i)}
                    onClick={() => setTimeout(() => setVisibleBtn(false), 200)}
                  >
                    <div
                      className={`circle-bg ${
                        activeClassI ? "list-button__active" : ""
                      }`}
                    >
                      {creatElement(nameC, activeClassI ? "hover-svg" : "")}
                    </div>
                    <div
                      className={`list-button__item ${
                        activeClassI ? "item-hover" : ""
                      }`}
                    >
                      {title}
                    </div>
                  </ScrollLink>
                </Grid>
              );
            } else {
              var activeClassI = activeIndexClass === i;
              return (
                <Grid item xs={6} sm={6} key={i}>
                  <ScrollLink
                    spy={true}
                    to={title}
                    smooth={true}
                    offset={offSet}
                    duration={1000}
                    activeClass="item-hover"
                    className="list-button__wrapper"
                    onSetActive={() => handleSetActive(i)}
                    onClick={() => setTimeout(() => setVisibleBtn(false), 200)}
                  >
                    <div
                      className={`circle-bg ${
                        activeClassI ? "list-button__active" : ""
                      }`}
                    >
                      {creatElement(nameC, activeClassI ? "hover-svg" : "")}
                    </div>
                    <div
                      className={`list-button__item ${
                        activeClassI ? "item-hover" : ""
                      }`}
                    >
                      {title}
                    </div>
                  </ScrollLink>
                </Grid>
              );
            }
          })}
          <Grid item xs={12} sm={12}>
            <ScrollLink
              spy={true}
              to={dataButton[12].title}
              smooth={true}
              offset={offSet}
              duration={1000}
              activeClass="item-hover"
              className="list-button__wrapper"
              onSetActive={() => handleSetActive(12)}
              onClick={() => setTimeout(() => setVisibleBtn(false), 200)}
            >
              <div
                className={`circle-bg ${
                  activeIndexClass === 12 ? "list-button__active" : ""
                }`}
              >
                {creatElement(
                  dataButton[12].componentIcon,
                  activeIndexClass === 12 ? "hover-svg" : ""
                )}
              </div>
              <div
                className={`list-button__item ${
                  activeIndexClass === 12 ? "item-hover" : ""
                }`}
              >
                {dataButton[12].title}
              </div>
            </ScrollLink>
          </Grid>
        </Grid>
      </div>
      {activeSidebarInside ? (
        <div className="b-menu">
          <div className="b-menu__wrapper d-flex">
            <div className="b-menu__block">
              <button className="circle-bg b-menu__button" onClick={handlePrev}>
                <ArrowLeft />
              </button>
              <h2 className="b-menu__title">Project details</h2>
              <button className="circle-bg b-menu__button" onClick={handleNext}>
                <ArrowRigth />
              </button>
            </div>
            <button
              onClick={toggleBottomMenu}
              className="circle-bg b-menu__button"
              style={{ marginLeft: "15px" }}
            >
              {visibleBtn ? <ButtonExit /> : <ButtonList />}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

function Arrow(props) {
  return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="arrow-sidebar"
    >
      <path
        d="M1.61022 10.6671C1.57735 10.6996 1.53829 10.7252 1.49535 10.7424C1.45241 10.7596 1.40646 10.7679 1.36022 10.7671C1.31398 10.7679 1.26804 10.7596 1.2251 10.7424C1.18216 10.7252 1.14309 10.6996 1.11022 10.6671C1.04403 10.6007 1.00685 10.5108 1.00685 10.4171C1.00685 10.3233 1.04403 10.2334 1.11022 10.1671L5.51022 5.76706L1.11022 1.36706C1.07463 1.33487 1.04595 1.29579 1.02593 1.25218C1.00591 1.20857 0.994969 1.16135 0.993764 1.11338C0.992559 1.06541 1.00112 1.0177 1.01892 0.973136C1.03673 0.928577 1.06341 0.888103 1.09734 0.854172C1.13127 0.820242 1.17174 0.793564 1.2163 0.775759C1.26086 0.757955 1.30857 0.749395 1.35654 0.7506C1.40451 0.751805 1.45174 0.76275 1.49535 0.78277C1.53895 0.80279 1.57804 0.831467 1.61022 0.867058L6.26022 5.51706C6.32642 5.58342 6.36359 5.67333 6.36359 5.76706C6.36359 5.86079 6.32642 5.9507 6.26022 6.01706L1.61022 10.6671Z"
        fill="#8186AF"
      />
    </svg>
  );
}

function ArrowLeft(props) {
  return (
    <svg
      width="7"
      height="13"
      viewBox="0 0 7 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.73277 0.361061C5.77184 0.321247 5.81855 0.289741 5.87011 0.268432C5.92166 0.247123 5.97699 0.236453 6.03277 0.237062C6.08855 0.236453 6.14387 0.247123 6.19543 0.268432C6.24698 0.289741 6.2937 0.321247 6.33277 0.361061C6.41215 0.440722 6.45673 0.548599 6.45673 0.661061C6.45673 0.773523 6.41215 0.881401 6.33277 0.961061L1.05677 6.23706L6.33277 11.5131C6.41233 11.5926 6.45703 11.7005 6.45703 11.8131C6.45703 11.9256 6.41233 12.0335 6.33277 12.1131C6.2532 12.1926 6.14529 12.2373 6.03277 12.2373C5.92025 12.2373 5.81233 12.1926 5.73277 12.1131L0.156767 6.53706C0.0773816 6.4574 0.0328069 6.34952 0.0328069 6.23706C0.0328069 6.1246 0.0773816 6.01672 0.156767 5.93706L5.73277 0.361061Z"
        fill="#8186AF"
      />
    </svg>
  );
}

function ArrowRigth(props) {
  return (
    <svg
      width="7"
      height="13"
      viewBox="0 0 7 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.26723 12.6391C1.22816 12.6789 1.18145 12.7104 1.12989 12.7317C1.07834 12.753 1.02301 12.7637 0.967233 12.7631C0.911453 12.7637 0.856125 12.753 0.804573 12.7317C0.75302 12.7104 0.706305 12.6789 0.667233 12.6391C0.587848 12.5594 0.543273 12.4515 0.543273 12.3391C0.543273 12.2266 0.587848 12.1187 0.667233 12.0391L5.94323 6.76306L0.667233 1.48706C0.587668 1.40749 0.542969 1.29958 0.542969 1.18706C0.542969 1.07454 0.587668 0.966624 0.667233 0.887059C0.746798 0.807494 0.854711 0.762794 0.967233 0.762794C1.07975 0.762794 1.18767 0.807494 1.26723 0.887059L6.84323 6.46306C6.92262 6.54272 6.96719 6.6506 6.96719 6.76306C6.96719 6.87552 6.92262 6.9834 6.84323 7.06306L1.26723 12.6391Z"
        fill="#8186AF"
      />
    </svg>
  );
}

function ButtonList(props) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0H5C4.44772 0 4 0.447715 4 1C4 1.55228 4.44772 2 5 2H15C15.5523 2 16 1.55228 16 1C16 0.447715 15.5523 0 15 0Z"
        fill="white"
      />
      <path
        d="M15 6H5C4.44772 6 4 6.44772 4 7C4 7.55228 4.44772 8 5 8H15C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6Z"
        fill="white"
      />
      <path
        d="M15 12H5C4.44772 12 4 12.4477 4 13C4 13.5523 4.44772 14 5 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12Z"
        fill="white"
      />
      <path
        d="M2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2C1.55228 2 2 1.55228 2 1Z"
        fill="white"
      />
      <path
        d="M2 7C2 6.44772 1.55228 6 1 6C0.447715 6 0 6.44772 0 7C0 7.55228 0.447715 8 1 8C1.55228 8 2 7.55228 2 7Z"
        fill="white"
      />
      <path
        d="M2 13C2 12.4477 1.55228 12 1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14C1.55228 14 2 13.5523 2 13Z"
        fill="white"
      />
    </svg>
  );
}
