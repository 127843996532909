import React from "react";

import "./comparison.scss";
import data from "../../../data/data";
import Slider from "../../fewSimpleComponents/slider/Slider";
import SliderDisabled from "../../../resources/elements/SliderDisabled";
import { ReactComponent as Title } from "../../../resources/icon/Comparison/Title.svg";
import { ReactComponent as FundingState } from "../../../resources/icon/Comparison/FundingState.svg";
import { ReactComponent as AnnualReturn } from "../../../resources/icon/Comparison/AnnualReturn.svg";
import { ReactComponent as Duration } from "../../../resources/icon/Comparison/Duration.svg";
import { ReactComponent as Location } from "../../../resources/icon/Comparison/Location.svg";
import { ReactComponent as Distribution } from "../../../resources/icon/Comparison/Distribution.svg";
import { ReactComponent as MyInvestment } from "../../../resources/icon/Comparison/MyInvestment.svg";
import { ReactComponent as Profit } from "../../../resources/icon/Comparison/Profit.svg";
import DisabledImg from "../../../resources/icon/Comparison/DisabledImg.png";

const dataTitle = [
  {
    title: "Title",
    icon: Title,
  },
  {
    title: "Funding state",
    icon: FundingState,
  },
  {
    title: "Annual return (%)",
    icon: AnnualReturn,
  },
  {
    title: "Duration",
    icon: Duration,
  },
  {
    title: "Location",
    icon: Location,
  },
  {
    title: "Distribution",
    icon: Distribution,
  },
  {
    title: "My investment",
    icon: MyInvestment,
  },
  {
    title: "Profit",
    icon: Profit,
  },
];

function Comparison(props) {
  const iniData = data.slice(0, 2);
  const [dataState, setDataState] = React.useState(iniData);
  const [values, setValues] = React.useState([]);

  function remove(index) {
    setDataState([...dataState.slice(0, index), ...dataState.slice(index + 1)]);
  }

  const handleInputChange = (event, index) => {
    const updatedValues = [...values];
    updatedValues[index] = event.target.value;
    setValues(updatedValues);
  };

  const creatElement = (title, clazz = "") => {
    let creatElement = React.createElement(title, { className: clazz });
    return creatElement;
  };

  const showDisabledItem = () => {
    if (dataState.length >= 3) {
      return null;
    } else if (dataState.length === 2) {
      return <ComparisonDisabled />;
    } else if (dataState.length === 1) {
      return (
        <>
          <ComparisonDisabled />
          <ComparisonDisabled />
        </>
      );
    } else if (dataState.length === 0) {
      return (
        <>
          <ComparisonDisabled />
          <ComparisonDisabled />
          <ComparisonDisabled />
        </>
      );
    }
  };

  return (
    <div className="comparison">
      <div className="comparison__wrapper ml-0">
        <div className="comparison__title-component">
          <h3>Project comparison</h3>
        </div>
        {dataTitle.map(({ title, icon }, i) => {
          return (
            <div className="comparison__box-wrapper " key={i}>
              <div className="comparison__box-icon">{creatElement(icon)}</div>
              <div className="comparison__box-title">{title}</div>
            </div>
          );
        })}
      </div>
      {dataState.map(({ title, procent, location, pictures, id }, i) => {
        return (
          <div className="comparison-item" key={id}>
            <div className="comparison__wrapper">
              <div className="comparison-item__img">
                <img src={pictures[i]?.url} alt={title} />
              </div>
              <div className="comparison-item__box">
                <div className="comparison-item__title">{title}</div>
              </div>
              <div className="comparison-item__box">
                <div className="comparison-item__funding-state">
                  <Slider />
                </div>
              </div>
              <div className="comparison-item__box">
                <div className="comparison-item__title">
                  {procent.return}% p.a.
                </div>
              </div>
              <div className="comparison-item__box">
                <div className="comparison-item__title">
                  {procent.duration} Month
                </div>
              </div>
              <div className="comparison-item__box">
                <div className="comparison-item__title">
                  <a href="http://extej.com/">{location.name}</a>
                </div>
              </div>
              <div className="comparison-item__box">
                <div className="comparison-item__title">
                  {procent.duration} Month
                </div>
              </div>
              <div className="comparison-item__box-input">
                <div className="input-label">
                  <div className="input-icon comparison-item__input-icon">
                    €
                  </div>
                  <input
                    type="number"
                    className="input-calculator comparison-item__input-calculator"
                    placeholder="0.00"
                    value={values[i] || ""}
                    onChange={(event) => handleInputChange(event, i)}
                  />
                </div>
              </div>
              <div className="comparison-item__box">
                <div className="comparison-item__profit">
                  €{" "}
                  {values[i]
                    ? +values[i] + values[i] * (procent.return / 100)
                    : "0.00"}
                </div>
              </div>
              <button
                className="button-general comparison-item__btn"
                onClick={() => remove(i)}
              >
                Delete object
              </button>
            </div>
          </div>
        );
      })}
      {showDisabledItem()}
    </div>
  );
}

export default Comparison;

function ComparisonDisabled(props) {
  return (
    <div className="comparison-item">
      <div className="comparison__wrapper">
        <div className="comparison-item__img">
          <img src={DisabledImg} alt="Title" />
        </div>
        <div className="comparison-item__box">
          <div className="comparison-item__title comparison-disabled">
            Title
          </div>
        </div>
        <div className="comparison-item__box">
          <div
            style={{ paddingTop: "6px" }}
            className="comparison-item__funding-state"
          >
            <SliderDisabled />
          </div>
        </div>
        <div className="comparison-item__box">
          <div className="comparison-item__title comparison-disabled">
            0% p.a.
          </div>
        </div>
        <div className="comparison-item__box">
          <div className="comparison-item__title comparison-disabled">
            0 Month
          </div>
        </div>
        <div className="comparison-item__box">
          <div className="comparison-item__title comparison-disabled">
            Location
          </div>
        </div>
        <div className="comparison-item__box">
          <div className="comparison-item__title comparison-disabled">
            0 Month
          </div>
        </div>
        <div className="comparison-item__box-input">
          <div className="input-label">
            <div className="input-icon comparison-item__input-icon comparison-disabled">
              €
            </div>
            <input
              type="text"
              className="input-calculator comparison-item__input-calculator comparison-disabled"
              placeholder="0.00"
              readOnly
            />
          </div>
        </div>
        <div className="comparison-item__box">
          <div className="comparison-item__profit comparison-disabled">
            € 0.00
          </div>
        </div>
      </div>
    </div>
  );
}
