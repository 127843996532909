import { useState } from "react";
import Grid from "@mui/material/Grid";
import { Pagination } from "@mui/material";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import ProductCard from "../productCard/ProductCard";

import "./newAndHotProjects.scss";

function NewAndHotProjects(props) {
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState("1");
  const [pageCount, setPageCount] = useState(null);

  const newProject = props.data.filter(
    (item) => item.category.mapBySort === "new projects"
  );

  const hotProject = props.data.filter(
    (item) => item.category.mapBySort === "hot projects"
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (value === "1") {
      setPageCount(Math.ceil(hotProject.length / rowsPerPage));
      setPage(1);
    } else {
      setPageCount(Math.ceil(newProject.length / rowsPerPage));
      setPage(1);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const newPageCoutn = Math.ceil(newProject.length / rowsPerPage);
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
            className="box-NewAndHotProjects"
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="New projects" value="1" />
              <Tab label="Hot projects" value="2" />
            </TabList>
            <Pagination
              count={pageCount === null ? newPageCoutn : pageCount}
              page={page}
              onChange={handleChangePage}
              rowsperpage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
          <TabPanel value="1" style={{ padding: 0 }}>
            <div className="product-card">
              <Grid
                container
                rowSpacing={{ xs: 11, sm: 11, md: 3 }}
                columnSpacing={4}
              >
                {newProject.slice(startIndex, endIndex).map((product) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                    <ProductCard {...product} />
                  </Grid>
                ))}
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value="2" style={{ padding: 0 }}>
            <div className="product-card">
              <Grid
                container
                rowSpacing={{ xs: 11, sm: 11, md: 3 }}
                columnSpacing={4}
              >
                {hotProject.slice(startIndex, endIndex).map((product) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                    <ProductCard {...product} />
                  </Grid>
                ))}
              </Grid>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default NewAndHotProjects;
