import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-scroll";

import React from "react";
import InvestmentCase from "../dashboard/InvestmentCase/InvestmentCase";
import Documents from "../dashboard/documents/Documents";
import ProjectCosting from "../dashboard/ProjectCosting/ProjectCosting";
import FinanceStructure from "../dashboard/financeStructure/FinanceStructure";
import DlendoRating from "../dashboard/dlendoRating/DlendoRating";
import MapLocation from "../dashboard/mapLocation/MapLocation";
import RelatedProjects from "../dashboard/relatedProjects/RelatedProjects";
import Dashboard from "../dashboard/dashboard/Dashboard";
import Calculator from "../dashboard/calculator/Calculator";
import ProjectNews from "../dashboard/projectNews/ProjectNews";
import ProjectPresentation from "../dashboard/projectPresentation/ProjectPresentation";
import InterestRateComparison from "../dashboard/interestRateComparison/InterestRateComparison";
import DealHighlights from "../dashboard/dealHighlights/DealHighlights";

function Item(props) {
  const itemId = useSelector((state) => state.itemProjectSlice.filtered[0]);

  useEffect(() => {
    if (itemId) {
      localStorage.setItem("itemId", JSON.stringify(itemId));
    }
  }, [itemId]);

  const dataId = itemId || JSON.parse(localStorage.getItem("itemId"));

  return (
    <>
      <Dashboard itemId={dataId} />
      <Calculator />
      <ProjectNews />
      <ProjectPresentation />
      <InterestRateComparison />
      <DealHighlights />
      <Documents />
      <InvestmentCase />
      <ProjectCosting />
      <FinanceStructure />
      <DlendoRating />
      <MapLocation itemId={dataId} />

      <ButtonReturn />

      <RelatedProjects />
    </>
  );
}

export default Item;

function ButtonReturn(props) {
  return (
    <div className="button-return">
      <div className="button-return-circle-empty"></div>
      <Link
        to="Project details"
        smooth={true}
        offset={-20}
        duration={800}
        className="button-return-rectangle rectangle-bg"
        href="home"
      >
        Return to the dashboard
      </Link>
      <Link
        to="Project details"
        smooth={true}
        offset={-20}
        duration={800}
        className="button-return-circle circle-bg"
        href="home"
      >
        <svg
          width="15"
          height="18"
          viewBox="0 0 15 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.3099 1.04584L1.6439 5.71283C1.42722 5.92951 1.25534 6.18676 1.13807 6.46986C1.02081 6.75297 0.960449 7.05641 0.960449 7.36284C0.960449 7.66927 1.02081 7.97269 1.13807 8.2558C1.25534 8.53891 1.42722 8.79615 1.6439 9.01283C1.86058 9.22951 2.11782 9.40139 2.40093 9.51866C2.68404 9.63593 2.98747 9.69629 3.2939 9.69629C3.60033 9.69629 3.90377 9.63593 4.18687 9.51866C4.46998 9.40139 4.72722 9.22951 4.9439 9.01283L7.9609 5.99583L10.9779 9.01283C11.1946 9.22951 11.4518 9.40139 11.7349 9.51866C12.018 9.63593 12.3215 9.69629 12.6279 9.69629C12.9343 9.69629 13.2378 9.63593 13.5209 9.51866C13.804 9.40139 14.0612 9.22951 14.2779 9.01283C14.4946 8.79615 14.6665 8.53891 14.7837 8.2558C14.901 7.97269 14.9614 7.66927 14.9614 7.36284C14.9614 7.05641 14.901 6.75297 14.7837 6.46986C14.6665 6.18676 14.4946 5.92951 14.2779 5.71283L9.6109 1.04584C9.39432 0.828983 9.13711 0.656941 8.85399 0.539566C8.57087 0.422191 8.26739 0.361771 7.9609 0.361771C7.65441 0.361771 7.35093 0.422191 7.06781 0.539566C6.78469 0.656941 6.52649 0.828983 6.3099 1.04584ZM8.7849 1.87083L13.4519 6.53783C13.6643 6.76 13.7828 7.05551 13.7828 7.36284C13.7828 7.67017 13.6643 7.96567 13.4519 8.18784C13.2329 8.40633 12.9362 8.52904 12.6269 8.52904C12.3176 8.52904 12.0209 8.40633 11.8019 8.18784L7.9599 4.34583L4.1179 8.18784C3.8991 8.40677 3.60228 8.52981 3.29276 8.52991C2.98323 8.53 2.68634 8.40713 2.4674 8.18832C2.24846 7.96952 2.12541 7.67272 2.12532 7.36319C2.12523 7.05366 2.2481 6.75676 2.4669 6.53783L7.1339 1.87083C7.35291 1.65204 7.64982 1.52913 7.9594 1.52913C8.26898 1.52913 8.56589 1.65204 8.7849 1.87083Z"
            fill="#FA544D"
          />
          <path
            d="M6.31004 9.21295L1.64404 13.88C1.42742 14.0966 1.2556 14.3539 1.1384 14.6369C1.02119 14.92 0.960891 15.2234 0.960938 15.5298C0.960984 15.8362 1.02138 16.1396 1.13867 16.4226C1.25596 16.7057 1.42785 16.9628 1.64454 17.1794C1.86122 17.3961 2.11844 17.5679 2.40152 17.6851C2.68461 17.8023 2.988 17.8626 3.29439 17.8625C3.60077 17.8625 3.90415 17.8021 4.1872 17.6848C4.47025 17.5675 4.72742 17.3956 4.94403 17.1789L7.95903 14.163L10.977 17.1789C11.1937 17.3956 11.451 17.5675 11.7341 17.6848C12.0172 17.802 12.3206 17.8624 12.627 17.8624C12.9335 17.8624 13.2369 17.802 13.52 17.6848C13.8031 17.5675 14.0604 17.3956 14.277 17.179C14.4937 16.9623 14.6656 16.705 14.7829 16.4219C14.9001 16.1388 14.9605 15.8354 14.9605 15.529C14.9605 15.2225 14.9001 14.9191 14.7829 14.636C14.6656 14.3529 14.4937 14.0956 14.277 13.879L9.60904 9.21295C9.39245 8.9961 9.13524 8.82406 8.85212 8.70669C8.569 8.58931 8.26552 8.5289 7.95903 8.5289C7.65255 8.5289 7.34907 8.58931 7.06594 8.70669C6.78282 8.82406 6.52662 8.9961 6.31004 9.21295ZM8.78403 10.038L13.451 14.705C13.6634 14.9271 13.7819 15.2226 13.7819 15.53C13.7819 15.8373 13.6634 16.1328 13.451 16.3549C13.2321 16.5734 12.9354 16.6961 12.626 16.6961C12.3167 16.6961 12.02 16.5734 11.801 16.3549L7.95903 12.5129L4.11703 16.354C3.89823 16.5729 3.60142 16.6959 3.29189 16.696C2.98236 16.6961 2.68547 16.5733 2.46653 16.3544C2.2476 16.1356 2.12455 15.8388 2.12445 15.5293C2.12436 15.2198 2.24723 14.9229 2.46603 14.704L7.13303 10.0369C7.35204 9.81815 7.64896 9.69525 7.95853 9.69525C8.26811 9.69525 8.56502 9.81815 8.78403 10.0369V10.038Z"
            fill="#FA544D"
          />
        </svg>
      </Link>
    </div>
  );
}
