import React from "react";
import { Grid, Modal } from "@mui/material";
import { Field, useFormikContext } from "formik";
import "./personalData.scss";

import TelephoneVerification from "../../popUps/telephoneVerification/TelephoneVerification";
import { country, dataPlaces, Language } from "./dataSelect";

import { ReactComponent as UserName } from "../../../resources/icon/SettingProfile/UserName.svg";
import { ReactComponent as FocusedInput } from "../../../resources/icon/SettingProfile/FocusedInput.svg";
import { ReactComponent as Email } from "../../../resources/icon/SettingProfile/Email.svg";
import { ReactComponent as Gender } from "../../../resources/icon/SettingProfile/Gender.svg";
import { ReactComponent as Telephone } from "../../../resources/icon/SettingProfile/Telephone.svg";
import { ReactComponent as DataOfBirth } from "../../../resources/icon/SettingProfile/DataOfBirth.svg";
import { ReactComponent as Attention } from "../../../resources/icon/SettingProfile/Attention.svg";
import { ReactComponent as Country } from "../../../resources/icon/SettingProfile/Country.svg";
import { ReactComponent as Address } from "../../../resources/icon/SettingProfile/Address.svg";
import { ReactComponent as Path } from "../../../resources/icon/SettingProfile/Path.svg";
import { ReactComponent as Lang } from "../../../resources/icon/SettingProfile/Lang.svg";
import { ReactComponent as PostalCode } from "../../../resources/icon/SettingProfile/PostalCode.svg";
import { ReactComponent as CopyReferrer } from "../../../resources/icon/SettingProfile/CopyReferrer.svg";
import { ReactComponent as Referrer } from "../../../resources/icon/SettingProfile/Referrer.svg";
import { ReactComponent as Instagram } from "../../../resources/icon/SettingProfile/Instagram.svg";
import { ReactComponent as Facebook } from "../../../resources/icon/SettingProfile/Facebook.svg";
import { ReactComponent as Twitter } from "../../../resources/icon/SettingProfile/Twitter.svg";
import { ReactComponent as Kverification } from "../../../resources/icon/SettingProfile/Kverification.svg";

const filedProps = [
  "email",
  "telephone",
  "dateOfBirth",
  "address",
  "postalCode",
  "facebook",
  "instagram",
  "twitter",
];

const PersonalData = () => {
  const [open, setOpen] = React.useState(false);
  const inputRefs = React.useRef([null]);
  const copyRefs = React.useRef(null);

  const { errors, touched, getFieldProps } = useFormikContext();
  const showError =
    filedProps.some((field) => field in errors) &&
    Object.keys(errors).length !== 0
      ? true
      : false;

  const handleIconClick = (index) => {
    inputRefs.current[index].focus();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleIconClickCopy = () => {
    const inputValue = copyRefs.current.value;
    if (inputValue) {
      navigator.clipboard
        .writeText(inputValue)
        .then(() => {
          console.log("Value copied to clipboard:", inputValue);
        })
        .catch((error) => {
          console.error("Error copying value to clipboard:", error);
        });
    }
  };

  const styleValidInput = (errors, touched, getFieldProp) => {
    if (errors?.includes("Invalid") && touched) {
      return "іnvalid";
    } else if (
      errors === undefined &&
      touched &&
      getFieldProps(getFieldProp).value !== ""
    ) {
      return "valid";
    }
  };

  return (
    <>
      <Modal className="custom-modal" open={open} onClose={handleClose}>
        <TelephoneVerification handleClose={handleClose} />
      </Modal>
      <Grid item xs={12} sm={12} md={6}>
        <div className="box box-col">
          <div className="box-title">Personal data</div>
          <Grid container spacing={2}>
            <ReadOnlyForm />

            <Grid item xs={12} sm={6} md={6}>
              <div className="data-input">
                <div
                  className={`data-input__wrapper ${styleValidInput(
                    errors.telephone,
                    touched.telephone,
                    "telephone"
                  )}`}
                >
                  <div className="data-input__left-icon">
                    <Telephone />
                  </div>
                  <div className="data-input__input-general">
                    <Field
                      type="text"
                      name="telephone"
                      innerRef={(el) => (inputRefs.current[0] = el)}
                    />
                    <label>Telephone</label>
                  </div>
                  <div className="data-input__verify" onClick={handleOpen}>
                    Verify
                  </div>
                  <div
                    className="data-input__icon-right"
                    onClick={() => handleIconClick(0)}
                  >
                    <FocusedInput />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div className="data-input">
                <div
                  className={`data-input__wrapper ${styleValidInput(
                    errors.email,
                    touched.email,
                    "email"
                  )}`}
                >
                  <div className="data-input__left-icon">
                    <Email />
                  </div>
                  <div className="data-input__input-general">
                    <Field
                      type="text"
                      name="email"
                      innerRef={(el) => (inputRefs.current[1] = el)}
                    />

                    <label>Email</label>
                  </div>
                  <div
                    className="data-input__icon-right"
                    onClick={() => handleIconClick(1)}
                  >
                    <FocusedInput />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div className="data-input">
                <div
                  className={`data-input__wrapper ${styleValidInput(
                    errors.dateOfBirth,
                    touched.dateOfBirth,
                    "dateOfBirth"
                  )}`}
                >
                  <div className="data-input__left-icon">
                    <DataOfBirth />
                  </div>
                  <div className="data-input__input-general">
                    <Field
                      type="text"
                      name="dateOfBirth"
                      innerRef={(el) => (inputRefs.current[2] = el)}
                    />
                    <label>Date of birth</label>
                  </div>
                  <div
                    className="data-input__icon-right"
                    onClick={() => handleIconClick(2)}
                  >
                    <FocusedInput />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div className="data-input">
                <div className={`data-input__wrapper`}>
                  <div className="data-input__left-icon">
                    <Country />
                  </div>
                  <div className="data-input__input-general path-select">
                    <CustomSelect options={country} />
                    <label>Country</label>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div className="data-input">
                <div className={`data-input__wrapper`}>
                  <div className="data-input__left-icon">
                    <Address />
                  </div>
                  <div className="data-input__input-general path-select">
                    <CustomSelect options={dataPlaces} />
                    <label>City</label>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div className="data-input">
                <div
                  className={`data-input__wrapper ${styleValidInput(
                    errors.address,
                    touched.address,
                    "address"
                  )}`}
                >
                  <div className="data-input__left-icon">
                    <Address />
                  </div>
                  <div className="data-input__input-general">
                    <Field
                      type="text"
                      name="address"
                      innerRef={(el) => (inputRefs.current[3] = el)}
                    />
                    <label>Address</label>
                  </div>
                  <div
                    className="data-input__icon-right"
                    onClick={() => handleIconClick(3)}
                  >
                    <FocusedInput />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div className="data-input">
                <div
                  className={`data-input__wrapper ${styleValidInput(
                    errors.postalCode,
                    touched.postalCode,
                    "postalCode"
                  )}`}
                >
                  <div className="data-input__left-icon">
                    <PostalCode />
                  </div>
                  <div className="data-input__input-general">
                    <Field
                      type="number"
                      name="postalCode"
                      innerRef={(el) => (inputRefs.current[4] = el)}
                    />
                    <label>Postal code</label>
                  </div>
                  <div
                    className="data-input__icon-right"
                    onClick={() => handleIconClick(4)}
                  >
                    <FocusedInput />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div className="data-input">
                <div className={`data-input__wrapper `}>
                  <div className="data-input__left-icon">
                    <Referrer />
                  </div>
                  <div className="data-input__input-general">
                    <input
                      name="referrer"
                      value="Myreferrer@gmail.com"
                      ref={copyRefs}
                    />
                    <label>Referrer</label>
                  </div>
                  <div
                    className="data-input__icon-right"
                    onClick={handleIconClickCopy}
                  >
                    <CopyReferrer />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div className="data-input">
                <div
                  className={`data-input__wrapper ${styleValidInput(
                    errors.facebook,
                    touched.facebook,
                    "facebook"
                  )}`}
                >
                  <div className="data-input__left-icon">
                    <Facebook />
                  </div>
                  <div className="data-input__input-general">
                    <Field
                      type="text"
                      name="facebook"
                      innerRef={(el) => (inputRefs.current[5] = el)}
                    />
                    <label>Facebook</label>
                    <div className="attention-label">
                      <Attention />
                    </div>
                  </div>
                  <div
                    className="data-input__icon-right"
                    onClick={() => handleIconClick(5)}
                  >
                    <FocusedInput />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div className="data-input">
                <div
                  className={`data-input__wrapper ${styleValidInput(
                    errors.twitter,
                    touched.twitter,
                    "twitter"
                  )}`}
                >
                  <div className="data-input__left-icon">
                    <Twitter />
                  </div>
                  <div className="data-input__input-general">
                    <Field
                      type="text"
                      name="twitter"
                      innerRef={(el) => (inputRefs.current[6] = el)}
                    />
                    <label>Twitter</label>
                    <div className="attention-label attention-label__twitter">
                      <Attention />
                    </div>
                  </div>
                  <div
                    className="data-input__icon-right"
                    onClick={() => handleIconClick(6)}
                  >
                    <FocusedInput />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div className="data-input">
                <div
                  className={`data-input__wrapper ${styleValidInput(
                    errors.instagram,
                    touched.instagram,
                    "instagram"
                  )}`}
                >
                  <div className="data-input__left-icon">
                    <Instagram />
                  </div>
                  <div className="data-input__input-general">
                    <Field
                      type="text"
                      name="instagram"
                      innerRef={(el) => (inputRefs.current[7] = el)}
                    />
                    <label>Instagram</label>
                    <div className="attention-label attention-label__instagram">
                      <Attention />
                    </div>
                  </div>
                  <div
                    className="data-input__icon-right"
                    onClick={() => handleIconClick(7)}
                  >
                    <FocusedInput />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div className="data-input">
                <div className={`data-input__wrapper`}>
                  <div className="data-input__left-icon">
                    <Lang />
                  </div>
                  <div className="data-input__input-general path-select">
                    <CustomSelect options={Language} />
                    <label>Language</label>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div className="data-input">
                <div className="data-input__wrapper">
                  <div className="data-input__left-icon">
                    <Kverification />
                  </div>
                  <div className="data-input__input-general">
                    <input readOnly type="text" style={{ color: "#8186af" }} />
                    <label>KYC verification</label>
                    <div
                      className="data-input__verify"
                      style={{ marginLeft: "22px" }}
                      onClick={handleOpen}
                    >
                      Verify
                    </div>
                  </div>
                </div>
              </div>
            </Grid>

            <div className="attention-title">
              <Attention />
              <div className="attention-title__text">
                Visible is only to your referral
              </div>
            </div>

            {showError && (
              <Grid item xs={12} mt={-1} mb={-2}>
                <div
                  className="error-description"
                  style={{ marginTop: "25px" }}
                >
                  {Object.entries(errors)
                    .filter(([fieldName]) => filedProps.includes(fieldName))
                    .map(([fieldName, errorMessage], i) => (
                      <ul key={i} className="error-description__error-list">
                        <li className="error-description__error-item">
                          {errorMessage}
                        </li>
                      </ul>
                    ))}
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default PersonalData;

function CustomSelect({ options }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const checkOfValue = options?.some((item) => item.hasOwnProperty("value"));

  return (
    <div className={`custom-selects ${isOpen ? "open" : ""}`}>
      <div className="selected-option d-flex" onClick={handleToggle}>
        {selectedOption ? (
          <>
            {checkOfValue && (
              <img
                src={`https://hatscripts.github.io/circle-flags/flags/${selectedOption.value}.svg`}
                width="18"
                alt={selectedOption.label}
              ></img>
            )}
            <span>{selectedOption.label}</span>
          </>
        ) : (
          <>
            {checkOfValue && (
              <img
                src={`https://hatscripts.github.io/circle-flags/flags/${options[5]?.value}.svg`}
                width="18"
                alt={options[5]?.label}
              ></img>
            )}
            <span>{options[5]?.label}</span>
          </>
        )}
      </div>
      <div className={`arrow ${isOpen ? "opened" : ""}`}>
        <Path />
      </div>
      {isOpen && (
        <div className="options">
          {options.map((option) => (
            <div
              key={option.value}
              className="option d-flex"
              onClick={() => handleOptionClick(option)}
            >
              {checkOfValue && (
                <img
                  src={`https://hatscripts.github.io/circle-flags/flags/${option.value}.svg`}
                  width="18"
                  alt={option.label}
                ></img>
              )}
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function ReadOnlyForm(props) {
  const basicData = [
    { labelName: "First name", filledFeild: "Laurens" },
    { labelName: "Last name", filledFeild: "Bernstein" },
  ];

  return (
    <>
      {basicData?.map(({ labelName, filledFeild }, i) => {
        return (
          <Grid item xs={12} sm={6} md={6} key={i}>
            <div className="data-input">
              <div className="data-input__wrapper">
                <div className="data-input__left-icon">
                  <UserName />
                </div>
                <div className="data-input__input-general">
                  <input
                    readOnly
                    type="text"
                    value={filledFeild}
                    style={{ color: "#8186af" }}
                  />
                  <label>{labelName}</label>
                </div>
              </div>
            </div>
          </Grid>
        );
      })}
      <Grid item xs={12} sm={6} md={6}>
        <div className="data-input">
          <div className="data-input__wrapper">
            <div className="data-input__left-icon">
              <Gender />
            </div>
            <div className="data-input__input-general">
              <input
                readOnly
                type="text"
                value="Male"
                style={{ color: "#8186af" }}
              />
              <label>Gender</label>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
}
