import React from "react";

import "./notificationsMessage.scss";
import { ReactComponent as ArrowLeft } from "../../../resources/icon/Notifications/ArrowLeft.svg";
import { ReactComponent as Notifications } from "../../../resources/icon/Notifications/Notifications.svg";
import { ReactComponent as Email } from "../../../resources/icon/Notifications/Email.svg";
import { ReactComponent as Attention } from "../../../resources/icon/Notifications/Attention.svg";
import { ButtonExit } from "../SidebarWrapper";

const dataNotifications = [
  {
    type: Email,
    date: "11/05/2023",
    time: "at 09:35",
    text: "Sehr geehrter Benutzer, Ihre Bewerbung wurde geprüft und Bewerbung wurde",
  },
  {
    type: Attention,
    date: "07/06/2023",
    time: "at 03:20",
    text: "Your signup bonus of 50$ has been transferred to your wallet. Your signup bonus of 50$ ",
  },
  {
    type: Email,
    date: "11/05/2023",
    time: "at 09:35",
    text: "Sehr geehrter Benutzer, Ihre Bewerbung wurde geprüft und Bewerbung wurde",
  },
  {
    type: Attention,
    date: "07/06/2023",
    time: "at 03:20",
    text: "Your signup bonus of 50$ has been transferred to your wallet. Your signup bonus of 50$ ",
  },
  {
    type: Email,
    date: "11/05/2023",
    time: "at 09:35",
    text: "Sehr geehrter Benutzer, Ihre Bewerbung wurde geprüft und Bewerbung wurde",
  },
  {
    type: Attention,
    date: "07/06/2023",
    time: "at 03:20",
    text: "Your signup bonus of 50$ has been transferred to your wallet. Your signup bonus of 50$ ",
  },
  {
    type: Email,
    date: "11/05/2023",
    time: "at 09:35",
    text: "Sehr geehrter Benutzer, Ihre Bewerbung wurde geprüft und Bewerbung wurde",
  },
  {
    type: Attention,
    date: "07/06/2023",
    time: "at 03:20",
    text: "Your signup bonus of 50$ has been transferred to your wallet. Your signup bonus of 50$ ",
  },
  {
    type: Email,
    date: "11/05/2023",
    time: "at 09:35",
    text: "Sehr geehrter Benutzer, Ihre Bewerbung wurde geprüft und Bewerbung wurde",
  },
  {
    type: Attention,
    date: "07/06/2023",
    time: "at 03:20",
    text: "Your signup bonus of 50$ has been transferred to your wallet. Your signup bonus of 50$ ",
  },
  {
    type: Email,
    date: "11/05/2023",
    time: "at 09:35",
    text: "Sehr geehrter Benutzer, Ihre Bewerbung wurde geprüft und Bewerbung wurde",
  },
  {
    type: Attention,
    date: "07/06/2023",
    time: "at 03:20",
    text: "Your signup bonus of 50$ has been transferred to your wallet. Your signup bonus of 50$ ",
  },
  {
    type: Email,
    date: "11/05/2023",
    time: "at 09:35",
    text: "Sehr geehrter Benutzer, Ihre Bewerbung wurde geprüft und Bewerbung wurde",
  },
  {
    type: Attention,
    date: "07/06/2023",
    time: "at 03:20",
    text: "Your signup bonus of 50$ has been transferred to your wallet. Your signup bonus of 50$ ",
  },
];

function NotificationsMessage({ notificationsVisible, toggleNotifications }) {
  const [visibleCards, setVisibleCards] = React.useState(4);

  const cardsContainerRef = React.useRef(null);

  const handleLoadMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 2);
  };

  React.useEffect(() => {
    if (cardsContainerRef.current) {
      const lastCard = cardsContainerRef.current.lastChild;
      if (lastCard) {
        lastCard.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [visibleCards]);

  const creatElements = (title) => {
    let creatElementes = React.createElement(title);
    return creatElementes;
  };

  return (
    <div
      className={`notifications-message
        ${notificationsVisible ? "" : "notifications-message__hidden-content"}
      `}
    >
      <div className="notifications-message__wrapper">
        <div className="notifications-message__title">
          <div
            className="notifications-message__arrow notifications-message__hidden"
            onClick={() => toggleNotifications()}
          >
            <ArrowLeft />
          </div>
          <div className="notifications-message__icon">
            <div className="notifications-message__amount-message">
              {dataNotifications.length}
            </div>
            <Notifications />
          </div>
          <div className="notifications-message__text">Notifications</div>
          <div
            className="notifications-message__button-wrapper notifications-message__hidden"
            onClick={() => toggleNotifications()}
          >
            <ButtonExit />
          </div>
        </div>
        <div className="notifications-message__colomn-title">
          <div>Date</div>
          <div>Message</div>
        </div>
        <div className="message__notifications" ref={cardsContainerRef}>
          {dataNotifications
            .slice(0, visibleCards)
            .map(({ text, date, time, type }, i) => {
              return (
                <div key={i} className="message__item">
                  <div className="message__left">
                    <div className="message__date">
                      <div>{date}</div>
                      <div>{time}</div>
                    </div>
                    <div className="message__icon-n">{creatElements(type)}</div>
                  </div>
                  <div className="message__right">{text}</div>
                </div>
              );
            })}
          {visibleCards < dataNotifications.length - 2 && (
            <div className="notifications-button" onClick={handleLoadMore}>
              <button className="button-general">Read more</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NotificationsMessage;
