import React from "react";

function IconPanding(props) {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5788 12.2659H13.3718V11.0589C13.3718 10.9528 13.3296 10.8511 13.2546 10.7761C13.1796 10.7011 13.0779 10.6589 12.9718 10.6589C12.8657 10.6589 12.7639 10.7011 12.6889 10.7761C12.6139 10.8511 12.5718 10.9528 12.5718 11.0589V12.6679C12.5718 12.774 12.6139 12.8758 12.6889 12.9508C12.7639 13.0258 12.8657 13.0679 12.9718 13.0679H14.5807C14.6868 13.0679 14.7886 13.0258 14.8636 12.9508C14.9386 12.8758 14.9808 12.774 14.9808 12.6679C14.9808 12.5619 14.9386 12.4601 14.8636 12.3851C14.7886 12.3101 14.6868 12.2679 14.5807 12.2679L14.5788 12.2659Z"
          fill="#FA544D"
        />
        <path
          d="M15.53 10.109C15.079 9.65977 14.5175 9.33743 13.9022 9.17451C13.2869 9.01159 12.6395 9.01384 12.0253 9.18104C11.4111 9.34823 10.8519 9.67446 10.4041 10.1268C9.95627 10.5791 9.63568 11.1416 9.47465 11.7574C9.31362 12.3732 9.31787 13.0206 9.48695 13.6343C9.65603 14.2479 9.98395 14.8062 10.4377 15.2526C10.8914 15.699 11.4548 16.0179 12.0712 16.177C12.6875 16.3362 13.3348 16.3299 13.948 16.159C14.5555 15.9865 15.1076 15.6584 15.5495 15.2071C15.9915 14.7559 16.3079 14.1971 16.4677 13.586C16.6275 12.975 16.6251 12.3328 16.4606 11.723C16.2961 11.1132 15.9753 10.5568 15.53 10.109ZM13.742 15.382C13.2637 15.5198 12.7574 15.5286 12.2746 15.4076C11.7918 15.2865 11.3496 15.0398 10.993 14.6926C10.6363 14.3454 10.3779 13.91 10.2439 13.4306C10.1099 12.9512 10.1052 12.4449 10.2301 11.963C10.3551 11.4812 10.6053 11.0411 10.9554 10.6872C11.3055 10.3334 11.743 10.0785 12.2234 9.94837C12.7039 9.81826 13.2102 9.8176 13.691 9.94645C14.1718 10.0753 14.6099 10.3291 14.961 10.682C15.3031 11.0309 15.5492 11.4625 15.6752 11.9346C15.8013 12.4068 15.8031 12.9036 15.6804 13.3767C15.5577 13.8497 15.3147 14.283 14.9751 14.6344C14.6354 14.9858 14.2106 15.2433 13.742 15.382Z"
          fill="#FA544D"
        />
        <path
          d="M7.97916 14.038H6.60917C6.48796 14.038 6.37172 13.9898 6.28602 13.9041C6.20031 13.8184 6.15217 13.7022 6.15217 13.581V11.943L6.28616 12.077C6.37268 12.1584 6.48751 12.203 6.60631 12.2012C6.7251 12.1994 6.83853 12.1514 6.92254 12.0674C7.00655 11.9833 7.05455 11.8699 7.05634 11.7511C7.05814 11.6323 7.0136 11.5175 6.93217 11.431L6.01916 10.518C5.93347 10.4324 5.8173 10.3843 5.69617 10.3843C5.57503 10.3843 5.45886 10.4324 5.37317 10.518L4.46017 11.431C4.37873 11.5175 4.33419 11.6323 4.33599 11.7511C4.33779 11.8699 4.38577 11.9833 4.46979 12.0674C4.5538 12.1514 4.66723 12.1994 4.78602 12.2012C4.90482 12.203 5.01965 12.1584 5.10616 12.077L5.24016 11.943V13.577C5.24069 13.9402 5.3852 14.2883 5.64201 14.5451C5.89882 14.8019 6.24698 14.9465 6.61017 14.947H7.98016C8.04191 14.9499 8.1036 14.9402 8.16151 14.9186C8.21941 14.8969 8.27232 14.8638 8.31704 14.8211C8.36176 14.7784 8.39735 14.7271 8.42168 14.6703C8.446 14.6135 8.45853 14.5523 8.45853 14.4905C8.45853 14.4287 8.446 14.3675 8.42168 14.3107C8.39735 14.2538 8.36176 14.2025 8.31704 14.1599C8.27232 14.1172 8.21941 14.084 8.16151 14.0624C8.1036 14.0408 8.04191 14.0311 7.98016 14.034L7.97916 14.038Z"
          fill="#8186AF"
        />
        <path
          d="M14.2401 5.95091C14.1544 5.8653 14.0382 5.81721 13.9171 5.81721C13.796 5.81721 13.6798 5.8653 13.5941 5.95091L13.4601 6.08491V4.44692C13.4596 4.08373 13.315 3.73557 13.0582 3.47876C12.8014 3.22195 12.4533 3.07745 12.0901 3.07692H10.7201C10.6583 3.07403 10.5966 3.08369 10.5387 3.10533C10.4808 3.12697 10.4279 3.16012 10.3832 3.2028C10.3385 3.24547 10.3029 3.29678 10.2786 3.3536C10.2542 3.41043 10.2417 3.4716 10.2417 3.53341C10.2417 3.59523 10.2542 3.6564 10.2786 3.71323C10.3029 3.77005 10.3385 3.82136 10.3832 3.86403C10.4279 3.90671 10.4808 3.93987 10.5387 3.9615C10.5966 3.98314 10.6583 3.9928 10.7201 3.98991H12.0901C12.2113 3.98991 12.3275 4.03806 12.4132 4.12377C12.4989 4.20947 12.5471 4.32571 12.5471 4.44692V6.08491L12.4131 5.95091C12.3266 5.86948 12.2117 5.82494 12.0929 5.82674C11.9741 5.82854 11.8607 5.87653 11.7767 5.96054C11.6927 6.04455 11.6447 6.15798 11.6429 6.27678C11.6411 6.39557 11.6857 6.5104 11.7671 6.59691L12.6801 7.50991C12.7659 7.59528 12.882 7.64321 13.0031 7.64321C13.1241 7.64321 13.2403 7.59528 13.3261 7.50991L14.2391 6.59691C14.3248 6.51135 14.3731 6.39525 14.3733 6.27412C14.3735 6.15299 14.3256 6.03674 14.2401 5.95091Z"
          fill="#8186AF"
        />
        <path
          d="M1.58594 2.853V7.294C1.58594 7.44571 1.6462 7.5912 1.75347 7.69847C1.86074 7.80574 2.00623 7.866 2.15794 7.866H9.04594C9.19764 7.866 9.34313 7.80574 9.4504 7.69847C9.55767 7.5912 9.61794 7.44571 9.61794 7.294V2.853C9.61794 2.7013 9.55767 2.55581 9.4504 2.44854C9.34313 2.34127 9.19764 2.28101 9.04594 2.28101H2.15794C2.00623 2.28101 1.86074 2.34127 1.75347 2.44854C1.6462 2.55581 1.58594 2.7013 1.58594 2.853ZM8.82094 3.079V3.808C8.65289 3.7483 8.50026 3.65189 8.37416 3.52578C8.24806 3.39968 8.15164 3.24705 8.09194 3.079H8.82094ZM2.38394 3.079H3.11294C3.05323 3.24705 2.95682 3.39968 2.83072 3.52578C2.70461 3.65189 2.55198 3.7483 2.38394 3.808V3.079ZM2.38394 7.069V6.34C2.55198 6.39971 2.70461 6.49612 2.83072 6.62223C2.95682 6.74833 3.05323 6.90096 3.11294 7.069H2.38394ZM8.82094 7.069H8.09094C8.15064 6.90096 8.24706 6.74833 8.37316 6.62223C8.49926 6.49612 8.65189 6.39971 8.81994 6.34L8.82094 7.069ZM8.82094 5.513C8.43784 5.59181 8.08626 5.78121 7.8097 6.05777C7.53314 6.33433 7.34374 6.68591 7.26494 7.069H3.93994C3.86075 6.68673 3.67141 6.33598 3.39527 6.06002C3.11913 5.78406 2.76827 5.59494 2.38594 5.516V4.638C2.76903 4.5592 3.12061 4.36979 3.39717 4.09324C3.67373 3.81668 3.86313 3.4651 3.94194 3.082H7.26494C7.34374 3.4651 7.53314 3.81668 7.8097 4.09324C8.08626 4.36979 8.43784 4.5592 8.82094 4.638V5.513Z"
          fill="#8186AF"
        />
        <path
          d="M5.60186 3.87695C5.36452 3.87695 5.13251 3.94733 4.93517 4.07919C4.73783 4.21104 4.58402 4.39846 4.4932 4.61773C4.40237 4.837 4.37861 5.07828 4.42491 5.31106C4.47122 5.54384 4.5855 5.75766 4.75332 5.92548C4.92114 6.09331 5.13497 6.20759 5.36775 6.25389C5.60052 6.30019 5.84181 6.27643 6.06108 6.18561C6.28035 6.09478 6.46776 5.94098 6.59962 5.74364C6.73148 5.5463 6.80185 5.31429 6.80185 5.07695C6.80185 4.91936 6.77081 4.76332 6.71051 4.61773C6.6502 4.47214 6.56181 4.33985 6.45038 4.22842C6.33895 4.11699 6.20667 4.02861 6.06108 3.9683C5.91549 3.90799 5.75944 3.87695 5.60186 3.87695ZM5.60186 5.47695C5.52274 5.47695 5.44541 5.45349 5.37963 5.40954C5.31385 5.36559 5.26258 5.30312 5.2323 5.23003C5.20203 5.15694 5.1941 5.07651 5.20954 4.99892C5.22497 4.92132 5.26307 4.85005 5.31901 4.79411C5.37495 4.73817 5.44622 4.70007 5.52381 4.68464C5.60141 4.6692 5.68183 4.67712 5.75492 4.7074C5.82801 4.73767 5.89049 4.78894 5.93444 4.85472C5.9784 4.9205 6.00186 4.99784 6.00186 5.07695C6.0008 5.18234 5.95819 5.28306 5.88329 5.35722C5.80839 5.43137 5.70725 5.47296 5.60186 5.47295V5.47695Z"
          fill="#8186AF"
        />
      </svg>
    </div>
  );
}

export default IconPanding;
