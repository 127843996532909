import { categories } from "./categories";
import { places } from "./places";
import { pictures } from "./pictures";
import { procents } from "./procents";
import { investmentTables } from "./investmentTables";
import { userNames } from "./userNames";

const data = [
  {
    id: 100,
    title: "Luxury Villa",
    description:
      "A stunning luxury villa with breathtaking views, A stunning luxury villa with breathtaking views",
    pictures: pictures[0],
    funds: { funded: 100000 },
    procent: procents[0],
    location: places[0],
    category: categories[0],
    dateCreated: "2023-05-20",
    investmentTable: investmentTables[0],
    userName: userNames[0],
  },
  {
    id: 101,
    title: "Charming Cottage",
    description: "A charming cottage nestled in the countryside",
    pictures: pictures[1],
    funds: { funded: 75000 },
    procent: procents[1],
    location: places[1],
    category: categories[1],
    dateCreated: "2023-05-24",
    investmentTable: investmentTables[1],
    userName: userNames[1],
  },
  {
    id: 102,
    title: "Modern Apartment",
    description: "A modern apartment in the heart of the city",
    pictures: pictures[2],
    funds: { funded: 90000 },
    procent: procents[2],
    location: places[2],
    category: categories[2],
    dateCreated: "2023-05-23",
    investmentTable: investmentTables[2],
    userName: userNames[2],
  },
  {
    id: 103,
    title: "Cozy Cabin",
    description: "A cozy cabin surrounded by nature, the heart of the city",
    pictures: pictures[3],
    funds: { funded: 60000 },
    procent: procents[3],
    location: places[3],
    category: categories[3],
    dateCreated: "2023-05-22",
    investmentTable: investmentTables[3],
    userName: userNames[3],
  },
  {
    id: 104,
    title: "Seaside Bungalow",
    description: "A cozy bungalow by the seaside, the heart of the city",
    pictures: pictures[4],
    funds: { funded: 80000 },
    procent: procents[4],
    location: places[4],
    category: categories[4],
    dateCreated: "2023-05-21",
    investmentTable: investmentTables[4],
    userName: userNames[4],
  },
  {
    id: 105,
    title: "Mountain Retreat",
    description: "A peaceful retreat in the mountains, peaceful retrea",
    pictures: pictures[5],
    funds: { funded: 120000 },
    procent: procents[5],
    location: places[5],
    category: categories[5],
    dateCreated: "2023-05-25",
    investmentTable: investmentTables[5],
    userName: userNames[5],
  },
  {
    id: 106,
    title: "Rustic Farmhouse",
    description: "A charming rustic farmhouse with scenic views",
    pictures: pictures[6],
    funds: { funded: 95000 },
    procent: procents[6],
    location: places[6],
    category: categories[6],
    dateCreated: "2023-05-26",
    investmentTable: investmentTables[6],
    userName: userNames[6],
  },
  {
    id: 107,
    title: "City Penthouse",
    description: "A luxurious penthouse in the heart of the city",
    pictures: pictures[7],
    funds: { funded: 60000 },
    procent: procents[7],
    location: places[7],
    category: categories[7],
    dateCreated: "2023-05-23",
    investmentTable: investmentTables[7],
    userName: userNames[7],
  },
  {
    id: 108,
    title: "Coastal Villa",
    description: "A luxurious coastal villa with stunning ocean views",
    pictures: pictures[8],
    funds: { funded: 200000 },
    procent: procents[9],
    location: places[8],
    category: categories[8],
    dateCreated: "2023-05-27",
    investmentTable: investmentTables[8],
    userName: userNames[8],
  },
  {
    id: 109,
    title: "Lakefront Cottage",
    description: "A cozy cottage on the lakeshore in the peaceful countryside",
    pictures: pictures[9],
    funds: { funded: 90000 },
    procent: procents[10],
    location: places[9],
    category: categories[9],
    dateCreated: "2023-05-28",
    investmentTable: investmentTables[9],
    userName: userNames[9],
  },
  {
    id: 110,
    title: "Modern Apartment",
    description:
      " Immerse yourself in the tranquility of nature, surrounded by lush greenery and breathtaking landscapes. ",
    pictures: pictures[10],
    funds: { funded: 150000 },
    procent: procents[11],
    location: places[10],
    category: categories[10],
    dateCreated: "2023-05-29",
    investmentTable: investmentTables[10],
    userName: userNames[10],
  },
  {
    id: 111,
    title: "Countryside Estate",
    description: "An expansive estate in the peaceful countryside",
    pictures: pictures[11],
    funds: { funded: 60000 },
    procent: procents[12],
    location: places[11],
    category: categories[11],
    dateCreated: "2023-05-23",
    investmentTable: investmentTables[11],
    userName: userNames[11],
  },
  {
    id: 112,
    title: "Modern Apartment",
    description:
      "Relax and rejuvenate in this idyllic retreat, where every moment is filled with warmth and serenity",
    pictures: pictures[12],
    funds: { funded: 90000 },
    procent: procents[13],
    location: places[2],
    category: categories[2],
    dateCreated: "2023-05-22",
    investmentTable: investmentTables[12],
    userName: userNames[12],
  },
  {
    id: 113,
    title: "Cozy Cabin",
    description:
      "Living with easy access to entertainment, shopping, and dining",
    pictures: pictures[13],
    funds: { funded: 60000 },
    procent: procents[14],
    location: places[3],
    category: categories[3],
    dateCreated: "2023-04-23",
    investmentTable: investmentTables[13],
    userName: userNames[13],
  },
  {
    id: 114,
    title: "Seaside Bungalow",
    description:
      "A cozy bungalow by the seaside, A cozy bungalow by the seaside",
    pictures: pictures[14],
    funds: { funded: 80000 },
    procent: procents[15],
    location: places[4],
    category: categories[4],
    dateCreated: "2023-05-24",
    investmentTable: investmentTables[14],
    userName: userNames[14],
  },
  {
    id: 115,
    title: "Mountain Retreat",
    description:
      "Experience the convenience of urban living with easy access to entertainment, shopping, and dining",
    pictures: pictures[15],
    funds: { funded: 120000 },
    procent: procents[16],
    location: places[5],
    category: categories[5],
    dateCreated: "2023-05-25",
    investmentTable: investmentTables[15],
    userName: userNames[15],
  },
  {
    id: 116,
    title: "Rustic Farmhouse",
    description: "A charming rustic farmhouse with scenic views",
    pictures: pictures[16],
    funds: { funded: 95000 },
    procent: procents[17],
    location: places[6],
    category: categories[6],
    dateCreated: "2023-06-10",
    investmentTable: investmentTables[16],
    userName: userNames[16],
  },
  {
    id: 117,
    title: "City Penthouse",
    description: "A luxurious penthouse in the heart of the city",
    pictures: pictures[17],
    funds: { funded: 60000 },
    procent: procents[18],
    location: places[7],
    category: categories[7],
    dateCreated: "2023-05-12",
    investmentTable: investmentTables[17],
    userName: userNames[17],
  },
  {
    id: 118,
    title: "Coastal Villa",
    description: "A luxurious coastal villa with stunning ocean views",
    pictures: pictures[18],
    funds: { funded: 200000 },
    procent: procents[19],
    location: places[8],
    category: categories[8],
    dateCreated: "2023-05-27",
    investmentTable: investmentTables[18],
    userName: userNames[18],
  },
  {
    id: 119,
    title: "Lakefront Cottage",
    description:
      "A cozy cottage on the lakeshore. A cozy cottage on the lakeshore",
    pictures: pictures[19],
    funds: { funded: 90000 },
    procent: procents[20],
    location: places[9],
    category: categories[9],
    dateCreated: "2023-05-28",
    investmentTable: investmentTables[19],
    userName: userNames[19],
  },
  {
    id: 120,
    title: "Modern Apartment",
    description:
      "Relax on pristine beaches, savor fresh seafood delicacies, and immerse yourself in the laid-back coastal lifestyle. This is the epitome of coastal living at its finest",
    pictures: pictures[20],
    funds: { funded: 150000 },
    procent: procents[21],
    location: places[10],
    category: categories[10],
    dateCreated: "2023-05-29",
    investmentTable: investmentTables[20],
    userName: userNames[20],
  },
  {
    id: 121,
    title: "Countryside Estate",
    description: "An expansive estate in the peaceful countryside",
    pictures: pictures[21],
    funds: { funded: 60000 },
    procent: procents[21],
    location: places[11],
    category: categories[11],
    dateCreated: "2023-05-04",
    investmentTable: investmentTables[21],
    userName: userNames[21],
  },
  {
    id: 122,
    title: "Modern Apartment",
    description:
      "A modern apartment in the heart of the city. In the heart of the city",
    pictures: pictures[15],
    funds: { funded: 90000 },
    procent: procents[0],
    location: places[2],
    category: categories[2],
    dateCreated: "2023-05-22",
    investmentTable: investmentTables[22],
    userName: userNames[22],
  },
  {
    id: 123,
    title: "Cozy Cabin",
    description: "A cozy cabin surrounded by nature, surrounded by nature",
    pictures: pictures[1],
    funds: { funded: 60000 },
    procent: procents[0],
    location: places[3],
    category: categories[3],
    dateCreated: "2023-05-23",
    investmentTable: investmentTables[23],
    userName: userNames[23],
  },
  {
    id: 124,
    title: "Luxury Villa",
    description:
      "Experience the charm of a rustic farmhouse set against a backdrop of rolling hills and breathtaking vistas.",
    pictures: pictures[2],
    funds: { funded: 100000 },
    procent: procents[0],
    location: places[0],
    category: categories[0],
    dateCreated: "2023-05-10",
    investmentTable: investmentTables[24],
    userName: userNames[24],
  },
  {
    id: 125,
    title: "Charming Cottage",
    description: "A charming cottage nestled in the countryside",
    pictures: pictures[3],
    funds: { funded: 75000 },
    procent: procents[0],
    location: places[1],
    category: categories[1],
    dateCreated: "2023-05-24",
    investmentTable: investmentTables[25],
    userName: userNames[25],
  },
];

export default data;

// const investmentTables = [/* ваш масив з investmentTables */];

// const updatedData = data.map((item, index) => {
//   return {
//     ...item,
//     investmentTable: investmentTables[index]
//   };
// });

// console.log(updatedData);
