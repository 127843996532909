import Grid from "@mui/material/Grid";
import { Element } from "react-scroll";

import ArrowPrimary from "../../../../resources/icon/ArrowPtimary";

import "./dealHighlights.scss";

function DealHighlights(props) {
  return (
    <Element name="Deal highlights">
      <h2 className="h2-title">Deal highlights</h2>
      <div className="box">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="deal-highlights">
              <div className="deal-highlights__title-wrapper">
                <ArrowPrimary />
                <h3 className="deal-highlights__title">Project development</h3>
              </div>

              <p className="deal-highlights__description">
                Project is a development property in 1220 Vienna, with a total
                area of 2,660 m². Within the next 30 months the project is to be
                made ready for construction and then sold to a property
                developer.
              </p>
            </div>
            <div className="deal-highlights">
              <div className="deal-highlights__title-wrapper">
                <ArrowPrimary />
                <h3 className="deal-highlights__title">Total living space</h3>
              </div>

              <p className="deal-highlights__description">
                Total living space According to current planning, it is expected
                that 36 residential units with a total living space of 2,527 sqm
                and a weighted total usable space of 2,717 sqm can be built on
                the property.
              </p>
            </div>
            <div className="deal-highlights">
              <div className="deal-highlights__title-wrapper">
                <ArrowPrimary />
                <h3 className="deal-highlights__title">Private garden areas</h3>
              </div>

              <p className="deal-highlights__description">
                The size of the efficiently cut residential units is between 47
                and 110 m². In addition, there are many terraces of up to 15 m²
                and private garden areas of up to 208 m² for all ground floor
                apartments. In addition, a children’s playground.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </Element>
  );
}

export default DealHighlights;
