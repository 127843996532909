import React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

import "./cashback.scss";
import { ReactComponent as DownloadArrow } from "../../../resources/icon/Table/DownloadArrow.svg";
import { ReactComponent as Procent } from "../../../resources/icon/Table/Procent.svg";

function Cashback(props) {
  const [hoverActive, setHoverActive] = React.useState(false);
  const [sliderValue, setSliderValue] = React.useState(0);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSliderValue(40);
    }, props.timeoutForSkeleton);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  return (
    <Grid item xs={12} sm={12} md={6}>
      <div className="boxs-chart" style={{ height: "340px" }}>
        <div className="boxs-chart__title">
          <h4>Cashback</h4>
        </div>
        <div className="boxs-chart__container">
          <span className="slider-level">Cashback level</span>
          <DiscreteSlider value={sliderValue} onChange={handleSliderChange} />

          <div className="slider-level" style={{ marginTop: "25px" }}>
            Platform tax
          </div>

          <div className="data-input copy-wrapper">
            <div
              className={`data-input__wrapper `}
              style={{ width: "50%", minHeight: "38px" }}
            >
              <div
                className="data-input__left-icon"
                style={{ minWidth: "40px" }}
              >
                <Procent />
              </div>
              <div className="data-input__input-general text-procent-value">
                {sliderValue}%
              </div>
            </div>
            <button
              className="button-general download-arrow"
              onClick={() => console.log("saved")}
              onMouseEnter={() => setHoverActive(true)}
              onMouseLeave={() => setHoverActive(false)}
            >
              <div style={{ visibility: "hidden" }}>0</div>
              <div>Save</div>
              <div>
                <DownloadArrow className={hoverActive && "hover-svg"} />
              </div>
            </button>
          </div>

          <div className="slider-description">
            If a user refers a friend to a our referral program, and the friend
            makes an investment of $100, the customer who referred them might
            share 10% of that his referral commission, or $10, as a cashback
            reward.
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default Cashback;

function DiscreteSlider(props) {
  const { value, onChange } = props;

  const sliderMarkStyles = {};
  for (let i = 1; i <= 9; i++) {
    const mark = i * 10;
    sliderMarkStyles[`& .MuiSlider-mark[data-index="${i}"]`] = {
      "&::before": {
        content: `'${mark}'`,
        position: "absolute",
        top: "10px",
        left: "-5px",
      },
    };
  }

  const PrettoSlider = styled(Slider)({
    color: "#FA544D;",
    height: 6,
    "& .MuiSlider-rail": {
      background: "#090B32 !important",
      opacity: "1 !important",
    },
    '& .MuiSlider-mark[data-index="0"]': {
      left: "1% !important",
      "&::before": {
        content: "'0'",
        position: "absolute",
        top: "10px",
        left: "-4px",
        fontFamily: "Kanit",
        fontWeight: "300",
        fontSize: "12px",
        color: "#fff !important",
      },
    },
    ...sliderMarkStyles,
    '& .MuiSlider-mark[data-index="10"]': {
      left: "99% !important",
      "&::before": {
        content: "'100'",
        position: "absolute",
        top: "10px",
        left: "-13px",
      },
    },
    "& .MuiSlider-mark": {
      background: "#090B32",
      opacity: 1,
      top: "23px",
      height: "7px",
      width: "1px",
      fontFamily: "Kanit",
      fontWeight: "300",
      fontSize: "12px",
      color: "#fff !important",
    },
    "& .MuiSlider-track": {
      border: "none",
      transition: " width 2s ease-in-out !important",
    },
    "& .MuiSlider-thumb": {
      height: 12,
      width: 12,
      transition: " width 2s ease-in-out !important",
      background: "linear-gradient(0deg, #DDDDDD 0%, #FAFAFA 100%)",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      display: "none",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });

  return (
    <Box sx={{ width: "100%", marginTop: -0.5 }}>
      <PrettoSlider
        aria-label="Temperature"
        value={value}
        onChange={onChange}
        valueLabelDisplay="auto"
        step={10}
        min={0}
        max={100}
        marks
      />
    </Box>
  );
}
