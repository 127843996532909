import React from "react";

function Partnership(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2998 1.19897V2.02498C15.2998 7.25598 15.2998 3.41697 15.2998 7.74197V8.56798H19.9998V1.19897H15.2998ZM18.8318 7.39898H16.4718V2.37298H18.8318V7.39898Z"
        fill="#8186AF"
        className={color}
      />
      <path
        d="M15.3002 2.02102H13.4002L11.3812 -0.000976562H7.5002L5.4792 2.02002H4.7002V7.73702H4.8002L7.9062 10.843C8.37128 11.308 9.00202 11.5693 9.6597 11.5693C10.3174 11.5693 10.9481 11.308 11.4132 10.843L14.5192 7.73702H15.3002V2.02102ZM4.7002 3.19302H5.9662L7.9872 1.17202H10.9002L12.9192 3.19102L12.4892 3.62103C12.2452 3.86332 11.9158 4.00019 11.5719 4.00225C11.2281 4.00431 10.8971 3.87137 10.6502 3.63202L9.6982 2.71803L5.8492 6.56702H4.7002V3.19302ZM14.0292 6.56702L10.5802 10.016C10.4587 10.1375 10.3145 10.2339 10.1558 10.2996C9.9971 10.3654 9.82699 10.3992 9.6552 10.3992C9.4834 10.3992 9.31329 10.3654 9.15458 10.2996C8.99586 10.2339 8.85166 10.1375 8.7302 10.016L6.3892 7.67502L9.7052 4.35902L9.8232 4.47302C10.2913 4.92597 10.9183 5.17736 11.5697 5.17325C12.221 5.16914 12.8448 4.90984 13.3072 4.45102L14.5632 3.19502H15.3002V6.56902L14.0292 6.56702Z"
        fill="#FA544D"
        className={color}
      />
      <path
        d="M4.7 2.02097V1.19897H0V8.56898H4.7V2.02498V2.02097ZM3.528 7.39297H1.172V2.36697H3.532L3.528 7.39297Z"
        fill="#8186AF"
        className={color}
      />
    </svg>
  );
}

export default Partnership;
