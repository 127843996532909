import Grid from "@mui/material/Grid";
import { Element } from "react-scroll";

import "./documents.scss";

function Documents(props) {
  return (
    <Element name="Documents">
      <h2 className="h2-title">Documents</h2>
      <div className="box">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="doc-table">
              <div className="doc-table__head-row">Date</div>
              <div className="doc-table__head-row">Name</div>
              <div className="doc-table__head-row">Document</div>

              <div className="doc-table__body-row first-child">
                26 Oct 2018 18:42 PM
              </div>
              <div className="doc-table__body-row">
                Vermögensanlagen-Informationsblatt (VIB){" "}
              </div>
              <div className="doc-table__body-row last-child">
                <a href="./test.xlsx" download>
                  <button className="button-general doc-table__button">
                    Download
                  </button>
                </a>
              </div>

              <div className="doc-table__body-row first-child">
                26 Oct 2018 18:42 PM
              </div>
              <div className="doc-table__body-row">Loan contract</div>
              <div className="doc-table__body-row last-child">
                <a href="./test.xlsx" download>
                  <button className="button-general doc-table__button">
                    Download
                  </button>
                </a>
              </div>

              <div className="doc-table__body-row first-child">
                26 Oct 2018 18:42 PM
              </div>
              <div className="doc-table__body-row">Exposé</div>
              <div className="doc-table__body-row last-child">
                <a href="./test.xlsx" download>
                  <button className="button-general doc-table__button">
                    Download
                  </button>
                </a>
              </div>

              <div className="doc-table__body-row first-child">
                26 Oct 2018 18:42 PM
              </div>
              <div className="doc-table__body-row">Fact sheet</div>
              <div className="doc-table__body-row last-child">
                <a href="./test.xlsx" download>
                  <button className="button-general doc-table__button">
                    Download
                  </button>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Element>
  );
}

export default Documents;
