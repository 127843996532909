export const procents = [
  {
    return: 6,
    duration: 28,
    invest: 8,
    loanAmount: 10,
  },
  {
    return: 4,
    duration: 42,
    invest: 6,
    loanAmount: 8,
  },
  {
    return: 5,
    duration: 18,
    invest: 7,
    loanAmount: 9,
  },
  {
    return: 3,
    duration: 24,
    invest: 5,
    loanAmount: 7,
  },
  {
    return: 4,
    duration: 12,
    invest: 6,
    loanAmount: 9,
  },
  {
    return: 7,
    duration: 12,
    invest: 10,
    loanAmount: 14,
  },
  {
    return: 5,
    duration: 8,
    invest: 7,
    loanAmount: 10,
  },
  {
    return: 3,
    duration: 8,
    invest: 5,
    loanAmount: 7,
  },
  {
    return: 8,
    duration: 15,
    invest: 12,
    loanAmount: 18,
  },
  {
    return: 6,
    duration: 10,
    invest: 8,
    loanAmount: 12,
  },
  {
    return: 6,
    duration: 12,
    invest: 9,
    loanAmount: 14,
  },
  {
    return: 3,
    duration: 8,
    invest: 5,
    loanAmount: 7,
  },
  {
    return: 5,
    duration: 6,
    invest: 7,
    loanAmount: 9,
  },
  {
    return: 7,
    duration: 8,
    invest: 5,
    loanAmount: 7,
  },
  {
    return: 8,
    duration: 10,
    invest: 6,
    loanAmount: 9,
  },
  {
    return: 7,
    duration: 12,
    invest: 10,
    loanAmount: 14,
  },
  {
    return: 5,
    duration: 8,
    invest: 7,
    loanAmount: 10,
  },
  {
    return: 3,
    duration: 8,
    invest: 5,
    loanAmount: 7,
  },
  {
    return: 8,
    duration: 15,
    invest: 12,
    loanAmount: 18,
  },
  {
    return: 6,
    duration: 10,
    invest: 8,
    loanAmount: 12,
  },
  {
    return: 6,
    duration: 12,
    invest: 9,
    loanAmount: 14,
  },
  {
    return: 3,
    duration: 8,
    invest: 5,
    loanAmount: 7,
  },
];
