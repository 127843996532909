import React from "react";
import { Grid } from "@mui/material";

import "./skeletonCompanyNews.scss";
import { ReactComponent as SkeletonImg } from "../../../resources/icon/CompanyNews/SkeletonImg.svg";

function SkeletonCompanyNews(props) {
  return (
    <div className="comp-news">
      <div className="comp-news__main-content">
        <SkeletonTitle />

        <div className="skeleton-main-news">
          <Grid container spacing={3} justifyContent="center">
            {[...new Array(2)].map((_, i) => {
              return (
                <Grid item xs={12} sm={6} md={6}>
                  <div key={i} className="box-p skeleton-main-news__item">
                    <div className="skeleton-main-news__img">
                      <SkeletonImg />
                    </div>
                    <div className="main-news__info-wrapper">
                      <SkInfoNews />
                    </div>
                  </div>
                </Grid>
              );
            })}

            {[...new Array(4)].map((_, i) => {
              return (
                <Grid item xs={12} sm={6} md={6}>
                  <div key={i} className="box-p skeleton-card-news__item">
                    <div className="skeleton-card-news__img">
                      <SkeletonImg />
                    </div>
                    <SkInfoNews />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>

        <div className="comp-news-btn">
          <div className="comp-news-btn__center">
            <div className="sk"></div>
          </div>
        </div>
      </div>

      <div className="comp-news__left-panel">
        <div className="box-p project-news">
          <SkeletonTitle />
        </div>
        <div className="skeleton-project-news__container">
          {[...new Array(4)].map((_, i) => {
            return (
              <div
                key={i}
                className="card-news__item skeleton-project-news__item"
              >
                <div className="skeleton-project-news__wrapper">
                  <div className="skeleton-project-news__img">
                    <SkeletonImg />
                  </div>
                  <SkInfoNews />
                </div>
                <div className="info-news__divider"></div>
              </div>
            );
          })}
          <div className="comp-news-btn" style={{ marginTop: "22px" }}>
            <div className="comp-news-btn__center">
              <div className="sk"></div>
            </div>
          </div>
        </div>

        <div className="box-p project-news br-t">
          <SkeletonTitle />
        </div>
        <div className="project-news__container">
          {[...new Array(3)].map((_, i) => {
            return (
              <div key={i} className="card-news__item project-news__item">
                <div className="project-news__wrapper">
                  <div className="dlendor-eports-sk"></div>
                  <SkInfoNews oneLine={true} />
                </div>
                <div className="info-news__divider"></div>
              </div>
            );
          })}
          <div className="comp-news-btn" style={{ marginTop: "22px" }}>
            <div className="comp-news-btn__center">
              <div className="sk"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { SkeletonCompanyNews, SkeletonTitle, SkInfoNews };

function SkInfoNews(props) {
  return (
    <div className="skeleton-info-news">
      <div className="skeleton-info-news__wrapper">
        <div className="skeleton-info-news__views">
          <div className="skeleton-info-news__data sk"></div>
          <div className="skeleton-info-news__divider-y"></div>
          <div className="skeleton-info-news__view sk"></div>
        </div>
        <div className="skeleton-info-news__hashtag sk"></div>
      </div>
      <div className="skeleton-info-news__divider"></div>
      {props.oneLine ? (
        <div className="skeleton-info-news__description-short sk"></div>
      ) : (
        <>
          <div className="skeleton-info-news__description sk"></div>
          <div className="skeleton-info-news__description-short sk"></div>
        </>
      )}
    </div>
  );
}

function SkeletonTitle(props) {
  return (
    <div className="comp-news__box-title">
      <div className="comp-news__icon">
        <div className="skeleton__icon"></div>
      </div>
      <div className="skeleton__title"></div>
    </div>
  );
}
