import { useState } from "react";
import Grid from "@mui/material/Grid";
import { Pagination } from "@mui/material";
import "./relatedProjects.scss";
import ProductCard from "../../../productCard/ProductCard";

import data from "../../../../data/data";

function RelatedProjects(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const pageCount = Math.ceil(data.length / rowsPerPage);

  return (
    <>
      <div className="product-card">
        <h2 className="h2-title red">Related projects</h2>
        <Pagination
          count={pageCount}
          page={page}
          onChange={handleChangePage}
          rowsperpageoptions={[3, 6, 9]}
          rowsperpage={rowsPerPage}
          onowsperpagechange={handleChangeRowsPerPage}
          boundaryCount={1}
          siblingCount={0}
        />
      </div>

      <Grid
        container
        rowSpacing={{ xs: 10, sm: 10, md: 3 }}
        mb={9}
        columnSpacing={4}
      >
        {data.slice(startIndex, endIndex).map((product) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={product.id}>
            <ProductCard {...product} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default RelatedProjects;
