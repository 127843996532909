import React from "react";
import { useLocation } from "react-router-dom";

import Logo from "../../resources/icon/Logo";
import SidebarInside from "../sidebar/sidebarInsade/SidebarInside";

import LogoPrimary from "../../resources/icon/LogoPrimary";

import LeftDashboardMenu from "../sidebar/sidebar/Sidebar";
import UserInfo from "../sidebar/userInfo/UserInfo";

import "./sidebarWrapper.scss";
import NotificationsMessage from "./notificationsMessage/NotificationsMessage";

function SidebarWrapper({ toggleOverlay }) {
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [notificationsVisible, setNotificationsVisible] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const { pathname, key } = useLocation();

  const activeSidebarInside = pathname.includes("/item/");

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  React.useEffect(() => {
    setTimeout(() => {
      setSidebarVisible(false);
      if (!notificationsVisible) {
        toggleOverlay(false);
      }
    }, 300);
    // eslint-disable-next-line
  }, [key]);

  const toggleNotifications = () => {
    setNotificationsVisible(!notificationsVisible);
    if (!notificationsVisible) {
      toggleOverlay(true);
    } else {
      toggleOverlay(false);
    }
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
    if (!sidebarVisible) {
      toggleOverlay(true);
    } else {
      toggleOverlay(false);
    }
  };

  const changeMinWidth = () => {
    if (sidebarVisible) {
      return {
        minWidth: "0px",
      };
    } else if (!activeSidebarInside) {
      return {
        minWidth: "285px",
      };
    }
  };

  React.useMemo(() => {
    if (window.innerWidth > 1279) {
      setSidebarVisible(false);
      changeMinWidth();
    }
    // eslint-disable-next-line
  }, [windowWidth]);

  const zIndexMore = sidebarVisible ? "z-index-chenge" : "";
  const hiddenMinWidth = !activeSidebarInside ? "hidden" : "";
  const hiddenMinWidthFixed = sidebarVisible ? "" : "hidden-width";
  const showSidebar = sidebarVisible ? "open" : "";
  const displayFlex = activeSidebarInside ? "d-flex" : "";
  const filterNone = notificationsVisible ? "filter-none" : "";
  return (
    <>
      <div className="top-menu">
        <Logo />
        <button onClick={toggleSidebar} className="top-menu-toggle-button">
          <ButtonShow />
        </button>
      </div>

      <div
        className={`sidebar-wrapper ${zIndexMore} ${hiddenMinWidth}`}
        style={changeMinWidth()}
      >
        <div
          className={`sidebar-wrapper__fixed ${hiddenMinWidthFixed} ${displayFlex}`}
        >
          <div
            className={`sidebar sidebar-hidden ${showSidebar} ${filterNone}`}
          >
            <button onClick={toggleSidebar} className="toggle-button">
              <ButtonExit />
            </button>
            <LogoPrimary />
            <UserInfo
              toggleNotifications={toggleNotifications}
              notificationsVisible={notificationsVisible}
            />
            <LeftDashboardMenu />
          </div>

          {!notificationsVisible && <SidebarInside />}
        </div>
        <NotificationsMessage
          toggleNotifications={toggleNotifications}
          notificationsVisible={notificationsVisible}
        />
      </div>
    </>
  );
}

export default SidebarWrapper;

export function ButtonExit(props) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.90568 6.85699L12.6237 2.13899C12.6948 2.06956 12.7514 1.98672 12.7902 1.89525C12.8291 1.80379 12.8494 1.70552 12.8499 1.60615C12.8505 1.50678 12.8314 1.40829 12.7936 1.31637C12.7559 1.22445 12.7003 1.14094 12.63 1.07067C12.5597 1.00041 12.4762 0.944783 12.3843 0.907027C12.2924 0.869271 12.1939 0.850133 12.0945 0.850721C11.9951 0.851309 11.8969 0.871612 11.8054 0.910453C11.714 0.949294 11.6311 1.0059 11.5617 1.07699L6.84368 5.79499L2.12568 1.07699C1.98428 0.938905 1.79415 0.862121 1.59652 0.863291C1.39888 0.864461 1.20968 0.943489 1.06992 1.08324C0.930173 1.22299 0.851144 1.4122 0.849975 1.60983C0.848805 1.80747 0.925589 1.9976 1.06368 2.13899L5.78168 6.85699L1.06368 11.575C0.958722 11.68 0.887229 11.8137 0.858225 11.9593C0.829222 12.1048 0.84401 12.2557 0.900721 12.3929C0.957433 12.5301 1.05352 12.6474 1.17686 12.73C1.30019 12.8126 1.44524 12.8568 1.59368 12.857C1.69218 12.8572 1.78976 12.8379 1.88074 12.8001C1.97172 12.7624 2.0543 12.7069 2.12368 12.637L6.84368 7.91899L11.5617 12.637C11.6312 12.7067 11.7138 12.7621 11.8047 12.7998C11.8957 12.8376 11.9932 12.857 12.0917 12.857C12.1902 12.8575 12.2879 12.8382 12.3789 12.8005C12.4699 12.7627 12.5524 12.7071 12.6217 12.637C12.7625 12.4962 12.8416 12.3052 12.8416 12.106C12.8416 11.9068 12.7625 11.7158 12.6217 11.575L7.90568 6.85699Z"
        fill="white"
      />
    </svg>
  );
}

function ButtonShow(props) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0H1C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H15C15.5523 2 16 1.55228 16 1C16 0.447715 15.5523 0 15 0Z"
        fill="#8186AF"
      />
      <path
        d="M15 6H1C0.447715 6 0 6.44772 0 7C0 7.55228 0.447715 8 1 8H15C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6Z"
        fill="#8186AF"
      />
      <path
        d="M15 12H1C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12Z"
        fill="#8186AF"
      />
    </svg>
  );
}
